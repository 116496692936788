import React, { useCallback, useEffect, useState, ReactElement } from "react";
import { useQuery, useQueryClient } from "react-query";
import { createClub, getClub, updateClub } from "./backend";

import { v4 as uuidv4 } from "uuid";
import { useHistory, useParams } from "react-router-dom";
import { CreateClubInput, UpdateClubInput } from "../../API";

import { Auth } from "aws-amplify";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { getTimeStamp } from "src/utils/time";
import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LocationOn, Save } from "@mui/icons-material";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";
import { logError, logEvent } from "src/utils/analytics";
import { ThreeDot } from "react-loading-indicators";

type VenueSelection = {
  description: string;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Array<{
    offset: number;
    value: string;
  }>;
  types: Array<string>;
};

type ClubFormParams = {
  clubId?: string;
};

const useClub = (clubId?: string) => {
  return useQuery(
    ["club", clubId],
    async () => {
      try {
        if (!clubId) {
          return null;
        }
        const club = await getClub(clubId);
        return club;
      } catch (e) {
        console.log("[ERROR] error getting club", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type CLUB_TYPES_TYPES =
  | "RUN_CLUB"
  | "BIKE_CLUB"
  | "SOCIAL_CLUB"
  | "GENERAL_CLUB";

const CLUB_TYPES: Array<{ key: CLUB_TYPES_TYPES; label: string }> = [
  { key: "RUN_CLUB", label: "Run Club" },
  { key: "BIKE_CLUB", label: "Bike Club" },
  { key: "SOCIAL_CLUB", label: "Social Club" },
  { key: "GENERAL_CLUB", label: "General Club" },
];

const ClubForm = (): ReactElement | null => {
  const { clubId } = useParams<ClubFormParams>();

  const newClubId = uuidv4();

  const history = useHistory();
  const cache = useQueryClient();
  const theme = useTheme();

  const { status: clubStatus, data: club } = useClub(clubId);
  const [clubName, setClubName] = useState<string | undefined>(undefined);
  const [clubAddress, setClubAddress] = useState<string | undefined>(undefined);
  const [clubCity, setClubCity] = useState<string | undefined>(undefined);
  const [clubState, setClubState] = useState<string | undefined>(undefined);
  const [clubLatitude, setClubLatitude] = useState<number | undefined>(
    undefined,
  );
  const [clubLongitude, setClubLongitude] = useState<number | undefined>(
    undefined,
  );
  const [clubScoreUnits, setClubScoreUnits] = useState<string | undefined>(
    undefined,
  );
  const [clubLogoUrl, setClubLogoUrl] = useState<string | undefined | null>(
    null,
  );
  const [clubType, setClubType] = useState<CLUB_TYPES_TYPES>("RUN_CLUB");
  const [clubMaxCustomScore, setClubMaxCustomScore] = useState<
    number | undefined
  >(undefined);
  const [isChangingVenue, setIsChangingVenue] = useState<boolean>(
    clubId === undefined,
  );

  const [hasCustomCheckinOption, setHasCustomCheckinOption] = useState<boolean>(
    club?.showCustomOption || false,
  );

  // errors
  const [customMaxScoreError, setCustomMaxScoreError] = useState<string | null>(
    null,
  );

  const [isSearchingLocation, setIsSearchingLocation] =
    useState<boolean>(false);

  // Places searcher
  const {
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  useEffect(() => {
    setIsSearchingLocation(false);
  }, [placesLoading, placeSuggestions]);

  const handleClubAddressChange = useCallback(
    (address: string) => {
      if (address.length > 2) {
        setIsSearchingLocation(true);
      }
      setValue(address);
    },
    [setValue],
  );

  const handleChangeHasCustom = useCallback(() => {
    setHasCustomCheckinOption(!hasCustomCheckinOption);
  }, [hasCustomCheckinOption]);

  const handleVenueSelected = async (venue: VenueSelection) => {
    const { types, structured_formatting } = venue;

    if (
      !types.includes("establishment") &&
      !types.includes("street_address") &&
      !types.includes("premise")
    ) {
      logEvent("non_venue_selected", {
        clubId,
        returnedTypes: types,
        returnedStructuredFormatting: structured_formatting,
      });
      window.alert("You must choose either a business or a street address");
      return;
    }

    let address = "";
    let city = "";
    let state = "";
    const addressParts = structured_formatting.secondary_text.split(",");

    if (types.includes("street_address") || types.includes("premise")) {
      address = structured_formatting.main_text;
      city = addressParts[0];
      state = addressParts[1];
    } else {
      address = addressParts[0];
      city = addressParts[1];
      state = addressParts[2];
    }

    setClubAddress(address);
    setClubCity(city);
    setClubState(state);

    setIsChangingVenue(false);

    clearSuggestions();

    const results = await getGeocode({ address: venue.description });
    if (results.length > 0) {
      try {
        const { lat, lng } = await getLatLng(results[0]);
        setClubLatitude(lat);
        setClubLongitude(lng);
      } catch (_e) {
        const e = _e as Error;
        logError({
          error: e,
          message: "Error getting lat/lng for venue",
          file: "ClubForm.tsx",
        });
        window.alert(
          "There was an error setting the address. Please select a new venue.",
        );
      }
    }
  };

  const handlePhotoCropped = (croppedImage: string) => {
    logEvent("photo_uploaded", {
      imageUrl: getImageUrl(croppedImage),
      clubId,
    });
    setClubLogoUrl(getImageUrl(croppedImage));
  };

  const handleSaveClub = useCallback(async () => {
    try {
      setCustomMaxScoreError(null);
      const authedUser = await Auth.currentAuthenticatedUser();

      const owner = authedUser.username;
      if (!owner || !clubName || !clubScoreUnits || !clubType) {
        alert(
          "Invalid club values. Please provide all fields. If this error persists please contact support. (support@runclub.beer)",
        );
        return;
      }
      if (
        hasCustomCheckinOption &&
        (!clubMaxCustomScore ||
          isNaN(clubMaxCustomScore) ||
          clubMaxCustomScore <= 0)
      ) {
        setCustomMaxScoreError(
          "If you are going to allow a custom max score it must be a positive number.",
        );
        return;
      }
      if (clubId) {
        const now = getTimeStamp();
        const input: UpdateClubInput = {
          id: clubId,
          name: clubName,
          address: clubAddress,
          latitude: clubLatitude,
          longitude: clubLongitude,
          city: clubCity,
          owner,
          state: clubState,
          scoreUnits: clubScoreUnits,
          updatedAt: now,
          showCustomOption: hasCustomCheckinOption,
          logoUrl: clubLogoUrl,
          clubType,
          customMaxScore: clubMaxCustomScore || 100,
        };
        const club = await updateClub(input);
        if (!club) {
          alert(
            "Error saving the club. Please try again. If this problem persists, please contact support@runclub.beer",
          );
        } else {
          logEvent("club_updated", {
            clubId,
            input,
          });
          cache.invalidateQueries(["club", clubId]);
          history.push(`/clubs/${clubId}`);
        }
      } else {
        const now = getTimeStamp();
        const input: CreateClubInput = {
          id: newClubId,
          address: clubAddress || "",
          name: clubName,
          city: clubCity || "",
          latitude: clubLatitude || 0,
          longitude: clubLongitude || 0,
          owner,
          hashedPinCode: "this value is no longer used",
          state: clubState || "",
          scoreUnits: clubScoreUnits,
          updatedAt: now,
          showCustomOption: hasCustomCheckinOption,
          logoUrl: clubLogoUrl,
          managerGroup: `${clubId}-managers`,
          clubType,
          customMaxScore: clubMaxCustomScore,
          wantsWeeklyStats: true,
        };
        const club = await createClub(input);
        if (!club) {
          logError({
            error: new Error("Error creating club"),
            message: "Error creating club",
            file: "ClubForm.tsx",
          });
          alert(
            "Error creating the club. Please try again. If this problem persists, please contact support@runclub.beer",
          );
        } else {
          logEvent("club_created", {
            clubId: club.id,
            input,
          });
          history.push(`/clubs/${club.id}`);
        }
      }
    } catch (e) {
      logError({
        error: e as Error,
        message: "Error handling club save",
        file: "ClubForm.tsx",
      });
      console.log("[ERROR] error handling club save", e);
    }
  }, [
    clubName,
    clubScoreUnits,
    clubType,
    hasCustomCheckinOption,
    clubMaxCustomScore,
    clubId,
    clubAddress,
    clubLatitude,
    clubLongitude,
    clubCity,
    clubState,
    clubLogoUrl,
    cache,
    history,
    newClubId,
  ]);

  useEffect(() => {
    if (club && clubStatus === "success") {
      setClubName(club.name);
      setClubAddress(club.address);
      setClubLatitude(club.latitude);
      setClubLongitude(club.longitude);
      setClubCity(club.city);
      setClubState(club.state);
      setClubScoreUnits(club.scoreUnits);
      setHasCustomCheckinOption(Boolean(club.showCustomOption));
      setClubLogoUrl(club.logoUrl || undefined);
      setClubType((club.clubType as CLUB_TYPES_TYPES) || "RUN_CLUB");
      setClubMaxCustomScore(club.customMaxScore || 100);
    }
  }, [club, clubStatus]);

  useEffect(() => {
    const checkOwner = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if ((club && user.username !== club.owner) || !user.username) {
        logEvent("club_access_denied", {
          clubId,
          userId: user.username,
        });
        history.push("/clubs");
      }
    };
    checkOwner();
  }, [club, clubId, history]);

  const renderPlacesResult = () => {
    if (placesLoading || isSearchingLocation) {
      return (
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Typography variant="h6">Searching...</Typography>
          <ThreeDot
            variant="pulsate"
            color={theme.palette.primary.main}
            size="small"
          />
        </Box>
      );
    }
    if (placeSuggestions) {
      return (
        <div>
          {placeSuggestions.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Box key={place_id}>
                <Button
                  fullWidth
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    borderBottom: "1px #ccc solid",
                    borderRadius: 0,
                  }}
                  startIcon={<LocationOn />}
                  onClick={() => handleVenueSelected(suggestion)}
                  variant="text">
                  <strong style={{ marginRight: 5 }}>{main_text}</strong>{" "}
                  <small>( {secondary_text} )</small>
                </Button>
              </Box>
            );
          })}
        </div>
      );
    }
  };

  const renderClubAddress = () => {
    const address = clubAddress
      ? `${clubAddress}, ${clubCity}, ${clubState}`
      : "";
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}>
        <TextField
          id="club-address"
          fullWidth
          label="Address"
          value={address}
          disabled
        />
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "rgba(255,255,255,.25)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button onClick={() => setIsChangingVenue(true)} variant="contained">
            Change Address
          </Button>
        </Box>
      </div>
    );
  };

  const renderBackButton = () => {
    if (!clubId) {
      return (
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={() => history.replace(`/clubs`)}>
          Cancel
        </Button>
      );
    }
  };

  if (clubId && clubStatus !== "success") {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <div>
        <div>
          <TextField
            id="club-name"
            fullWidth
            label="Name"
            sx={{ my: 2 }}
            value={clubName || ""}
            onChange={(e) => {
              setClubName(e.target.value);
            }}
          />
          {!isChangingVenue && renderClubAddress()}
          {isChangingVenue && (
            <ClickAwayListener onClickAway={() => clearSuggestions()}>
              <Box sx={{ position: "relative" }}>
                <TextField
                  fullWidth
                  sx={{ my: 2 }}
                  label="Venue address or name"
                  helperText="This is the venue where the club meets. Search by either name or address."
                  onChange={(e) => handleClubAddressChange(e.target.value)}
                />
                <Paper
                  elevation={10}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    position: "absolute",
                    top: "66%",
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    zIndex: 9999,
                  }}>
                  {renderPlacesResult()}
                </Paper>
              </Box>
            </ClickAwayListener>
          )}
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel htmlFor="club-type">Club Type</InputLabel>
            <Select
              id="club-type"
              fullWidth
              label="Club Type"
              value={clubType}
              onChange={(e) => setClubType(e.target.value as CLUB_TYPES_TYPES)}>
              {CLUB_TYPES.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ my: 2 }}>
            <Typography variant="body2">Club Logo</Typography>
            {clubLogoUrl && (
              <img
                src={clubLogoUrl}
                alt="club logo"
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "contain",
                }}
              />
            )}
            <PhotoCropper
              onCancel={() => null}
              onComplete={handlePhotoCropped}
              showPreviewSquare
              keyFolder={`clubs/${clubId || newClubId}`}
            />
          </FormControl>
          <TextField
            sx={{ my: 2 }}
            id="club-score-units"
            fullWidth
            label="Score units"
            helperText="This is the unit of measurement visible to your members"
            value={clubScoreUnits || ""}
            onChange={(e) => setClubScoreUnits(e.target.value)}
          />
          <Paper>
            {customMaxScoreError && <div>{customMaxScoreError}</div>}
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={hasCustomCheckinOption}
                    onChange={handleChangeHasCustom}
                    name="has-custom-checkin-option"
                    color="primary"
                  />
                }
                label="Show custom check-in option"
              />
            </div>
            {hasCustomCheckinOption && (
              <TextField
                id="club-max-custom"
                fullWidth
                label="Maximum Custom Amount"
                value={clubMaxCustomScore || 0}
                type="number"
                onChange={(e) =>
                  setClubMaxCustomScore(parseFloat(e.target.value))
                }
              />
            )}
          </Paper>
        </div>
        <Box
          sx={{
            display: "flex",
            mt: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
            onClick={handleSaveClub}>
            Save
          </Button>
          {renderBackButton()}
        </Box>
      </div>
    </Container>
  );
};

export default ClubForm;
