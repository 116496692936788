import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import {
  getMemberAchievementsByClubAndStatus,
  setMemberAchievementHasReceivedSwag,
  setMemberAchievementIsNew,
} from "./backend";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { format } from "date-fns";
import React from "react";
import { MemberAchievement } from "src/API";

type ClubAchievementsProps = {
  clubId: string;
};

const useMemberAchievements = (
  clubID: string,
  nextToken: string | null,
  status = "new",
) => {
  return useQuery(
    ["club", clubID, "memberAchievements", status, nextToken],
    async () => {
      const results = await getMemberAchievementsByClubAndStatus({
        clubID,
        nextToken,
        status,
      });
      return {
        memberAchievements: results?.memberAchievements,
        nextToken: results?.nextToken,
      };
    },
    {
      keepPreviousData: true,
    },
  );
};

const usePagination = () => {
  const [lastTokens, setLastTokens] = useState<Array<string>>([]);

  const pushToken = (token?: string | null) => {
    if (token) {
      setLastTokens((t) => [...t, token]);
    }
  };

  const popToken = () => {
    setLastTokens((t) => t.slice(0, -1));
  };

  return {
    nextToken: lastTokens.length > 0 ? lastTokens[lastTokens.length - 1] : null,
    pushToken,
    popToken,
  };
};

function useUrlQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ClubAchievements = (): ReactElement => {
  const theme = useTheme();
  const { clubId } = useParams<ClubAchievementsProps>();
  const query = useUrlQuery();
  const status = query.get("status") || "new";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [sortBy, setSortBy] = useState<
    "date" | "firstName" | "lastName" | "email"
  >("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const { nextToken, pushToken, popToken } = usePagination();

  const { status: memberAchievementStatus, data: results } =
    useMemberAchievements(clubId, nextToken, status);

  const memberAchievements = results?.memberAchievements;
  const token = results?.nextToken;

  const [sortedMemberAchievements, setSortedMemberAchievements] = useState<
    MemberAchievement[]
  >(memberAchievements || []);

  const cache = useQueryClient();

  useEffect(() => {
    if (!memberAchievements) {
      return;
    }
    const sorted = [...memberAchievements].sort((a, b) => {
      if (!a || !b || !a.member || !b.member) {
        return 0;
      }
      if (sortBy === "date") {
        if (sortDirection === "asc") {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        } else {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        }
      } else if (sortBy === "lastName") {
        if (!a.member.lastName || !b.member.lastName) {
          return 0;
        }
        if (sortDirection === "asc") {
          return a.member.lastName.localeCompare(b.member.lastName);
        } else {
          return b.member.lastName.localeCompare(a.member.lastName);
        }
      } else if (sortBy === "firstName") {
        if (!a.member.firstName || !b.member.firstName) {
          return 0;
        }
        if (sortDirection === "asc") {
          return a.member.firstName.localeCompare(b.member.firstName);
        } else {
          return b.member.firstName.localeCompare(a.member.firstName);
        }
      } else if (sortBy === "email") {
        if (!a.member.email || !b.member.email) {
          return 0;
        }
        if (sortDirection === "asc") {
          return a.member.email.localeCompare(b.member.email);
        } else {
          return b.member.email.localeCompare(a.member.email);
        }
      }
      return 0;
    });
    setSortedMemberAchievements(sorted);
  }, [memberAchievements, sortBy, sortDirection]);

  const handleSeenClick = async (
    memberAchievementID: string,
    isNew: boolean,
  ) => {
    const updatedMemberAchievement = await setMemberAchievementIsNew(
      memberAchievementID,
      isNew,
    );
    if (updatedMemberAchievement) {
      cache.invalidateQueries(["club", clubId, "memberAchievements"]);
    } else {
      alert("Error updating seen status. Please try again.");
    }
  };
  const handleSwagGivenClick = async (
    memberAchievementID: string,
    hasReceivedSwag: boolean,
  ) => {
    const updatedMemberAchievement = await setMemberAchievementHasReceivedSwag(
      memberAchievementID,
      hasReceivedSwag,
    );
    if (updatedMemberAchievement) {
      cache.invalidateQueries(["club", clubId, "memberAchievements"]);
    } else {
      alert("Error updating has swag status. Please try again.");
    }
  };

  const renderSwagStatus = (
    memberAchievementID: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
  ) => {
    if (!hasSwag) {
      return "no swag";
    }
    if (!swagReceived) {
      return (
        <Button onClick={() => handleSwagGivenClick(memberAchievementID, true)}>
          Mark swag given
        </Button>
      );
    }
    return (
      <div>
        swag received
        <p>
          {swagReceivedDate
            ? format(new Date(swagReceivedDate), "MM/dd/yyyy")
            : ""}
        </p>
      </div>
    );
  };

  const renderMobileTable = () => {
    return (
      <Box>
        {sortedMemberAchievements?.map((memberAchievement) => {
          const {
            member,
            achievement,
            id,
            dateAchieved,
            hasSwag,
            swagReceived,
            isNew,
            swagReceivedDate,
          } = memberAchievement;
          if (!member || !achievement) {
            return null;
          }
          return (
            <Card key={`achievement-${id}`} sx={{ mb: 2 }}>
              <CardHeader
                title={`${member.firstName} ${member.lastName}`}
                subheader={member.email}
              />
              <CardContent>
                <Typography>{achievement.name}</Typography>
                <Typography>
                  {dateAchieved
                    ? format(new Date(dateAchieved), "iii MM/dd/yyyy h:mmaaa")
                    : ""}
                </Typography>
              </CardContent>
              <CardActions sx={{ alignContent: "center" }}>
                <Typography>Status:</Typography>
                {(hasSwag || swagReceivedDate) &&
                  renderSwagStatus(
                    memberAchievement.id,
                    hasSwag,
                    swagReceived,
                    swagReceivedDate,
                  )}
                {isNew && !hasSwag && (
                  <Button
                    onClick={() =>
                      handleSeenClick(memberAchievement.id, false)
                    }>
                    Mark Seen
                  </Button>
                )}
              </CardActions>
            </Card>
          );
        })}
      </Box>
    );
  };

  const renderDesktopTable = () => {
    return (
      <div>
        <div>
          <TableContainer component={Paper}>
            <Table aria-label="club table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Achievement</TableCell>
                  <TableCell>Date Achieved</TableCell>
                  <TableCell>Swag Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMemberAchievements?.map((memberAchievement) => {
                  const {
                    member,
                    achievement,
                    id,
                    dateAchieved,
                    hasSwag,
                    swagReceived,
                    isNew,
                    swagReceivedDate,
                  } = memberAchievement;
                  if (!member || !achievement) {
                    return null;
                  }
                  return (
                    <TableRow key={`member-achievement-${id}`}>
                      <TableCell>
                        {member.firstName} {member.lastName}
                      </TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>{achievement.name}</TableCell>
                      <TableCell>
                        {dateAchieved
                          ? format(
                              new Date(dateAchieved),
                              "iii MM/dd/yyyy h:mmaaa",
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {(hasSwag || swagReceivedDate) &&
                          renderSwagStatus(
                            memberAchievement.id,
                            hasSwag,
                            swagReceived,
                            swagReceivedDate,
                          )}
                        {isNew && !hasSwag && (
                          <Button
                            onClick={() =>
                              handleSeenClick(memberAchievement.id, false)
                            }>
                            Mark Seen
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={() => popToken()}
            disabled={nextToken === null || nextToken === undefined}>
            Previous Page
          </Button>
          <Button
            onClick={() => pushToken(token)}
            disabled={token === null || token === undefined}>
            Next Page
          </Button>
        </div>
      </div>
    );
  };

  if (memberAchievementStatus === "loading") {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <Box>
      <Box maxWidth={500} sx={{ mb: 2, display: "flex" }}>
        <FormControl sx={{ display: "flex", flex: 1, mr: { xs: 1, md: 2 } }}>
          <InputLabel sx={{ backgroundColor: "#fff", px: 1 }}>
            Sort By
          </InputLabel>
          <Select
            label="Sort By"
            value={sortBy || "date"}
            onChange={(e) => {
              setSortBy(
                e.target.value as "date" | "firstName" | "lastName" | "email",
              );
            }}>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="firstName">First Name</MenuItem>
            <MenuItem value="lastName">Last Name</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ display: "flex", flex: 1 }}>
          <InputLabel sx={{ backgroundColor: "#fff", px: 1 }}>
            Sort Dir
          </InputLabel>
          <Select
            label="Sort Dir"
            value={sortDirection || "asc"}
            onChange={(e) => {
              setSortDirection(e.target.value as "asc" | "desc");
            }}>
            <MenuItem value="asc">ASC</MenuItem>
            <MenuItem value="desc">DESC</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {isMobile ? renderMobileTable() : renderDesktopTable()}
    </Box>
  );
};

export default ClubAchievements;
