import React, { ReactElement } from "react";
import { Switch, Route } from "react-router-dom";
import WhatAreAchievements from "./WhatAreAchievements";
import WhatAreOptions from "./WhatAreOptions";
import WhatYouNeed from "./WhatYouNeed";

const OnboardingRoot = (): ReactElement => {
  return (
    <Switch>
      <Route path="/onboarding" exact>
        <WhatYouNeed />
      </Route>
      <Route path="/onboarding/what-you-need">
        <WhatYouNeed />
      </Route>
      <Route path="/onboarding/what-are-options">
        <WhatAreOptions />
      </Route>
      <Route path="/onboarding/what-are-achievements">
        <WhatAreAchievements />
      </Route>
    </Switch>
  );
};

export default OnboardingRoot;
