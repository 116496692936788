import React, { ReactElement } from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import ClubList from "./ClubList";
import ClubHome from "./ClubHome";
import MemberEdit from "./MemberEdit";
import MemberShow from "./MemberShow";
import ClubEdit from "./ClubEdit";
import AchievementForm from "./AchievementForm";
import Onboarding from "./Onboarding";
import { useHasFeature } from "src/utils/features";
import ClubForm from "./ClubForm";

const ClubRoot = (): ReactElement => {
  const match = useRouteMatch();

  const newOnboarding = useHasFeature("MANAGER_USE_CLUB_ONBOARDING_V2");
  const useNewOnboarding = newOnboarding?.hasFeature;
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        {useNewOnboarding ? <Onboarding /> : <ClubForm />}
      </Route>
      <Route path={`${match.path}/:clubId/members/:memberId/edit`}>
        <MemberEdit />
      </Route>
      <Route path={`${match.path}/:clubId/members/:memberId`}>
        <MemberShow />
      </Route>
      <Route path={`${match.path}/:clubId/edit/:option`}>
        <ClubEdit />
      </Route>
      <Route path={`${match.path}/:clubId/achievements/new`}>
        <AchievementForm />
      </Route>
      <Route path={`${match.path}/:clubId/achievements/:achievementId`}>
        <AchievementForm />
      </Route>
      <Route path={`${match.path}/:clubId/:option`}>
        <ClubHome />
      </Route>
      <Route path={`${match.path}/:clubId`}>
        <ClubHome />
      </Route>
      <Route path={match.path}>
        <ClubList />
      </Route>
    </Switch>
  );
};

export default ClubRoot;
