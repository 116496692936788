import React, { useEffect, useState, ReactElement } from "react";

import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { round } from "lodash";
import { useQuery, useQueryClient } from "react-query";

import { deleteMember, getMember, restoreMember, saveMember } from "./backend";

import { format } from "date-fns";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

const useMember = (memberId: string) => {
  return useQuery(
    ["members", memberId],
    async () => {
      const result = await getMember(memberId);

      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type MemberEditParams = {
  clubId: string;
  memberId: string;
};

const MemberEdit = (): ReactElement => {
  const { clubId, memberId } = useParams<MemberEditParams>();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isRestoreDialogOpen, setIsRestoreDialogOpen] =
    useState<boolean>(false);
  // const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { status: memberStatus, data: member } = useMember(memberId);

  const client = useQueryClient();
  const history = useHistory();

  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [totalScore, setTotalScore] = useState<number>(0);

  const disabled = Boolean(member && member.deletedAt);

  useEffect(() => {
    if (!isSaving && isSaved) {
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }
  }, [isSaved, isSaving]);

  useEffect(() => {
    if (member) {
      setEmail(member.email);
      setFirstName(member.firstName);
      setLastName(member.lastName);
      setTotalScore(member.totalScore);
    }
  }, [member]);

  const handleRestoreMember = async () => {
    try {
      const result = await restoreMember(memberId);
      if (result) {
        setIsRestoreDialogOpen(false);
        history.push(`/clubs/${clubId}/members`);
      } else {
        setError("Error restoring member");
      }
    } catch (e) {
      setError("Error restoring member");
      console.log("[ERROR] error restoring member", e);
    }
  };

  const handleDeleteMember = async () => {
    try {
      const result = await deleteMember(memberId);
      if (result) {
        setIsDeleteDialogOpen(false);
        history.push(`/clubs/${clubId}/members`);
      } else {
        setError("Error deleting member");
      }
    } catch (e) {
      setError("Error deleting member");
      console.log("[ERROR] error deleting member", e);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedMember = await saveMember({
        memberId,
        firstName,
        lastName,
        email,
        totalScore,
      });
      setIsSaved(true);

      if (updatedMember) {
        client.setQueryData(["members", memberId], updatedMember);
      }
    } catch (e) {
      console.log("[ERROR] error saving", e);
      alert("error saving member try again");
    } finally {
      setIsSaving(false);
    }
  };

  if (memberStatus === "loading") {
    return (
      <div>
        <h1>Loading Member...</h1>
        <CircularProgress color="primary" size={60} />
      </div>
    );
  }

  if (!member) {
    return (
      <Container maxWidth="sm">
        <Typography>Error finding that member</Typography>
        <RouterLink to={`/clubs/${clubId}/members`}>Back to Members</RouterLink>
      </Container>
    );
  }
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <RouterLink color="inherit" to="/clubs">
          All Clubs
        </RouterLink>
        <RouterLink color="inherit" to={`/clubs/${clubId}`}>
          Club Home
        </RouterLink>
        <Typography color="textPrimary">
          {member.lastName}, {member.firstName}
        </Typography>
      </Breadcrumbs>
      {isSaved && <Alert severity="success">Saved!</Alert>}
      {member.deletedAt && (
        <Alert severity="warning" sx={{ my: 3 }}>
          Member Deleted at {format(new Date(member.deletedAt), "yyyy-MM-dd")}
        </Alert>
      )}
      <Box>
        <TextField
          id="member-first-name"
          fullWidth
          label="First name"
          variant="outlined"
          sx={{ my: 2 }}
          disabled={disabled}
          defaultValue={member.firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          id="member-last-name"
          fullWidth
          label="Last name"
          variant="outlined"
          sx={{ my: 2 }}
          disabled={disabled}
          defaultValue={member.lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          id="member-email"
          fullWidth
          label="Email"
          variant="outlined"
          sx={{ my: 2 }}
          disabled={disabled}
          defaultValue={member.email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="member-total-score"
          fullWidth
          label="Total score"
          variant="outlined"
          sx={{ my: 2 }}
          defaultValue={round(member.totalScore, 1)}
          type="number"
          disabled={disabled}
          onChange={(e) => setTotalScore(parseFloat(e.target.value))}
        />
        {!member.deletedAt && (
          <>
            <Button
              disabled={isSaving}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleSave()}>
              {isSaving ? "Saving..." : "Save"}
            </Button>

            <Button
              sx={{ mt: 1 }}
              color="error"
              fullWidth
              onClick={() => setIsDeleteDialogOpen(true)}
              variant="contained">
              Delete Member
            </Button>
          </>
        )}
        {member.deletedAt && (
          <Button
            sx={{ mt: 1 }}
            color="primary"
            fullWidth
            onClick={() => setIsRestoreDialogOpen(true)}
            variant="contained">
            Restore Member
          </Button>
        )}
      </Box>
      <Dialog open={isDeleteDialogOpen}>
        <DialogTitle>Delete Member</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <DialogContentText>
            Are you sure you want to delete this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button color="error" onClick={handleDeleteMember}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isRestoreDialogOpen}>
        <DialogTitle>Restore Member</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <DialogContentText>
            Are you sure you want to restore this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRestoreDialogOpen(false)}>Cancel</Button>
          <Button color="error" onClick={handleRestoreMember}>
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MemberEdit;
