export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      managerGroup
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const deleteMemberAchievement = /* GraphQL */ `
  mutation DeleteMemberAchievement(
    $input: DeleteMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    deleteMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      managerGroup
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        managerGroup
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        managerGroup
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const deleteCheckinOption = /* GraphQL */ `
  mutation DeleteCheckinOption(
    $input: DeleteCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    deleteCheckinOption(input: $input, condition: $condition) {
      id
      managerGroup
      owner
      label
      score
      order
      clubID
      status
      createdAt
      updatedAt
    }
  }
`;

export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement($input: CreateAchievementInput!) {
    createAchievement(input: $input) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      managerGroup
      createdAt
      updatedAt
    }
  }
`;

export const createClub = /* GraphQL */ `
  mutation CreateClub(
    $input: CreateClubInput!
    $condition: ModelClubConditionInput
  ) {
    createClub(input: $input, condition: $condition) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      customMemberFields {
        label
        type
        selectOptions
        __typename
      }
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          managerGroup
          owner
          label
          score
          order
          clubID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;

export const updateCheckinOption = /* GraphQL */ `
  mutation UpdateCheckinOption(
    $input: UpdateCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    updateCheckinOption(input: $input, condition: $condition) {
      id
      managerGroup
      owner
      label
      score
      order
      clubID
      status
      createdAt
      updatedAt
    }
  }
`;

export const createCheckinOption = /* GraphQL */ `
  mutation CreateCheckinOption(
    $input: CreateCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    createCheckinOption(input: $input, condition: $condition) {
      id
      managerGroup
      owner
      label
      score
      order
      clubID
      status
      createdAt
      updatedAt
    }
  }
`;

export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      managerGroup
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      managerGroup
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const createMemberAchievement = /* GraphQL */ `
  mutation CreateMemberAchievement(
    $input: CreateMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    createMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      managerGroup
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        managerGroup
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        managerGroup
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const updateClub = /* GraphQL */ `
  mutation UpdateClub(
    $input: UpdateClubInput!
    $condition: ModelClubConditionInput
  ) {
    updateClub(input: $input, condition: $condition) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      customMemberFields {
        label
        type
        slug
        selectOptions
        __typename
      }
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          managerGroup
          owner
          label
          score
          order
          clubID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;

export const updateMemberAchievement = /* GraphQL */ `
  mutation UpdateMemberAchievement(
    $input: UpdateMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    updateMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      managerGroup
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        managerGroup
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        managerGroup
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      managerGroup
      createdAt
      updatedAt
    }
  }
`;
