import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Input,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import { useDispatch, useStore } from "./context";
import { ONBOARDING_STEP_COUNT, showJson } from ".";
import { useHistory } from "react-router-dom";

const OnboardingUnits = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const [hasCustomCheckinOption, setHasCustomCheckinOption] = React.useState(
    !store.customMaxScore,
  );

  const [newCustomMaxScore, setNewCustomMaxScore] = React.useState<
    number | null
  >(store.customMaxScore || null);

  const [hasValueError, setValueError] = React.useState(false);

  const handleChangeUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "SET_UNITS", payload: e.target.value });
  };

  const handleSetNewCustomOptionValue = (value: number) => {
    if (isNaN(value) || value <= 0) {
      setValueError(true);
    } else {
      setValueError(false);
      dispatch({ type: "SET_CUSTOM_MAX_SCORE", payload: value });
    }
    setNewCustomMaxScore(value);
  };

  const handleNext = () => {
    dispatch({ type: "SET_STEP", payload: store.currentStep + 1 });
    history.push(`/clubs/new/options`);
  };

  return (
    <Container>
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}>
          <Typography variant="h1">New Club</Typography>
          <Typography variant="h2">5/{ONBOARDING_STEP_COUNT}</Typography>
        </Box>
        <Typography variant="h2">What do you want to track?</Typography>
        <Typography variant="body2">
          {"(This will be what unit of measurement you use for your club.)"}
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 2 }}>
        <Input
          placeholder="Club units"
          fullWidth
          sx={{ textAlign: "center" }}
          onChange={handleChangeUnits}
          inputProps={{
            sx: { textAlign: "center", fontSize: "1.2rem" },
          }}
        />
        <Typography variant="caption">
          {'(Ex. "miles", "check-ins", "kilometers", "high fives")'}
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Box>
          <Alert severity="info">
            <AlertTitle>
              Would you would like to allow your members to enter a custom
              check-in amount?
            </AlertTitle>
            <FormControlLabel
              control={
                <Switch
                  checked={hasCustomCheckinOption}
                  onChange={() => {
                    dispatch({
                      type: "CLEAR_CUSTOM_MAX_SCORE",
                    });
                    setNewCustomMaxScore(null);
                    setHasCustomCheckinOption((e) => !e);
                  }}
                  name="has-custom-checkin-option"
                  color="primary"
                />
              }
              label="Allow custom check-in option"
            />
            {hasCustomCheckinOption && (
              <>
                <TextField
                  sx={{ mt: 1, backgroundColor: "white" }}
                  id="club-max-custom"
                  error={hasValueError}
                  defaultValue={store.customMaxScore}
                  fullWidth
                  label="Maximum custom amount"
                  value={
                    newCustomMaxScore !== null && !isNaN(newCustomMaxScore)
                      ? newCustomMaxScore
                      : ""
                  }
                  type="number"
                  onChange={(e) =>
                    handleSetNewCustomOptionValue(parseFloat(e.target.value))
                  }
                />
                <Typography variant="caption">
                  Enter a maximum value for custom check-ins {"that's"} &gt; 0.
                </Typography>
              </>
            )}
            {hasValueError && (
              <Alert severity="error">Please enter a value &gt; 0.</Alert>
            )}
          </Alert>
        </Box>
      </Paper>
      <Button
        fullWidth
        size="large"
        onClick={handleNext}
        variant="contained"
        disabled={
          (hasCustomCheckinOption &&
            (!newCustomMaxScore || newCustomMaxScore <= 0)) ||
          (hasCustomCheckinOption && hasValueError) ||
          !store.units ||
          store.units.length < 2
        }>
        Create My Club!
      </Button>
      {showJson(store)}
    </Container>
  );
};

export default OnboardingUnits;
