import React, { ReactElement, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import TabPanel from "../../components/TabPanel";
import { useEffect } from "react";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import { AppBar, Tab, Tabs } from "@mui/material";

type SettingsRootParams = {
  option: string;
};

const SettingsDisplay = (): ReactElement => {
  const { option } = useParams<SettingsRootParams>();
  const history = useHistory();
  const [tabValue, setTabValue] = useState<number>(0);

  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickTab = (tab: string) => {
    history.replace(`/settings/${tab}`);
  };

  useEffect(() => {
    switch (option) {
      case "general":
        setTabValue(0);
        break;
      case "password":
        setTabValue(1);
        break;
      default:
        setTabValue(0);
        break;
    }
  }, [option]);

  return (
    <div>
      <AppBar color="transparent" position="static">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Manager Settings Tabbar">
          <Tab label="General" onClick={() => handleClickTab("general")} />
          <Tab label="Password" onClick={() => handleClickTab("password")} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <SettingsGeneral />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SettingsPassword />
      </TabPanel>
    </div>
  );
};

export default SettingsDisplay;
