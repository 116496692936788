import React, { ReactElement, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SettingsPassword = (): ReactElement => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const setErrorTimeout = (message: string) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleChangePasswordClicked = async () => {
    setSuccess(false);
    try {
      if (
        oldPassword.length === 0 ||
        newPassword.length === 0 ||
        confirmNewPassword.length === 0
      ) {
        setErrorTimeout("You must fill out all fields to change your password");
        return;
      }

      if (newPassword !== confirmNewPassword) {
        setErrorTimeout("New password does not match confirmation password");
        return;
      }

      setIsChangingPassword(true);
      setError(null);

      const currentUser = await Auth.currentAuthenticatedUser();

      const response = await Auth.changePassword(
        currentUser,
        oldPassword,
        newPassword,
      );

      if (response === "SUCCESS") {
        setSuccess(true);
        setNewPassword("");
        setOldPassword("");
        setConfirmNewPassword("");
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] Error changing password", e);

      if (e.message && e.message.includes("previousPassword")) {
        setErrorTimeout(
          "Your current password is incorrect. Please correct it and try again.",
        );
        return;
      }
      if (e.message && e.message.includes("proposedPassword")) {
        setErrorTimeout(
          "Your new password does not meet the password requirements. Must include letters and numbers and be longer than 6 characters",
        );
        return;
      }

      setErrorTimeout(e.message);
    } finally {
      setIsChangingPassword(false);
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <Alert severity="error" variant="filled" onClose={() => setError(null)}>
          {error}
        </Alert>
      );
    }
  };
  const renderSuccess = () => {
    if (success) {
      return (
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setSuccess(false)}>
          Successfully changed password!
        </Alert>
      );
    }
  };
  return (
    <div>
      <Typography variant="h5">Password</Typography>
      {renderError()}
      {renderSuccess()}
      <FormControl fullWidth>
        <InputLabel htmlFor="current-password">Current Password</InputLabel>
        <Input
          id="auth-password"
          type={isShowPassword ? "text" : "password"}
          onChange={(e) => setOldPassword(e.target.value)}
          fullWidth
          value={oldPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsShowPassword((v) => !v)}
                onMouseDown={handleMouseDownPassword}>
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="new-password">New Password</InputLabel>
        <Input
          id="new-password"
          type={isShowPassword ? "text" : "password"}
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsShowPassword((v) => !v)}
                onMouseDown={handleMouseDownPassword}>
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="confirm-new-password">
          Confirm New Password
        </InputLabel>
        <Input
          id="confirm-new-password"
          type={isShowPassword ? "text" : "password"}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsShowPassword((v) => !v)}
                onMouseDown={handleMouseDownPassword}>
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={isChangingPassword}
        onClick={handleChangePasswordClicked}>
        <Typography variant="button">
          {isChangingPassword ? "Changing" : "Change Password"}
        </Typography>
      </Button>
    </div>
  );
};

export default SettingsPassword;
