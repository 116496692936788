import React, { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OnboardingName from "./Name";
import { StoreProvider } from "./context";
import { Box, Container } from "@mui/material";
import OnboardingUnits from "./Units";
import OnboardingNav from "./Nav";
import OnboardingAddress from "./Address";
import OnboardingType from "./Type";
import OnboardingLogo from "./Logo";

export const ONBOARDING_STEP_COUNT = 5;

export const SHOW_JSON = false;

export const showJson = (store: unknown): ReactElement | null => {
  return SHOW_JSON ? <Box>{JSON.stringify(store, null, 2)}</Box> : null;
};

const OnboardingIndex = (): ReactElement => {
  const match = useRouteMatch();

  return (
    <Container maxWidth="sm">
      <StoreProvider>
        <OnboardingNav />
        <Switch>
          <Route path={`${match.path}/address`}>
            <OnboardingAddress />
          </Route>
          <Route path={`${match.path}/type`}>
            <OnboardingType />
          </Route>
          <Route path={`${match.path}/logo`}>
            <OnboardingLogo />
          </Route>
          <Route path={`${match.path}/units`}>
            <OnboardingUnits />
          </Route>
          <Route path={match.path}>
            <OnboardingName />
          </Route>
        </Switch>
      </StoreProvider>
    </Container>
  );
};

export default OnboardingIndex;
