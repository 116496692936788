import React, {
  useEffect,
  useState,
  ReactElement,
  useCallback,
  useContext,
} from "react";
import { round, uniqBy } from "lodash";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import {
  getClub,
  downloadMembers,
  getMemberAchievementsByClubAndStatus,
  searchMembers,
  searchMembersByEmail,
  getOptionsByClubId,
  uploadMembers,
} from "./backend";
import { CSVLink } from "react-csv";

import { format } from "date-fns";
import TabPanel from "../../components/TabPanel";
import ClubAchievements from "./ClubAchievements";
import { useQuery } from "react-query";
import MembersRange from "./MembersRange";
import CloseIcon from "@mui/icons-material/Close";
import AchievementList from "./AchievementList";
import Loading from "../../components/Loading";
import CurrentUserContext from "src/contexts/CurrentUserContext";
import {
  Alert,
  AlertTitle,
  AppBar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Club, Member } from "src/API";
import { Add, Download, Edit, Upload, Visibility } from "@mui/icons-material";
import { logError, logEvent } from "src/utils/analytics";

const useMemberAchievements = (clubID: string) => {
  const [nextToken, setNextToken] = useState<string | null>(null);
  return useQuery(
    ["club", clubID, "memberAchievements", "new"],
    async () => {
      const results = await getMemberAchievementsByClubAndStatus({
        clubID,
        nextToken,
        status: "new",
      });
      setNextToken(results?.nextToken || null);
      return results?.memberAchievements;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useClubOptions = (clubId: string) => {
  return useQuery(
    ["club", clubId, "options"],
    async () => {
      const result = await getOptionsByClubId(clubId);

      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

type ClubHomeParams = {
  clubId: string;
  option?: string;
};

const ClubHome = (): ReactElement => {
  const theme = useTheme();

  const { clubId, option } = useParams<ClubHomeParams>();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoadingClub, setIsLoadingClub] = useState<boolean>(false);
  const [isSearchingMembers, setIsSearchingMembers] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [filteredMemberList, setFilteredMemberList] = useState<Array<Member>>(
    [],
  );

  const [isUploadingData, setIsUploadingData] = useState<boolean>(false);

  const [isManagingMembers, setIsManagingMembers] = useState<boolean>(false);
  const [club, setClub] = useState<Club | null>(null);
  const [tabValue, setTabValue] = useState<number>(0);

  const [csvData, setCsvData] = useState<Array<Array<string>>>([[]]);
  const [showDownloadCsv, setShowDownloadCsv] = useState<boolean>(false);
  const [showDeletedMembers, setShowDeletedMembers] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState<string | null>(null);

  const [updateExisting, setUpdateExisting] = useState<boolean>(false);

  const currentUser = useContext(CurrentUserContext);

  const [isProcessingUpload, setIsProcessingUpload] = useState<boolean>(false);
  const [isProcessingDownload, setIsProcessingDownload] =
    useState<boolean>(false);

  const { data: memberAchievements } = useMemberAchievements(clubId);

  const { data: clubOptions, status: clubOptionsStatus } =
    useClubOptions(clubId);

  const [uploadData, setUploadData] = useState<File | null>(null);

  const history = useHistory();

  const [memberSearchQuery, setMemberSearchQuery] = useState<string>("");

  const handleSearchQueryChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (value.length === 0) {
      setHasSearched(false);
    }
    setMemberSearchQuery(value);
  };

  const handleShowDeletedMembersChanged = () => {
    setShowDeletedMembers((v) => !v);
    if (memberSearchQuery.length > 2) {
      handleSearchMembers({ showAll: !showDeletedMembers });
    }
  };

  const handleSearchMembers = async ({ showAll }: { showAll?: boolean }) => {
    const showEm = showAll === undefined ? showDeletedMembers : showAll;
    setIsSearchingMembers(true);

    const results = await searchMembers({
      clubId,
      query: memberSearchQuery,
      showAll: showEm,
    });

    const membersByEmail = await searchMembersByEmail(
      clubId,
      memberSearchQuery,
    );

    const members = results && results.members ? results.members : [];
    const allMembers = [...members, ...membersByEmail];

    const uniqueMembers = uniqBy(allMembers, (member) => member.id);
    const sortedMembers = uniqueMembers.sort((a, b) =>
      a.lastName < b.lastName ? -1 : 1,
    );
    setFilteredMemberList(sortedMembers);
    setIsSearchingMembers(false);
    setHasSearched(true);
  };

  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickTab = (tab: string) => {
    history.replace(`/clubs/${clubId}/${tab}`);
  };

  const handleDownloadData = async () => {
    try {
      logEvent("download_members_open", { clubId });
      setIsProcessingDownload(true);
      const members = await downloadMembers(clubId);
      if (members) {
        const csvMembers = members.map((member: Member) => [
          member.firstName,
          member.lastName,
          member.email,
          member.totalScore,
        ]);

        setCsvData(csvMembers as Array<Array<string>>);
        setIsManagingMembers(false);
        setShowDownloadCsv(true);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error handling downloading data", e);
      setDownloadError("Error downloading data");
      logError({
        error: e,
        message: "Error downloading data",
        file: "ClubHome.tsx",
      });
    } finally {
      setTimeout(() => {
        setIsProcessingDownload(false);
      }, 1000);
    }
  };

  const loadClub = useCallback(async () => {
    setIsLoadingClub(true);
    try {
      const results = await getClub(clubId);
      setClub(results);
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error loading club", e);
      logError({
        error: e,
        message: "Error loading club",
        file: "ClubHome.tsx",
      });
    } finally {
      setIsLoadingClub(false);
    }
  }, [clubId]);

  useEffect(() => {
    loadClub();
  }, [loadClub]);

  useEffect(() => {
    switch (option) {
      case "member-list":
        setTabValue(0);
        break;
      case "member-achievements":
        setTabValue(1);
        break;
      case "member-range":
        setTabValue(2);
        break;
      case "achievements":
        setTabValue(3);
        break;
      default:
        setTabValue(0);
        break;
    }
  }, [option]);

  const handleOpenManageData = () => {
    setIsManagingMembers(true);
  };

  const handleUploadData = () => {
    setIsUploadingData(true);
    setIsManagingMembers(false);
  };

  const handleCloseUploadingMember = () => {
    setIsManagingMembers(false);
    setIsUploadingData(false);
  };

  const handleProcessUpload = async () => {
    try {
      setIsProcessingUpload(true);
      setUploadSuccess(null);
      if (uploadData) {
        const allowedExtensions = ["csv"];
        const fileExtension = uploadData?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          setError("Please input a csv file");
          return;
        }

        const result = await uploadMembers({
          clubId,
          file: uploadData,
          updateExisting,
        });

        if (result.success) {
          logEvent("upload_members_success", { clubId });
          setUploadSuccess(
            `Create: ${result.createCount}. Updated ${result.updateCount}.`,
          );
          setUpdateExisting(false);
          setUploadData(null);
        }
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error processing upload", e);
      setError("Error processing upload. Please try again.");
      logError({
        error: e,
        message: "Error processing upload",
        file: "ClubHome.tsx",
      });
    } finally {
      setIsProcessingUpload(false);
    }
  };

  const renderNeedsAttention = () => {
    if (clubOptionsStatus === "loading") {
      return null;
    }
    if (clubOptions && clubOptions.length === 0) {
      return (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>You do not have any check-in options.</AlertTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}>
            <Button
              variant="contained"
              color="warning"
              startIcon={<Add />}
              onClick={() => history.push(`/clubs/${clubId}/edit/options`)}>
              Add options
            </Button>
            <Typography sx={{ my: 1, mx: 2, textAlign: "center" }}>
              if you are new to RunClub:
            </Typography>
            <Button
              variant="contained"
              color="warning"
              startIcon={<Visibility />}
              onClick={() => {
                logEvent("onboarding_open", { clubId });
                history.push("/onboarding");
              }}>
              View onboarding help.
            </Button>
          </Box>
        </Alert>
      );
    }
  };

  const renderMobileMembers = () => {
    return (
      <Box>
        {!isSearchingMembers &&
          filteredMemberList.map((member) => (
            <Card key={`member-${member.id}`} sx={{ mb: 1 }}>
              <CardHeader
                action={
                  <Chip
                    color={member.deletedAt ? "error" : "primary"}
                    label={member.deletedAt ? "DELETED" : "ACTIVE"}
                  />
                }
                title={`${member.firstName} ${member.lastName}`}
                subheader={member.email}
              />
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h1" fontWeight="bold" textAlign="center">
                  {round(member.totalScore, 1)} {club?.scoreUnits}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  onClick={() =>
                    history.push(`/clubs/${clubId}/members/${member.id}/edit`)
                  }>
                  Edit
                </Button>
              </CardActions>
            </Card>
          ))}
        {!isSearchingMembers && filteredMemberList.length === 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Typography variant="h3">No Results</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderDesktopMembers = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="club table">
          <TableHead>
            <TableRow>
              <TableCell>first name</TableCell>
              <TableCell>last name</TableCell>
              <TableCell>email</TableCell>
              <TableCell>{club?.scoreUnits}</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isSearchingMembers &&
              filteredMemberList.map((member) => (
                <TableRow
                  key={`member-${member.id}`}
                  sx={{
                    backgroundColor: member.deletedAt
                      ? "rgba(255, 0, 0, 0.1)"
                      : "inherit",
                  }}>
                  <TableCell component="th" scope="row">
                    <RouterLink to={`/clubs/${clubId}/members/${member.id}`}>
                      {member.firstName}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{member.lastName}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{round(member.totalScore, 2)}</TableCell>
                  <TableCell>
                    <Chip
                      color={member.deletedAt ? "error" : "primary"}
                      label={member.deletedAt ? "DELETED" : "ACTIVE"}
                    />
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={`/clubs/${clubId}/members/${member.id}/edit`}>
                      Edit
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            {!isSearchingMembers && filteredMemberList.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  No Results
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderMembers = () => {
    const newAchievements = memberAchievements
      ? memberAchievements.filter(
          (a) => (a.hasSwag && !a.swagReceived) || a.isNew,
        )
      : [];

    const date = format(new Date(), "yyyy-MM-dd");
    return (
      <>
        <Dialog
          open={showDownloadCsv}
          onClose={() => setShowDownloadCsv(false)}
          aria-labelledby="download-csv-modal"
          aria-describedby="dowload-csv-modal">
          <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setShowDownloadCsv(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <h2>Members file ready for download...</h2>
            <CSVLink
              data={csvData}
              style={{
                border: `1px solid ${theme.palette.primary.main}`,
                padding: 10,
                borderRadius: 100,
                textDecoration: "none",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
              onClick={() => {
                logEvent("download_csv", { clubId });
                setShowDownloadCsv(false);
              }}
              headers={["First Name", "Last Name", "Email", "Total Score"]}
              filename={`${club?.name}-members-${date}.csv`}>
              Download File
            </CSVLink>
          </DialogContent>
        </Dialog>
        <AppBar color="transparent" position="static">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            scrollButtons="auto"
            orientation={isMobile ? "vertical" : "horizontal"}
            aria-label="Club home tab bar">
            <Tab
              label="Members"
              onClick={() => handleClickTab("member-list")}
            />
            <Tab
              onClick={() => handleClickTab("member-achievements")}
              label={
                <Badge badgeContent={newAchievements.length} color="primary">
                  Member Achievements
                </Badge>
              }
            />
            <Tab
              label="Member Date Range"
              onClick={() => handleClickTab("member-range")}
            />
            <Tab
              label="Achievements"
              onClick={() => handleClickTab("achievements")}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0} boxStyles={{ pt: 3 }}>
          {renderNeedsAttention()}
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchMembers({});
              }}>
              <div style={{ display: "flex", flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  type="search"
                  placeholder="Search Members"
                  sx={{ p: 0, m: 0 }}
                  InputProps={{
                    sx: {
                      p: 0,
                      m: 0,
                    },
                    endAdornment: (
                      <Button
                        onClick={() => handleSearchMembers({})}
                        sx={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 2,
                          borderTopRightRadius: 2,
                          alignSelf: "stretch",
                        }}
                        variant="contained"
                        color="primary">
                        Search
                      </Button>
                    ),
                  }}
                  onChange={handleSearchQueryChanged}
                />
              </div>
            </form>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDeletedMembers}
                    onChange={handleShowDeletedMembersChanged}
                  />
                }
                label="Show deleted members?"
              />
            </Box>
          </div>

          {isSearchingMembers && <Loading />}

          {!isSearchingMembers &&
            hasSearched &&
            (isMobile ? renderMobileMembers() : renderDesktopMembers())}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ClubAchievements />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <MembersRange />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <AchievementList />
        </TabPanel>
      </>
    );
  };

  const renderUploadForm = () => {
    if (isProcessingUpload) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Loading />
          <Typography>Uploading...</Typography>
        </Box>
      );
    }

    return (
      <>
        <Typography variant="body2">When ready, upload the CSV here</Typography>
        <Paper elevation={10} sx={{ mt: 1, p: 2 }}>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setUploadData(e.target.files[0]);
              }
            }}
          />
          <Divider sx={{ mt: 2 }} />
          <FormControlLabel
            label="Update existing members?"
            control={
              <Switch
                title="Update existing members?"
                checked={updateExisting || false}
                onChange={() => setUpdateExisting((s) => !s)}
              />
            }></FormControlLabel>
          <br />
          <Typography variant="caption">
            Update existing will overwrite name &amp; score of existing members
            based on email.
          </Typography>
        </Paper>
      </>
    );
  };

  if (isLoadingClub) {
    return <Loading />;
  }
  if (club && currentUser && club.owner !== currentUser.username) {
    return (
      <Alert severity="error">
        <Typography variant="body2">
          You do not have permission to view this club.
        </Typography>
      </Alert>
    );
  }
  if (club) {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/clubs">
            All Clubs
          </RouterLink>
          <Typography color="textPrimary">{club.name}</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: { xs: 2, md: 0 },
            mb: 2,
          }}>
          <ButtonGroup
            variant="contained"
            size="small"
            sx={{ borderRadius: 100, mb: 1 }}>
            <Button onClick={handleOpenManageData}>Manage Member Data</Button>
            <Button
              onClick={() => history.push(`/clubs/${clubId}/edit/general`)}>
              Edit Club
            </Button>
          </ButtonGroup>
        </Box>
        {renderMembers()}

        <Dialog open={isUploadingData} onClose={handleCloseUploadingMember}>
          <DialogTitle>Upload Member Data</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Use the following CSV to organize your member data
            </Typography>
            <CSVLink
              data={[]}
              filename="member-data-template.csv"
              headers={["First Name", "Last Name", "Email", "Total Score"]}
              onClick={() => {
                logEvent("download_member_data_template", {
                  clubId,
                });
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px solid ${theme.palette.primary.main}`,
                padding: 10,
                borderRadius: 100,
                textDecoration: "none",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}>
              <Download /> Download CSV Template
            </CSVLink>
            <Divider sx={{ mt: 2, mb: 1 }} />

            {error && <Alert severity="error">{error}</Alert>}
            {uploadSuccess && (
              <Alert
                severity="success"
                action={
                  <Button onClick={() => setUploadSuccess(null)}>Noice!</Button>
                }>
                {uploadSuccess}
              </Alert>
            )}
            {!uploadSuccess && renderUploadForm()}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={!uploadData}
              onClick={handleProcessUpload}>
              Upload Members
            </Button>
            <Button onClick={handleCloseUploadingMember}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isManagingMembers && !isUploadingData}>
          <DialogTitle>Manage Member Data</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            {downloadError && (
              <Alert sx={{ mb: 2 }} severity="error">
                {downloadError}
              </Alert>
            )}
            {isProcessingDownload && <Loading />}
            {!isProcessingDownload && (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mb: 2 }}
                  startIcon={<Upload />}
                  onClick={handleUploadData}>
                  Upload member data
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<Download />}
                  onClick={handleDownloadData}>
                  Download member data
                </Button>
              </>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  return <div>Loading Club...</div>;
};

export default ClubHome;
