import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from "react";

import { v4 as uuidv4 } from "uuid";

export type CLUB_TYPES_TYPES =
  | "RUN_CLUB"
  | "BIKE_CLUB"
  | "SOCIAL_CLUB"
  | "GENERAL_CLUB";

export const CLUB_TYPES: Array<{ key: CLUB_TYPES_TYPES; label: string }> = [
  { key: "RUN_CLUB", label: "Run Club" },
  { key: "BIKE_CLUB", label: "Bike Club" },
  { key: "SOCIAL_CLUB", label: "Social Club" },
  { key: "GENERAL_CLUB", label: "General Club" },
];

type State = {
  clubID: string;
  currentStep: number;
  address?: string;
  city?: string;
  state?: string;
  latitude?: number;
  longitude?: number;
  type?: CLUB_TYPES_TYPES;
  name?: string;
  units?: string;
  customMaxScore?: number;
  logoUrl?: string;
};

type SetClubName = { type: "SET_NAME"; payload: string };
type SetClubUnits = { type: "SET_UNITS"; payload: string };
type SetCurrentStep = { type: "SET_STEP"; payload: number };
type SetAddress = {
  type: "SET_ADDRESS";
  payload: {
    address: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
  };
};
type ClearCustomMaxScore = {
  type: "CLEAR_CUSTOM_MAX_SCORE";
};
type SetCustomMaxScore = {
  type: "SET_CUSTOM_MAX_SCORE";
  payload: number;
};
type SetLogoUrl = {
  type: "SET_LOGO_URL";
  payload: string;
};
type SetClubType = {
  type: "SET_TYPE";
  payload: CLUB_TYPES_TYPES;
};
type Reset = {
  type: "RESET";
};

type Action =
  | SetClubName
  | SetClubUnits
  | SetCurrentStep
  | SetAddress
  | SetCustomMaxScore
  | SetLogoUrl
  | SetClubType
  | Reset
  | ClearCustomMaxScore;

const blankState = {
  currentStep: 0,
  clubID: uuidv4(),
};
const savedState = localStorage.getItem("club-onboarding");
const initialState = savedState ? JSON.parse(savedState) : blankState;

const reducer = (state: State, action: Action): State => {
  let newState = state;
  switch (action.type) {
    case "RESET":
      newState = blankState;
      newState.clubID = uuidv4();
      break;
    case "CLEAR_CUSTOM_MAX_SCORE":
      newState = {
        ...state,
        customMaxScore: undefined,
      };
      break;
    case "SET_TYPE":
      newState = {
        ...state,
        type: action.payload,
      };
      break;
    case "SET_STEP":
      newState = {
        ...state,
        currentStep: action.payload,
      };
      break;
    case "SET_LOGO_URL":
      newState = {
        ...state,
        logoUrl: action.payload,
      };
      break;
    case "SET_CUSTOM_MAX_SCORE":
      newState = {
        ...state,
        customMaxScore: action.payload,
      };
      break;
    case "SET_ADDRESS":
      newState = {
        ...state,
        address: action.payload.address,
        city: action.payload.city,
        state: action.payload.state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
      break;
    case "SET_NAME":
      newState = {
        ...state,
        name: action.payload,
      };
      break;
    case "SET_UNITS":
      newState = {
        ...state,
        units: action.payload,
      };
      break;
    default:
      return state;
  }
  localStorage.setItem("club-onboarding", JSON.stringify(newState));
  return newState;
};

const storeContext = createContext<State>(initialState);
const dispatchContext = createContext<(a: Action) => void>(() => initialState);
export const createClubContext = createContext<() => void>(() => null);

type StoreProviderProps = {
  children: ReactNode;
};

export const StoreProvider = ({
  children,
}: StoreProviderProps): ReactElement => {
  const [store, dispatch] = useReducer(reducer, initialState);

  return (
    <storeContext.Provider value={store}>
      <dispatchContext.Provider value={dispatch}>
        {children}
      </dispatchContext.Provider>
    </storeContext.Provider>
  );
};

export const useStore = (): State => {
  return useContext(storeContext);
};

type UseDispatchResult = (a: Action) => void;

export const useDispatch = (): UseDispatchResult => {
  return useContext(dispatchContext);
};

type UseCreateClubContext = () => void;

export const useCreateClub = (): UseCreateClubContext => {
  return useContext(createClubContext);
};
