import React, { ReactElement, useState } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";

import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu, People, SettingsApplications } from "@mui/icons-material";

const Navigation = (): ReactElement => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);

  const handleSignOutPressed = () => {
    const result = window.confirm("Are you sure you want to sign out?");

    if (result) {
      history.replace("/");
      localStorage.clear();
      Auth.signOut();
    }
  };
  if (isMobile) {
    return (
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar>
            <Box sx={{ display: "flex", flex: 1 }}>
              <div className="icon">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setIsMobileOpen((v) => !v)}
                  aria-label="menu">
                  <Menu />
                </IconButton>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <img
                  src="/icon-144x144-transparent.png"
                  alt="logo"
                  width="50"
                  height="50"
                />
              </Box>
              <Button style={{ color: "#fff" }} onClick={handleSignOutPressed}>
                Sign Out
              </Button>
            </Box>
          </Toolbar>
          <Drawer
            anchor="left"
            open={isMobileOpen}
            onClose={() => setIsMobileOpen(false)}>
            <Box sx={{ width: 250 }}>
              <List>
                <ListItem key={`clubs`} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsMobileOpen(false);
                      history.push("/clubs");
                    }}>
                    <ListItemIcon>
                      <People />
                    </ListItemIcon>
                    <ListItemText primary={`Clubs`} />
                  </ListItemButton>
                </ListItem>
                <ListItem key={`settings`} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setIsMobileOpen(false);
                      history.push("/settings");
                    }}>
                    <ListItemIcon>
                      <SettingsApplications />
                    </ListItemIcon>
                    <ListItemText primary={`User Settings`} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Container>
      </AppBar>
    );
  }
  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          <RouterLink
            style={{ textDecoration: "none", color: "white", marginRight: 10 }}
            to="/clubs">
            Clubs
          </RouterLink>
          <RouterLink
            style={{ textDecoration: "none", color: "white" }}
            to="/settings">
            User Settings
          </RouterLink>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src="/icon-144x144-transparent.png"
              alt="logo"
              width="50"
              height="50"
            />
          </Box>
          <Button style={{ color: "#fff" }} onClick={handleSignOutPressed}>
            Sign Out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
