/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCheckinResponse = {
  __typename: "CreateCheckinResponse",
  checkin?: Checkin | null,
  member?: Member | null,
  memberAchievements?:  Array<MemberAchievement | null > | null,
  message?: string | null,
};

export type Checkin = {
  __typename: "Checkin",
  id: string,
  owner?: string | null,
  date: string,
  score: number,
  label: string,
  memberID: string,
  clubID: string,
  weather?: CheckinWeather | null,
  managerGroup?: string | null,
  createdAt: string,
  updatedAt: string,
  member?: Member | null,
};

export type CheckinWeather = {
  __typename: "CheckinWeather",
  apparentTemperature: number,
  cloudCover: number,
  dewPoint: number,
  humidity: number,
  nearestStormDistance?: number | null,
  nearestStormBearing?: number | null,
  precipIntensity?: number | null,
  precipProbability?: number | null,
  precipType?: string | null,
  sunriseTime?: string | null,
  sunsetTime?: string | null,
  temperature: number,
};

export type Member = {
  __typename: "Member",
  id: string,
  owner?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  genderIdentity?: string | null,
  birthday?: string | null,
  totalScore: number,
  clubID: string,
  userId: string,
  managers?: Array< string | null > | null,
  managerGroup?: string | null,
  customMemberFieldValues?:  Array<MemberFieldValue | null > | null,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string | null,
  memberAchievements?: ModelMemberAchievementConnection | null,
  club?: Club | null,
  checkins?: ModelCheckinConnection | null,
};

export type MemberFieldValue = {
  __typename: "MemberFieldValue",
  label?: string | null,
  value?: string | null,
};

export type ModelMemberAchievementConnection = {
  __typename: "ModelMemberAchievementConnection",
  items:  Array<MemberAchievement | null >,
  nextToken?: string | null,
};

export type MemberAchievement = {
  __typename: "MemberAchievement",
  id: string,
  memberID: string,
  achievementID: string,
  clubID: string,
  checkinID: string,
  dateAchieved: string,
  hasSwag: boolean,
  swagReceived: boolean,
  swagReceivedDate?: string | null,
  isNew?: boolean | null,
  status?: string | null,
  managerGroup?: string | null,
  createdAt: string,
  updatedAt: string,
  achievement?: Achievement | null,
  club?: Club | null,
  member?: Member | null,
  checkin?: Checkin | null,
};

export type Achievement = {
  __typename: "Achievement",
  id: string,
  clubID: string,
  name: string,
  description: string,
  type: AchievementType,
  imageUrl?: string | null,
  value?: number | null,
  start?: string | null,
  end?: string | null,
  swag?: string | null,
  isManualAssign?: boolean | null,
  deletedAt?: string | null,
  managerGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum AchievementType {
  SCORE = "SCORE",
  STREAK = "STREAK",
  RANGE = "RANGE",
}


export type Club = {
  __typename: "Club",
  id: string,
  owner?: string | null,
  name: string,
  address: string,
  clubType?: string | null,
  city: string,
  state: string,
  zipcode?: string | null,
  latitude: number,
  longitude: number,
  scoreUnits: string,
  hashedPinCode?: string | null,
  finishedText?: string | null,
  customMaxScore?: number | null,
  clubSlug?: string | null,
  customMemberFields?:  Array<MemberField | null > | null,
  showCustomOption?: boolean | null,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string | null,
  managerGroup?: string | null,
  logoUrl?: string | null,
  routesPathsUrl?: string | null,
  isPublic?: boolean | null,
  schedule?: ClubSchedule | null,
  wantsWeeklyStats?: boolean | null,
  facebookSlug?: string | null,
  instagramSlug?: string | null,
  twitterSlug?: string | null,
  ticTokSlug?: string | null,
  achievments?: ModelAchievementConnection | null,
  approvalStatus?: ClubApprovalStatus | null,
  checkinOptions?: ModelCheckinOptionConnection | null,
  members?: ModelMemberConnection | null,
};

export type MemberField = {
  __typename: "MemberField",
  label?: string | null,
  slug?: string | null,
  type?: MemberFieldType | null,
  selectOptions?: Array< string | null > | null,
};

export enum MemberFieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  SELECT = "SELECT",
}


export type ClubSchedule = {
  __typename: "ClubSchedule",
  monday?: number | null,
  tuesday?: number | null,
  wednesday?: number | null,
  thursday?: number | null,
  friday?: number | null,
  saturday?: number | null,
  sunday?: number | null,
  mondayAddress?: string | null,
  tuesdayAddress?: string | null,
  wednesdayAddress?: string | null,
  thursdayAddress?: string | null,
  fridayAddress?: string | null,
  saturdayAddress?: string | null,
  sundayAddress?: string | null,
};

export type ModelAchievementConnection = {
  __typename: "ModelAchievementConnection",
  items:  Array<Achievement | null >,
  nextToken?: string | null,
};

export enum ClubApprovalStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}


export type ModelCheckinOptionConnection = {
  __typename: "ModelCheckinOptionConnection",
  items:  Array<CheckinOption | null >,
  nextToken?: string | null,
};

export type CheckinOption = {
  __typename: "CheckinOption",
  id: string,
  managerGroup?: string | null,
  owner?: string | null,
  label: string,
  score: number,
  order: number,
  clubID: string,
  status?: CheckinOptionStatus | null,
  createdAt: string,
  updatedAt: string,
};

export enum CheckinOptionStatus {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
}


export type ModelMemberConnection = {
  __typename: "ModelMemberConnection",
  items:  Array<Member | null >,
  nextToken?: string | null,
};

export type ModelCheckinConnection = {
  __typename: "ModelCheckinConnection",
  items:  Array<Checkin | null >,
  nextToken?: string | null,
};

export type DeleteCheckinResponse = {
  __typename: "DeleteCheckinResponse",
  status?: string | null,
  message?: string | null,
};

export type CreateClubSecretInput = {
  id?: string | null,
  clubID: string,
  secret: string,
};

export type ModelClubSecretConditionInput = {
  clubID?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  and?: Array< ModelClubSecretConditionInput | null > | null,
  or?: Array< ModelClubSecretConditionInput | null > | null,
  not?: ModelClubSecretConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ClubSecret = {
  __typename: "ClubSecret",
  id: string,
  clubID: string,
  secret: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateClubSecretInput = {
  id: string,
  clubID?: string | null,
  secret?: string | null,
};

export type DeleteClubSecretInput = {
  id: string,
};

export type CreateMemberSecretInput = {
  id?: string | null,
  memberID: string,
  secret: string,
};

export type ModelMemberSecretConditionInput = {
  memberID?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  and?: Array< ModelMemberSecretConditionInput | null > | null,
  or?: Array< ModelMemberSecretConditionInput | null > | null,
  not?: ModelMemberSecretConditionInput | null,
};

export type MemberSecret = {
  __typename: "MemberSecret",
  id: string,
  memberID: string,
  secret: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMemberSecretInput = {
  id: string,
  memberID?: string | null,
  secret?: string | null,
};

export type DeleteMemberSecretInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  authUserId?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  birthday?: string | null,
  genderIdentity?: string | null,
  isPrivate?: boolean | null,
};

export type ModelUserConditionInput = {
  authUserId?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  genderIdentity?: ModelStringInput | null,
  isPrivate?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  authUserId?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  birthday?: string | null,
  genderIdentity?: string | null,
  isPrivate?: boolean | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserInput = {
  id: string,
  authUserId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  birthday?: string | null,
  genderIdentity?: string | null,
  isPrivate?: boolean | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateDoNotEmailInput = {
  id?: string | null,
  email: string,
  description?: string | null,
};

export type ModelDoNotEmailConditionInput = {
  email?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDoNotEmailConditionInput | null > | null,
  or?: Array< ModelDoNotEmailConditionInput | null > | null,
  not?: ModelDoNotEmailConditionInput | null,
};

export type DoNotEmail = {
  __typename: "DoNotEmail",
  id: string,
  email: string,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDoNotEmailInput = {
  id: string,
  email?: string | null,
  description?: string | null,
};

export type DeleteDoNotEmailInput = {
  id: string,
};

export type CreateAchievementInput = {
  id?: string | null,
  clubID: string,
  name: string,
  description: string,
  type: AchievementType,
  imageUrl?: string | null,
  value?: number | null,
  start?: string | null,
  end?: string | null,
  swag?: string | null,
  isManualAssign?: boolean | null,
  deletedAt?: string | null,
  managerGroup?: string | null,
};

export type ModelAchievementConditionInput = {
  clubID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelAchievementTypeInput | null,
  imageUrl?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  swag?: ModelStringInput | null,
  isManualAssign?: ModelBooleanInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelAchievementConditionInput | null > | null,
  or?: Array< ModelAchievementConditionInput | null > | null,
  not?: ModelAchievementConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAchievementTypeInput = {
  eq?: AchievementType | null,
  ne?: AchievementType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateAchievementInput = {
  id: string,
  clubID?: string | null,
  name?: string | null,
  description?: string | null,
  type?: AchievementType | null,
  imageUrl?: string | null,
  value?: number | null,
  start?: string | null,
  end?: string | null,
  swag?: string | null,
  isManualAssign?: boolean | null,
  deletedAt?: string | null,
  managerGroup?: string | null,
};

export type DeleteAchievementInput = {
  id: string,
};

export type CreateMemberAchievementInput = {
  id?: string | null,
  memberID: string,
  achievementID: string,
  clubID: string,
  checkinID: string,
  dateAchieved: string,
  hasSwag: boolean,
  swagReceived: boolean,
  swagReceivedDate?: string | null,
  isNew?: boolean | null,
  status?: string | null,
  managerGroup?: string | null,
};

export type ModelMemberAchievementConditionInput = {
  memberID?: ModelIDInput | null,
  achievementID?: ModelIDInput | null,
  clubID?: ModelIDInput | null,
  checkinID?: ModelIDInput | null,
  dateAchieved?: ModelStringInput | null,
  hasSwag?: ModelBooleanInput | null,
  swagReceived?: ModelBooleanInput | null,
  swagReceivedDate?: ModelStringInput | null,
  isNew?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelMemberAchievementConditionInput | null > | null,
  or?: Array< ModelMemberAchievementConditionInput | null > | null,
  not?: ModelMemberAchievementConditionInput | null,
};

export type UpdateMemberAchievementInput = {
  id: string,
  memberID?: string | null,
  achievementID?: string | null,
  clubID?: string | null,
  checkinID?: string | null,
  dateAchieved?: string | null,
  hasSwag?: boolean | null,
  swagReceived?: boolean | null,
  swagReceivedDate?: string | null,
  isNew?: boolean | null,
  status?: string | null,
  managerGroup?: string | null,
};

export type DeleteMemberAchievementInput = {
  id: string,
};

export type CreateClubInput = {
  id?: string | null,
  owner?: string | null,
  name: string,
  address: string,
  clubType?: string | null,
  city: string,
  state: string,
  zipcode?: string | null,
  latitude: number,
  longitude: number,
  scoreUnits: string,
  hashedPinCode?: string | null,
  finishedText?: string | null,
  customMaxScore?: number | null,
  clubSlug?: string | null,
  customMemberFields?: Array< MemberFieldInput | null > | null,
  showCustomOption?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  managerGroup?: string | null,
  approvalStatus?: ClubApprovalStatus | null,
  logoUrl?: string | null,
  routesPathsUrl?: string | null,
  isPublic?: boolean | null,
  schedule?: ClubScheduleInput | null,
  wantsWeeklyStats?: boolean | null,
  facebookSlug?: string | null,
  instagramSlug?: string | null,
  twitterSlug?: string | null,
  ticTokSlug?: string | null,
};

export type MemberFieldInput = {
  label?: string | null,
  slug?: string | null,
  type?: MemberFieldType | null,
  selectOptions?: Array< string | null > | null,
};

export type ClubScheduleInput = {
  monday?: number | null,
  tuesday?: number | null,
  wednesday?: number | null,
  thursday?: number | null,
  friday?: number | null,
  saturday?: number | null,
  sunday?: number | null,
  mondayAddress?: string | null,
  tuesdayAddress?: string | null,
  wednesdayAddress?: string | null,
  thursdayAddress?: string | null,
  fridayAddress?: string | null,
  saturdayAddress?: string | null,
  sundayAddress?: string | null,
};

export type ModelClubConditionInput = {
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  clubType?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  scoreUnits?: ModelStringInput | null,
  hashedPinCode?: ModelStringInput | null,
  finishedText?: ModelStringInput | null,
  customMaxScore?: ModelFloatInput | null,
  clubSlug?: ModelStringInput | null,
  showCustomOption?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  approvalStatus?: ModelClubApprovalStatusInput | null,
  logoUrl?: ModelStringInput | null,
  routesPathsUrl?: ModelStringInput | null,
  isPublic?: ModelBooleanInput | null,
  wantsWeeklyStats?: ModelBooleanInput | null,
  facebookSlug?: ModelStringInput | null,
  instagramSlug?: ModelStringInput | null,
  twitterSlug?: ModelStringInput | null,
  ticTokSlug?: ModelStringInput | null,
  and?: Array< ModelClubConditionInput | null > | null,
  or?: Array< ModelClubConditionInput | null > | null,
  not?: ModelClubConditionInput | null,
};

export type ModelClubApprovalStatusInput = {
  eq?: ClubApprovalStatus | null,
  ne?: ClubApprovalStatus | null,
};

export type UpdateClubInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  address?: string | null,
  clubType?: string | null,
  city?: string | null,
  state?: string | null,
  zipcode?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  scoreUnits?: string | null,
  hashedPinCode?: string | null,
  finishedText?: string | null,
  customMaxScore?: number | null,
  clubSlug?: string | null,
  customMemberFields?: Array< MemberFieldInput | null > | null,
  showCustomOption?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  managerGroup?: string | null,
  approvalStatus?: ClubApprovalStatus | null,
  logoUrl?: string | null,
  routesPathsUrl?: string | null,
  isPublic?: boolean | null,
  schedule?: ClubScheduleInput | null,
  wantsWeeklyStats?: boolean | null,
  facebookSlug?: string | null,
  instagramSlug?: string | null,
  twitterSlug?: string | null,
  ticTokSlug?: string | null,
};

export type DeleteClubInput = {
  id: string,
};

export type CreateClubManagerInput = {
  id?: string | null,
  userId: string,
  clubId: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
};

export type ModelClubManagerConditionInput = {
  clubId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelClubManagerConditionInput | null > | null,
  or?: Array< ModelClubManagerConditionInput | null > | null,
  not?: ModelClubManagerConditionInput | null,
};

export type ClubManager = {
  __typename: "ClubManager",
  id: string,
  userId: string,
  clubId: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  club?: Club | null,
};

export type UpdateClubManagerInput = {
  id: string,
  userId?: string | null,
  clubId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
};

export type DeleteClubManagerInput = {
  id: string,
};

export type CreateClubManagerRequestInput = {
  id?: string | null,
  userId: string,
  clubId: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  message?: string | null,
  status?: ClubManagerRequestStatus | null,
};

export enum ClubManagerRequestStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}


export type ModelClubManagerRequestConditionInput = {
  clubId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  message?: ModelStringInput | null,
  status?: ModelClubManagerRequestStatusInput | null,
  and?: Array< ModelClubManagerRequestConditionInput | null > | null,
  or?: Array< ModelClubManagerRequestConditionInput | null > | null,
  not?: ModelClubManagerRequestConditionInput | null,
};

export type ModelClubManagerRequestStatusInput = {
  eq?: ClubManagerRequestStatus | null,
  ne?: ClubManagerRequestStatus | null,
};

export type ClubManagerRequest = {
  __typename: "ClubManagerRequest",
  id: string,
  userId: string,
  clubId: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  message?: string | null,
  status?: ClubManagerRequestStatus | null,
  createdAt: string,
  updatedAt: string,
  user?: User | null,
  club?: Club | null,
};

export type UpdateClubManagerRequestInput = {
  id: string,
  userId?: string | null,
  clubId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  message?: string | null,
  status?: ClubManagerRequestStatus | null,
};

export type DeleteClubManagerRequestInput = {
  id: string,
};

export type CreateCheckinOptionInput = {
  id?: string | null,
  managerGroup?: string | null,
  owner?: string | null,
  label: string,
  score: number,
  order: number,
  clubID: string,
  status?: CheckinOptionStatus | null,
};

export type ModelCheckinOptionConditionInput = {
  label?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  order?: ModelFloatInput | null,
  clubID?: ModelIDInput | null,
  status?: ModelCheckinOptionStatusInput | null,
  and?: Array< ModelCheckinOptionConditionInput | null > | null,
  or?: Array< ModelCheckinOptionConditionInput | null > | null,
  not?: ModelCheckinOptionConditionInput | null,
};

export type ModelCheckinOptionStatusInput = {
  eq?: CheckinOptionStatus | null,
  ne?: CheckinOptionStatus | null,
};

export type UpdateCheckinOptionInput = {
  id: string,
  managerGroup?: string | null,
  owner?: string | null,
  label?: string | null,
  score?: number | null,
  order?: number | null,
  clubID?: string | null,
  status?: CheckinOptionStatus | null,
};

export type DeleteCheckinOptionInput = {
  id: string,
};

export type CreateMemberInput = {
  id?: string | null,
  owner?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  genderIdentity?: string | null,
  birthday?: string | null,
  totalScore: number,
  clubID: string,
  userId: string,
  managers?: Array< string | null > | null,
  managerGroup?: string | null,
  customMemberFieldValues?: Array< MemberFieldValueInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  memberClubId?: string | null,
};

export type MemberFieldValueInput = {
  label?: string | null,
  value?: string | null,
};

export type ModelMemberConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  genderIdentity?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  totalScore?: ModelFloatInput | null,
  clubID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelMemberConditionInput | null > | null,
  or?: Array< ModelMemberConditionInput | null > | null,
  not?: ModelMemberConditionInput | null,
};

export type UpdateMemberInput = {
  id: string,
  owner?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  genderIdentity?: string | null,
  birthday?: string | null,
  totalScore?: number | null,
  clubID?: string | null,
  userId?: string | null,
  managers?: Array< string | null > | null,
  managerGroup?: string | null,
  customMemberFieldValues?: Array< MemberFieldValueInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deletedAt?: string | null,
  memberClubId?: string | null,
};

export type DeleteMemberInput = {
  id: string,
};

export type CreateCheckinInput = {
  id?: string | null,
  owner?: string | null,
  date: string,
  score: number,
  label: string,
  memberID: string,
  clubID: string,
  weather?: CheckinWeatherInput | null,
  managerGroup?: string | null,
  checkinMemberId?: string | null,
};

export type CheckinWeatherInput = {
  apparentTemperature: number,
  cloudCover: number,
  dewPoint: number,
  humidity: number,
  nearestStormDistance?: number | null,
  nearestStormBearing?: number | null,
  precipIntensity?: number | null,
  precipProbability?: number | null,
  precipType?: string | null,
  sunriseTime?: string | null,
  sunsetTime?: string | null,
  temperature: number,
};

export type ModelCheckinConditionInput = {
  date?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  label?: ModelStringInput | null,
  memberID?: ModelIDInput | null,
  clubID?: ModelIDInput | null,
  and?: Array< ModelCheckinConditionInput | null > | null,
  or?: Array< ModelCheckinConditionInput | null > | null,
  not?: ModelCheckinConditionInput | null,
};

export type UpdateCheckinInput = {
  id: string,
  owner?: string | null,
  date?: string | null,
  score?: number | null,
  label?: string | null,
  memberID?: string | null,
  clubID?: string | null,
  weather?: CheckinWeatherInput | null,
  managerGroup?: string | null,
  checkinMemberId?: string | null,
};

export type DeleteCheckinInput = {
  id: string,
};

export type GetCheckinHashResponse = {
  __typename: "GetCheckinHashResponse",
  status?: string | null,
  hash?: string | null,
  message?: string | null,
};

export type FindableMemberConnection = {
  __typename: "FindableMemberConnection",
  items?:  Array<Member | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelClubSecretFilterInput = {
  id?: ModelIDInput | null,
  clubID?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  and?: Array< ModelClubSecretFilterInput | null > | null,
  or?: Array< ModelClubSecretFilterInput | null > | null,
  not?: ModelClubSecretFilterInput | null,
};

export type ModelClubSecretConnection = {
  __typename: "ModelClubSecretConnection",
  items:  Array<ClubSecret | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMemberSecretFilterInput = {
  id?: ModelIDInput | null,
  memberID?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  and?: Array< ModelMemberSecretFilterInput | null > | null,
  or?: Array< ModelMemberSecretFilterInput | null > | null,
  not?: ModelMemberSecretFilterInput | null,
};

export type ModelMemberSecretConnection = {
  __typename: "ModelMemberSecretConnection",
  items:  Array<MemberSecret | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  authUserId?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  genderIdentity?: ModelStringInput | null,
  isPrivate?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelDoNotEmailFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDoNotEmailFilterInput | null > | null,
  or?: Array< ModelDoNotEmailFilterInput | null > | null,
  not?: ModelDoNotEmailFilterInput | null,
};

export type ModelDoNotEmailConnection = {
  __typename: "ModelDoNotEmailConnection",
  items:  Array<DoNotEmail | null >,
  nextToken?: string | null,
};

export type ModelAchievementFilterInput = {
  id?: ModelIDInput | null,
  clubID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelAchievementTypeInput | null,
  imageUrl?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  swag?: ModelStringInput | null,
  isManualAssign?: ModelBooleanInput | null,
  deletedAt?: ModelStringInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelAchievementFilterInput | null > | null,
  or?: Array< ModelAchievementFilterInput | null > | null,
  not?: ModelAchievementFilterInput | null,
};

export type ModelMemberAchievementFilterInput = {
  id?: ModelIDInput | null,
  memberID?: ModelIDInput | null,
  achievementID?: ModelIDInput | null,
  clubID?: ModelIDInput | null,
  checkinID?: ModelIDInput | null,
  dateAchieved?: ModelStringInput | null,
  hasSwag?: ModelBooleanInput | null,
  swagReceived?: ModelBooleanInput | null,
  swagReceivedDate?: ModelStringInput | null,
  isNew?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelMemberAchievementFilterInput | null > | null,
  or?: Array< ModelMemberAchievementFilterInput | null > | null,
  not?: ModelMemberAchievementFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelMemberAchievementByClubByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
  le?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
  lt?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
  ge?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
  gt?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelMemberAchievementByClubByStatusByDateCompositeKeyInput | null,
};

export type ModelMemberAchievementByClubByStatusByDateCompositeKeyInput = {
  status?: string | null,
  dateAchieved?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelClubFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  clubType?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zipcode?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  scoreUnits?: ModelStringInput | null,
  hashedPinCode?: ModelStringInput | null,
  finishedText?: ModelStringInput | null,
  customMaxScore?: ModelFloatInput | null,
  clubSlug?: ModelStringInput | null,
  showCustomOption?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  managerGroup?: ModelStringInput | null,
  approvalStatus?: ModelClubApprovalStatusInput | null,
  logoUrl?: ModelStringInput | null,
  routesPathsUrl?: ModelStringInput | null,
  isPublic?: ModelBooleanInput | null,
  wantsWeeklyStats?: ModelBooleanInput | null,
  facebookSlug?: ModelStringInput | null,
  instagramSlug?: ModelStringInput | null,
  twitterSlug?: ModelStringInput | null,
  ticTokSlug?: ModelStringInput | null,
  and?: Array< ModelClubFilterInput | null > | null,
  or?: Array< ModelClubFilterInput | null > | null,
  not?: ModelClubFilterInput | null,
};

export type ModelClubConnection = {
  __typename: "ModelClubConnection",
  items:  Array<Club | null >,
  nextToken?: string | null,
};

export type SearchableClubFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  clubType?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  state?: SearchableStringFilterInput | null,
  zipcode?: SearchableStringFilterInput | null,
  latitude?: SearchableFloatFilterInput | null,
  longitude?: SearchableFloatFilterInput | null,
  scoreUnits?: SearchableStringFilterInput | null,
  hashedPinCode?: SearchableStringFilterInput | null,
  finishedText?: SearchableStringFilterInput | null,
  customMaxScore?: SearchableFloatFilterInput | null,
  clubSlug?: SearchableStringFilterInput | null,
  showCustomOption?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  managerGroup?: SearchableStringFilterInput | null,
  logoUrl?: SearchableStringFilterInput | null,
  routesPathsUrl?: SearchableStringFilterInput | null,
  isPublic?: SearchableBooleanFilterInput | null,
  wantsWeeklyStats?: SearchableBooleanFilterInput | null,
  facebookSlug?: SearchableStringFilterInput | null,
  instagramSlug?: SearchableStringFilterInput | null,
  twitterSlug?: SearchableStringFilterInput | null,
  ticTokSlug?: SearchableStringFilterInput | null,
  and?: Array< SearchableClubFilterInput | null > | null,
  or?: Array< SearchableClubFilterInput | null > | null,
  not?: SearchableClubFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableClubSortInput = {
  field?: SearchableClubSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableClubSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  address = "address",
  clubType = "clubType",
  city = "city",
  state = "state",
  zipcode = "zipcode",
  latitude = "latitude",
  longitude = "longitude",
  scoreUnits = "scoreUnits",
  hashedPinCode = "hashedPinCode",
  finishedText = "finishedText",
  customMaxScore = "customMaxScore",
  clubSlug = "clubSlug",
  showCustomOption = "showCustomOption",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  deletedAt = "deletedAt",
  managerGroup = "managerGroup",
  logoUrl = "logoUrl",
  routesPathsUrl = "routesPathsUrl",
  isPublic = "isPublic",
  wantsWeeklyStats = "wantsWeeklyStats",
  facebookSlug = "facebookSlug",
  instagramSlug = "instagramSlug",
  twitterSlug = "twitterSlug",
  ticTokSlug = "ticTokSlug",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableClubConnection = {
  __typename: "SearchableClubConnection",
  items:  Array<Club | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelClubManagerFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  clubId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelClubManagerFilterInput | null > | null,
  or?: Array< ModelClubManagerFilterInput | null > | null,
  not?: ModelClubManagerFilterInput | null,
};

export type ModelClubManagerConnection = {
  __typename: "ModelClubManagerConnection",
  items:  Array<ClubManager | null >,
  nextToken?: string | null,
};

export type ModelClubManagerRequestFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  clubId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  message?: ModelStringInput | null,
  status?: ModelClubManagerRequestStatusInput | null,
  and?: Array< ModelClubManagerRequestFilterInput | null > | null,
  or?: Array< ModelClubManagerRequestFilterInput | null > | null,
  not?: ModelClubManagerRequestFilterInput | null,
};

export type ModelClubManagerRequestConnection = {
  __typename: "ModelClubManagerRequestConnection",
  items:  Array<ClubManagerRequest | null >,
  nextToken?: string | null,
};

export type ModelCheckinOptionFilterInput = {
  id?: ModelIDInput | null,
  managerGroup?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  label?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  order?: ModelFloatInput | null,
  clubID?: ModelIDInput | null,
  status?: ModelCheckinOptionStatusInput | null,
  and?: Array< ModelCheckinOptionFilterInput | null > | null,
  or?: Array< ModelCheckinOptionFilterInput | null > | null,
  not?: ModelCheckinOptionFilterInput | null,
};

export type ModelMemberFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  genderIdentity?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  totalScore?: ModelFloatInput | null,
  clubID?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  managers?: ModelStringInput | null,
  managerGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelMemberFilterInput | null > | null,
  or?: Array< ModelMemberFilterInput | null > | null,
  not?: ModelMemberFilterInput | null,
};

export type SearchableMemberFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  genderIdentity?: SearchableStringFilterInput | null,
  birthday?: SearchableStringFilterInput | null,
  totalScore?: SearchableFloatFilterInput | null,
  clubID?: SearchableIDFilterInput | null,
  userId?: SearchableStringFilterInput | null,
  managers?: SearchableStringFilterInput | null,
  managerGroup?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableMemberFilterInput | null > | null,
  or?: Array< SearchableMemberFilterInput | null > | null,
  not?: SearchableMemberFilterInput | null,
};

export type SearchableMemberSortInput = {
  field?: SearchableMemberSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMemberSortableFields {
  id = "id",
  owner = "owner",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  genderIdentity = "genderIdentity",
  birthday = "birthday",
  totalScore = "totalScore",
  clubID = "clubID",
  userId = "userId",
  managers = "managers",
  managerGroup = "managerGroup",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  deletedAt = "deletedAt",
}


export type SearchableMemberConnection = {
  __typename: "SearchableMemberConnection",
  items:  Array<Member | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCheckinFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  label?: ModelStringInput | null,
  memberID?: ModelIDInput | null,
  clubID?: ModelIDInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelCheckinFilterInput | null > | null,
  or?: Array< ModelCheckinFilterInput | null > | null,
  not?: ModelCheckinFilterInput | null,
};

export type FindableClubConnection = {
  __typename: "FindableClubConnection",
  items?:  Array<Club | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type CreateBackendCheckinMutationVariables = {
  memberID: string,
  clubID: string,
  score: number,
  weather?: string | null,
  label: string,
  hash: string,
};

export type CreateBackendCheckinMutation = {
  createBackendCheckin:  {
    __typename: "CreateCheckinResponse",
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    memberAchievements?:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    message?: string | null,
  },
};

export type DeleteBackendCheckinMutationVariables = {
  checkinID: string,
};

export type DeleteBackendCheckinMutation = {
  deleteBackendCheckin:  {
    __typename: "DeleteCheckinResponse",
    status?: string | null,
    message?: string | null,
  },
};

export type CreateClubSecretMutationVariables = {
  input: CreateClubSecretInput,
  condition?: ModelClubSecretConditionInput | null,
};

export type CreateClubSecretMutation = {
  createClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClubSecretMutationVariables = {
  input: UpdateClubSecretInput,
  condition?: ModelClubSecretConditionInput | null,
};

export type UpdateClubSecretMutation = {
  updateClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClubSecretMutationVariables = {
  input: DeleteClubSecretInput,
  condition?: ModelClubSecretConditionInput | null,
};

export type DeleteClubSecretMutation = {
  deleteClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMemberSecretMutationVariables = {
  input: CreateMemberSecretInput,
  condition?: ModelMemberSecretConditionInput | null,
};

export type CreateMemberSecretMutation = {
  createMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMemberSecretMutationVariables = {
  input: UpdateMemberSecretInput,
  condition?: ModelMemberSecretConditionInput | null,
};

export type UpdateMemberSecretMutation = {
  updateMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMemberSecretMutationVariables = {
  input: DeleteMemberSecretInput,
  condition?: ModelMemberSecretConditionInput | null,
};

export type DeleteMemberSecretMutation = {
  deleteMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateDoNotEmailMutationVariables = {
  input: CreateDoNotEmailInput,
  condition?: ModelDoNotEmailConditionInput | null,
};

export type CreateDoNotEmailMutation = {
  createDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDoNotEmailMutationVariables = {
  input: UpdateDoNotEmailInput,
  condition?: ModelDoNotEmailConditionInput | null,
};

export type UpdateDoNotEmailMutation = {
  updateDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDoNotEmailMutationVariables = {
  input: DeleteDoNotEmailInput,
  condition?: ModelDoNotEmailConditionInput | null,
};

export type DeleteDoNotEmailMutation = {
  deleteDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAchievementMutationVariables = {
  input: CreateAchievementInput,
  condition?: ModelAchievementConditionInput | null,
};

export type CreateAchievementMutation = {
  createAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAchievementMutationVariables = {
  input: UpdateAchievementInput,
  condition?: ModelAchievementConditionInput | null,
};

export type UpdateAchievementMutation = {
  updateAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAchievementMutationVariables = {
  input: DeleteAchievementInput,
  condition?: ModelAchievementConditionInput | null,
};

export type DeleteAchievementMutation = {
  deleteAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMemberAchievementMutationVariables = {
  input: CreateMemberAchievementInput,
  condition?: ModelMemberAchievementConditionInput | null,
};

export type CreateMemberAchievementMutation = {
  createMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateMemberAchievementMutationVariables = {
  input: UpdateMemberAchievementInput,
  condition?: ModelMemberAchievementConditionInput | null,
};

export type UpdateMemberAchievementMutation = {
  updateMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteMemberAchievementMutationVariables = {
  input: DeleteMemberAchievementInput,
  condition?: ModelMemberAchievementConditionInput | null,
};

export type DeleteMemberAchievementMutation = {
  deleteMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateClubMutationVariables = {
  input: CreateClubInput,
  condition?: ModelClubConditionInput | null,
};

export type CreateClubMutation = {
  createClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClubMutationVariables = {
  input: UpdateClubInput,
  condition?: ModelClubConditionInput | null,
};

export type UpdateClubMutation = {
  updateClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteClubMutationVariables = {
  input: DeleteClubInput,
  condition?: ModelClubConditionInput | null,
};

export type DeleteClubMutation = {
  deleteClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateClubManagerMutationVariables = {
  input: CreateClubManagerInput,
  condition?: ModelClubManagerConditionInput | null,
};

export type CreateClubManagerMutation = {
  createClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateClubManagerMutationVariables = {
  input: UpdateClubManagerInput,
  condition?: ModelClubManagerConditionInput | null,
};

export type UpdateClubManagerMutation = {
  updateClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteClubManagerMutationVariables = {
  input: DeleteClubManagerInput,
  condition?: ModelClubManagerConditionInput | null,
};

export type DeleteClubManagerMutation = {
  deleteClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateClubManagerRequestMutationVariables = {
  input: CreateClubManagerRequestInput,
  condition?: ModelClubManagerRequestConditionInput | null,
};

export type CreateClubManagerRequestMutation = {
  createClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateClubManagerRequestMutationVariables = {
  input: UpdateClubManagerRequestInput,
  condition?: ModelClubManagerRequestConditionInput | null,
};

export type UpdateClubManagerRequestMutation = {
  updateClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteClubManagerRequestMutationVariables = {
  input: DeleteClubManagerRequestInput,
  condition?: ModelClubManagerRequestConditionInput | null,
};

export type DeleteClubManagerRequestMutation = {
  deleteClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCheckinOptionMutationVariables = {
  input: CreateCheckinOptionInput,
  condition?: ModelCheckinOptionConditionInput | null,
};

export type CreateCheckinOptionMutation = {
  createCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCheckinOptionMutationVariables = {
  input: UpdateCheckinOptionInput,
  condition?: ModelCheckinOptionConditionInput | null,
};

export type UpdateCheckinOptionMutation = {
  updateCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCheckinOptionMutationVariables = {
  input: DeleteCheckinOptionInput,
  condition?: ModelCheckinOptionConditionInput | null,
};

export type DeleteCheckinOptionMutation = {
  deleteCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMemberMutationVariables = {
  input: CreateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type CreateMemberMutation = {
  createMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateMemberMutationVariables = {
  input: UpdateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type UpdateMemberMutation = {
  updateMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteMemberMutationVariables = {
  input: DeleteMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type DeleteMemberMutation = {
  deleteMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCheckinMutationVariables = {
  input: CreateCheckinInput,
  condition?: ModelCheckinConditionInput | null,
};

export type CreateCheckinMutation = {
  createCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCheckinMutationVariables = {
  input: UpdateCheckinInput,
  condition?: ModelCheckinConditionInput | null,
};

export type UpdateCheckinMutation = {
  updateCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCheckinMutationVariables = {
  input: DeleteCheckinInput,
  condition?: ModelCheckinConditionInput | null,
};

export type DeleteCheckinMutation = {
  deleteCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetCheckinHashQueryVariables = {
  clubID: string,
};

export type GetCheckinHashQuery = {
  getCheckinHash?:  {
    __typename: "GetCheckinHashResponse",
    status?: string | null,
    hash?: string | null,
    message?: string | null,
  } | null,
};

export type SearchMembersNewQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
};

export type SearchMembersNewQuery = {
  searchMembersNew?:  {
    __typename: "FindableMemberConnection",
    items?:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetClubSecretQueryVariables = {
  id: string,
};

export type GetClubSecretQuery = {
  getClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClubSecretsQueryVariables = {
  filter?: ModelClubSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClubSecretsQuery = {
  listClubSecrets?:  {
    __typename: "ModelClubSecretConnection",
    items:  Array< {
      __typename: "ClubSecret",
      id: string,
      clubID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubSecretByClubIDQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubSecretByClubIDQuery = {
  clubSecretByClubID?:  {
    __typename: "ModelClubSecretConnection",
    items:  Array< {
      __typename: "ClubSecret",
      id: string,
      clubID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubSecretBySecretQueryVariables = {
  secret?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubSecretBySecretQuery = {
  clubSecretBySecret?:  {
    __typename: "ModelClubSecretConnection",
    items:  Array< {
      __typename: "ClubSecret",
      id: string,
      clubID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberSecretQueryVariables = {
  id: string,
};

export type GetMemberSecretQuery = {
  getMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMemberSecretsQueryVariables = {
  filter?: ModelMemberSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberSecretsQuery = {
  listMemberSecrets?:  {
    __typename: "ModelMemberSecretConnection",
    items:  Array< {
      __typename: "MemberSecret",
      id: string,
      memberID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberSecretByMemberIDQueryVariables = {
  memberID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberSecretByMemberIDQuery = {
  memberSecretByMemberID?:  {
    __typename: "ModelMemberSecretConnection",
    items:  Array< {
      __typename: "MemberSecret",
      id: string,
      memberID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberSecretBySecretQueryVariables = {
  secret?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberSecretBySecretQuery = {
  memberSecretBySecret?:  {
    __typename: "ModelMemberSecretConnection",
    items:  Array< {
      __typename: "MemberSecret",
      id: string,
      memberID: string,
      secret: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByAuthUserIdQueryVariables = {
  authUserId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByAuthUserIdQuery = {
  userByAuthUserId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDoNotEmailQueryVariables = {
  id: string,
};

export type GetDoNotEmailQuery = {
  getDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDoNotEmailsQueryVariables = {
  filter?: ModelDoNotEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoNotEmailsQuery = {
  listDoNotEmails?:  {
    __typename: "ModelDoNotEmailConnection",
    items:  Array< {
      __typename: "DoNotEmail",
      id: string,
      email: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DoNotEmailByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoNotEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DoNotEmailByEmailQuery = {
  doNotEmailByEmail?:  {
    __typename: "ModelDoNotEmailConnection",
    items:  Array< {
      __typename: "DoNotEmail",
      id: string,
      email: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAchievementQueryVariables = {
  id: string,
};

export type GetAchievementQuery = {
  getAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAchievementsQueryVariables = {
  filter?: ModelAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAchievementsQuery = {
  listAchievements?:  {
    __typename: "ModelAchievementConnection",
    items:  Array< {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AchievmentsByClubQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AchievmentsByClubQuery = {
  achievmentsByClub?:  {
    __typename: "ModelAchievementConnection",
    items:  Array< {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberAchievementQueryVariables = {
  id: string,
};

export type GetMemberAchievementQuery = {
  getMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListMemberAchievementsQueryVariables = {
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberAchievementsQuery = {
  listMemberAchievements?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByMemberQueryVariables = {
  memberID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByMemberQuery = {
  memberAchievementsByMember?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByAchievementQueryVariables = {
  achievementID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByAchievementQuery = {
  memberAchievementsByAchievement?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByClubQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByClubQuery = {
  memberAchievementsByClub?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByCheckinQueryVariables = {
  checkinID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByCheckinQuery = {
  memberAchievementsByCheckin?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByClubByStatusQueryVariables = {
  clubID?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByClubByStatusQuery = {
  memberAchievementsByClubByStatus?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberAchievementsByClubByStatusByDateQueryVariables = {
  clubID?: string | null,
  statusDateAchieved?: ModelMemberAchievementByClubByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberAchievementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberAchievementsByClubByStatusByDateQuery = {
  memberAchievementsByClubByStatusByDate?:  {
    __typename: "ModelMemberAchievementConnection",
    items:  Array< {
      __typename: "MemberAchievement",
      id: string,
      memberID: string,
      achievementID: string,
      clubID: string,
      checkinID: string,
      dateAchieved: string,
      hasSwag: boolean,
      swagReceived: boolean,
      swagReceivedDate?: string | null,
      isNew?: boolean | null,
      status?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
      checkin?:  {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubByOwnerQueryVariables = {
  owner?: string | null,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubByOwnerQuery = {
  clubByOwner?:  {
    __typename: "ModelClubConnection",
    items:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubsByOwnerAndDeletedAtQueryVariables = {
  owner?: string | null,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubsByOwnerAndDeletedAtQuery = {
  clubsByOwnerAndDeletedAt?:  {
    __typename: "ModelClubConnection",
    items:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubsByClubSlugQueryVariables = {
  clubSlug?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubsByClubSlugQuery = {
  clubsByClubSlug?:  {
    __typename: "ModelClubConnection",
    items:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchClubsQueryVariables = {
  filter?: SearchableClubFilterInput | null,
  sort?: SearchableClubSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchClubsQuery = {
  searchClubs?:  {
    __typename: "SearchableClubConnection",
    items:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetClubQueryVariables = {
  id: string,
};

export type GetClubQuery = {
  getClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListClubsQueryVariables = {
  filter?: ModelClubFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClubsQuery = {
  listClubs?:  {
    __typename: "ModelClubConnection",
    items:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClubManagerQueryVariables = {
  id: string,
};

export type GetClubManagerQuery = {
  getClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListClubManagersQueryVariables = {
  filter?: ModelClubManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClubManagersQuery = {
  listClubManagers?:  {
    __typename: "ModelClubManagerConnection",
    items:  Array< {
      __typename: "ClubManager",
      id: string,
      userId: string,
      clubId: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        authUserId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        birthday?: string | null,
        genderIdentity?: string | null,
        isPrivate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubManagersByClubIdQueryVariables = {
  clubId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubManagersByClubIdQuery = {
  clubManagersByClubId?:  {
    __typename: "ModelClubManagerConnection",
    items:  Array< {
      __typename: "ClubManager",
      id: string,
      userId: string,
      clubId: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        authUserId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        birthday?: string | null,
        genderIdentity?: string | null,
        isPrivate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubManagersByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubManagersByUserIdQuery = {
  clubManagersByUserId?:  {
    __typename: "ModelClubManagerConnection",
    items:  Array< {
      __typename: "ClubManager",
      id: string,
      userId: string,
      clubId: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        authUserId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        birthday?: string | null,
        genderIdentity?: string | null,
        isPrivate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClubManagerRequestQueryVariables = {
  id: string,
};

export type GetClubManagerRequestQuery = {
  getClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListClubManagerRequestsQueryVariables = {
  filter?: ModelClubManagerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClubManagerRequestsQuery = {
  listClubManagerRequests?:  {
    __typename: "ModelClubManagerRequestConnection",
    items:  Array< {
      __typename: "ClubManagerRequest",
      id: string,
      userId: string,
      clubId: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      message?: string | null,
      status?: ClubManagerRequestStatus | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        authUserId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        birthday?: string | null,
        genderIdentity?: string | null,
        isPrivate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClubManagerRequestsByClubIdQueryVariables = {
  clubId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClubManagerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClubManagerRequestsByClubIdQuery = {
  clubManagerRequestsByClubId?:  {
    __typename: "ModelClubManagerRequestConnection",
    items:  Array< {
      __typename: "ClubManagerRequest",
      id: string,
      userId: string,
      clubId: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      message?: string | null,
      status?: ClubManagerRequestStatus | null,
      createdAt: string,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        authUserId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        birthday?: string | null,
        genderIdentity?: string | null,
        isPrivate?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCheckinOptionQueryVariables = {
  id: string,
};

export type GetCheckinOptionQuery = {
  getCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCheckinOptionsQueryVariables = {
  filter?: ModelCheckinOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckinOptionsQuery = {
  listCheckinOptions?:  {
    __typename: "ModelCheckinOptionConnection",
    items:  Array< {
      __typename: "CheckinOption",
      id: string,
      managerGroup?: string | null,
      owner?: string | null,
      label: string,
      score: number,
      order: number,
      clubID: string,
      status?: CheckinOptionStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CheckinOptionsByClubIDQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckinOptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckinOptionsByClubIDQuery = {
  checkinOptionsByClubID?:  {
    __typename: "ModelCheckinOptionConnection",
    items:  Array< {
      __typename: "CheckinOption",
      id: string,
      managerGroup?: string | null,
      owner?: string | null,
      label: string,
      score: number,
      order: number,
      clubID: string,
      status?: CheckinOptionStatus | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberQueryVariables = {
  id: string,
};

export type GetMemberQuery = {
  getMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListMembersQueryVariables = {
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersQuery = {
  listMembers?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembersByClubIDQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembersByClubIDQuery = {
  membersByClubID?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembersByClubAndDateQueryVariables = {
  clubID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembersByClubAndDateQuery = {
  membersByClubAndDate?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberByUserIdQuery = {
  memberByUserId?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberByClubAndUserIdQueryVariables = {
  clubID?: string | null,
  userId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberByClubAndUserIdQuery = {
  memberByClubAndUserId?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembersByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembersByEmailQuery = {
  membersByEmail?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembersByClubAndEmailQueryVariables = {
  clubID?: string | null,
  email?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembersByClubAndEmailQuery = {
  membersByClubAndEmail?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMembersQueryVariables = {
  filter?: SearchableMemberFilterInput | null,
  sort?: SearchableMemberSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMembersQuery = {
  searchMembers?:  {
    __typename: "SearchableMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCheckinQueryVariables = {
  id: string,
};

export type GetCheckinQuery = {
  getCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCheckinsQueryVariables = {
  filter?: ModelCheckinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckinsQuery = {
  listCheckins?:  {
    __typename: "ModelCheckinConnection",
    items:  Array< {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CheckinsByMemberIDQueryVariables = {
  memberID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckinsByMemberIDQuery = {
  checkinsByMemberID?:  {
    __typename: "ModelCheckinConnection",
    items:  Array< {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CheckinsByClubIDQueryVariables = {
  clubID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckinsByClubIDQuery = {
  checkinsByClubID?:  {
    __typename: "ModelCheckinConnection",
    items:  Array< {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CheckinsByClubIDAndDateQueryVariables = {
  clubID?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckinFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CheckinsByClubIDAndDateQuery = {
  checkinsByClubIDAndDate?:  {
    __typename: "ModelCheckinConnection",
    items:  Array< {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchClubsNewQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
  aroundLatLng?: string | null,
};

export type SearchClubsNewQuery = {
  searchClubsNew?:  {
    __typename: "FindableClubConnection",
    items?:  Array< {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateClubSecretSubscription = {
  onCreateClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClubSecretSubscription = {
  onUpdateClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClubSecretSubscription = {
  onDeleteClubSecret?:  {
    __typename: "ClubSecret",
    id: string,
    clubID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMemberSecretSubscription = {
  onCreateMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMemberSecretSubscription = {
  onUpdateMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMemberSecretSubscription = {
  onDeleteMemberSecret?:  {
    __typename: "MemberSecret",
    id: string,
    memberID: string,
    secret: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    authUserId?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    birthday?: string | null,
    genderIdentity?: string | null,
    isPrivate?: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDoNotEmailSubscription = {
  onCreateDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDoNotEmailSubscription = {
  onUpdateDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDoNotEmailSubscription = {
  onDeleteDoNotEmail?:  {
    __typename: "DoNotEmail",
    id: string,
    email: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAchievementSubscription = {
  onCreateAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAchievementSubscription = {
  onUpdateAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAchievementSubscription = {
  onDeleteAchievement?:  {
    __typename: "Achievement",
    id: string,
    clubID: string,
    name: string,
    description: string,
    type: AchievementType,
    imageUrl?: string | null,
    value?: number | null,
    start?: string | null,
    end?: string | null,
    swag?: string | null,
    isManualAssign?: boolean | null,
    deletedAt?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMemberAchievementSubscription = {
  onCreateMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateMemberAchievementSubscription = {
  onUpdateMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteMemberAchievementSubscription = {
  onDeleteMemberAchievement?:  {
    __typename: "MemberAchievement",
    id: string,
    memberID: string,
    achievementID: string,
    clubID: string,
    checkinID: string,
    dateAchieved: string,
    hasSwag: boolean,
    swagReceived: boolean,
    swagReceivedDate?: string | null,
    isNew?: boolean | null,
    status?: string | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      clubID: string,
      name: string,
      description: string,
      type: AchievementType,
      imageUrl?: string | null,
      value?: number | null,
      start?: string | null,
      end?: string | null,
      swag?: string | null,
      isManualAssign?: boolean | null,
      deletedAt?: string | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkin?:  {
      __typename: "Checkin",
      id: string,
      owner?: string | null,
      date: string,
      score: number,
      label: string,
      memberID: string,
      clubID: string,
      weather?:  {
        __typename: "CheckinWeather",
        apparentTemperature: number,
        cloudCover: number,
        dewPoint: number,
        humidity: number,
        nearestStormDistance?: number | null,
        nearestStormBearing?: number | null,
        precipIntensity?: number | null,
        precipProbability?: number | null,
        precipType?: string | null,
        sunriseTime?: string | null,
        sunsetTime?: string | null,
        temperature: number,
      } | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
      member?:  {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateClubSubscription = {
  onCreateClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateClubSubscription = {
  onUpdateClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteClubSubscription = {
  onDeleteClub?:  {
    __typename: "Club",
    id: string,
    owner?: string | null,
    name: string,
    address: string,
    clubType?: string | null,
    city: string,
    state: string,
    zipcode?: string | null,
    latitude: number,
    longitude: number,
    scoreUnits: string,
    hashedPinCode?: string | null,
    finishedText?: string | null,
    customMaxScore?: number | null,
    clubSlug?: string | null,
    customMemberFields?:  Array< {
      __typename: "MemberField",
      label?: string | null,
      slug?: string | null,
      type?: MemberFieldType | null,
      selectOptions?: Array< string | null > | null,
    } | null > | null,
    showCustomOption?: boolean | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    managerGroup?: string | null,
    logoUrl?: string | null,
    routesPathsUrl?: string | null,
    isPublic?: boolean | null,
    schedule?:  {
      __typename: "ClubSchedule",
      monday?: number | null,
      tuesday?: number | null,
      wednesday?: number | null,
      thursday?: number | null,
      friday?: number | null,
      saturday?: number | null,
      sunday?: number | null,
      mondayAddress?: string | null,
      tuesdayAddress?: string | null,
      wednesdayAddress?: string | null,
      thursdayAddress?: string | null,
      fridayAddress?: string | null,
      saturdayAddress?: string | null,
      sundayAddress?: string | null,
    } | null,
    wantsWeeklyStats?: boolean | null,
    facebookSlug?: string | null,
    instagramSlug?: string | null,
    twitterSlug?: string | null,
    ticTokSlug?: string | null,
    achievments?:  {
      __typename: "ModelAchievementConnection",
      items:  Array< {
        __typename: "Achievement",
        id: string,
        clubID: string,
        name: string,
        description: string,
        type: AchievementType,
        imageUrl?: string | null,
        value?: number | null,
        start?: string | null,
        end?: string | null,
        swag?: string | null,
        isManualAssign?: boolean | null,
        deletedAt?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    approvalStatus?: ClubApprovalStatus | null,
    checkinOptions?:  {
      __typename: "ModelCheckinOptionConnection",
      items:  Array< {
        __typename: "CheckinOption",
        id: string,
        managerGroup?: string | null,
        owner?: string | null,
        label: string,
        score: number,
        order: number,
        clubID: string,
        status?: CheckinOptionStatus | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        genderIdentity?: string | null,
        birthday?: string | null,
        totalScore: number,
        clubID: string,
        userId: string,
        managers?: Array< string | null > | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateClubManagerSubscription = {
  onCreateClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateClubManagerSubscription = {
  onUpdateClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteClubManagerSubscription = {
  onDeleteClubManager?:  {
    __typename: "ClubManager",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateClubManagerRequestSubscription = {
  onCreateClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateClubManagerRequestSubscription = {
  onUpdateClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteClubManagerRequestSubscription = {
  onDeleteClubManagerRequest?:  {
    __typename: "ClubManagerRequest",
    id: string,
    userId: string,
    clubId: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    message?: string | null,
    status?: ClubManagerRequestStatus | null,
    createdAt: string,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      authUserId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      birthday?: string | null,
      genderIdentity?: string | null,
      isPrivate?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCheckinOptionSubscription = {
  onCreateCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCheckinOptionSubscription = {
  onUpdateCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCheckinOptionSubscription = {
  onDeleteCheckinOption?:  {
    __typename: "CheckinOption",
    id: string,
    managerGroup?: string | null,
    owner?: string | null,
    label: string,
    score: number,
    order: number,
    clubID: string,
    status?: CheckinOptionStatus | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMemberSubscriptionVariables = {
  owner?: string | null,
  userId?: string | null,
  managers?: string | null,
};

export type OnCreateMemberSubscription = {
  onCreateMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateMemberSubscriptionVariables = {
  owner?: string | null,
  userId?: string | null,
  managers?: string | null,
};

export type OnUpdateMemberSubscription = {
  onUpdateMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteMemberSubscriptionVariables = {
  owner?: string | null,
  userId?: string | null,
  managers?: string | null,
};

export type OnDeleteMemberSubscription = {
  onDeleteMember?:  {
    __typename: "Member",
    id: string,
    owner?: string | null,
    firstName: string,
    lastName: string,
    email: string,
    genderIdentity?: string | null,
    birthday?: string | null,
    totalScore: number,
    clubID: string,
    userId: string,
    managers?: Array< string | null > | null,
    managerGroup?: string | null,
    customMemberFieldValues?:  Array< {
      __typename: "MemberFieldValue",
      label?: string | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
    memberAchievements?:  {
      __typename: "ModelMemberAchievementConnection",
      items:  Array< {
        __typename: "MemberAchievement",
        id: string,
        memberID: string,
        achievementID: string,
        clubID: string,
        checkinID: string,
        dateAchieved: string,
        hasSwag: boolean,
        swagReceived: boolean,
        swagReceivedDate?: string | null,
        isNew?: boolean | null,
        status?: string | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    club?:  {
      __typename: "Club",
      id: string,
      owner?: string | null,
      name: string,
      address: string,
      clubType?: string | null,
      city: string,
      state: string,
      zipcode?: string | null,
      latitude: number,
      longitude: number,
      scoreUnits: string,
      hashedPinCode?: string | null,
      finishedText?: string | null,
      customMaxScore?: number | null,
      clubSlug?: string | null,
      customMemberFields?:  Array< {
        __typename: "MemberField",
        label?: string | null,
        slug?: string | null,
        type?: MemberFieldType | null,
        selectOptions?: Array< string | null > | null,
      } | null > | null,
      showCustomOption?: boolean | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      managerGroup?: string | null,
      logoUrl?: string | null,
      routesPathsUrl?: string | null,
      isPublic?: boolean | null,
      schedule?:  {
        __typename: "ClubSchedule",
        monday?: number | null,
        tuesday?: number | null,
        wednesday?: number | null,
        thursday?: number | null,
        friday?: number | null,
        saturday?: number | null,
        sunday?: number | null,
        mondayAddress?: string | null,
        tuesdayAddress?: string | null,
        wednesdayAddress?: string | null,
        thursdayAddress?: string | null,
        fridayAddress?: string | null,
        saturdayAddress?: string | null,
        sundayAddress?: string | null,
      } | null,
      wantsWeeklyStats?: boolean | null,
      facebookSlug?: string | null,
      instagramSlug?: string | null,
      twitterSlug?: string | null,
      ticTokSlug?: string | null,
      achievments?:  {
        __typename: "ModelAchievementConnection",
        nextToken?: string | null,
      } | null,
      approvalStatus?: ClubApprovalStatus | null,
      checkinOptions?:  {
        __typename: "ModelCheckinOptionConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckinConnection",
      items:  Array< {
        __typename: "Checkin",
        id: string,
        owner?: string | null,
        date: string,
        score: number,
        label: string,
        memberID: string,
        clubID: string,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCheckinSubscription = {
  onCreateCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateCheckinSubscription = {
  onUpdateCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteCheckinSubscription = {
  onDeleteCheckin?:  {
    __typename: "Checkin",
    id: string,
    owner?: string | null,
    date: string,
    score: number,
    label: string,
    memberID: string,
    clubID: string,
    weather?:  {
      __typename: "CheckinWeather",
      apparentTemperature: number,
      cloudCover: number,
      dewPoint: number,
      humidity: number,
      nearestStormDistance?: number | null,
      nearestStormBearing?: number | null,
      precipIntensity?: number | null,
      precipProbability?: number | null,
      precipType?: string | null,
      sunriseTime?: string | null,
      sunsetTime?: string | null,
      temperature: number,
    } | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
    member?:  {
      __typename: "Member",
      id: string,
      owner?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      genderIdentity?: string | null,
      birthday?: string | null,
      totalScore: number,
      clubID: string,
      userId: string,
      managers?: Array< string | null > | null,
      managerGroup?: string | null,
      customMemberFieldValues?:  Array< {
        __typename: "MemberFieldValue",
        label?: string | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      deletedAt?: string | null,
      memberAchievements?:  {
        __typename: "ModelMemberAchievementConnection",
        nextToken?: string | null,
      } | null,
      club?:  {
        __typename: "Club",
        id: string,
        owner?: string | null,
        name: string,
        address: string,
        clubType?: string | null,
        city: string,
        state: string,
        zipcode?: string | null,
        latitude: number,
        longitude: number,
        scoreUnits: string,
        hashedPinCode?: string | null,
        finishedText?: string | null,
        customMaxScore?: number | null,
        clubSlug?: string | null,
        showCustomOption?: boolean | null,
        createdAt: string,
        updatedAt: string,
        deletedAt?: string | null,
        managerGroup?: string | null,
        logoUrl?: string | null,
        routesPathsUrl?: string | null,
        isPublic?: boolean | null,
        wantsWeeklyStats?: boolean | null,
        facebookSlug?: string | null,
        instagramSlug?: string | null,
        twitterSlug?: string | null,
        ticTokSlug?: string | null,
        approvalStatus?: ClubApprovalStatus | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckinConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
