import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";

const IS_PROD = process.env.NODE_ENV === "production";

Sentry.init({
  dsn: "https://559ff71f76534a8683fa713932b0ccc3@o4504961234239488.ingest.sentry.io/4504961237188608",
  tracesSampleRate: 0.666,
  release: "prod-1.0.0",
  environment: IS_PROD ? "production" : "development",
  debug: !IS_PROD,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
