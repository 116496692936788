import * as queries from "./queries";
import * as mutations from "./mutations";
import * as customQueries from "./queries";
import { getTimeStamp } from "src/utils/time";
import { v4 as uuidv4 } from "uuid";

import md5 from "md5";

import {
  Achievement,
  Checkin,
  CheckinOption,
  CheckinOptionStatus,
  Club,
  CreateClubInput,
  CreateMemberInput,
  Member,
  MemberAchievement,
  UpdateClubInput,
  UpdateMemberInput,
} from "src/API";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { formatISO } from "date-fns";
import { logError } from "src/utils/analytics";

type AddAchievementToMemberResult = {
  createMemberAchievement: MemberAchievement;
};

type AddAchievementToMemberProps = {
  achievement: Achievement;
  memberID: string;
  checkinID: string;
};

const addAchievementToMember = async ({
  achievement,
  memberID,
  checkinID,
}: AddAchievementToMemberProps): Promise<MemberAchievement | null> => {
  try {
    const now = getTimeStamp();
    const result = (await API.graphql(
      graphqlOperation(mutations.createMemberAchievement, {
        input: {
          id: md5(`${achievement.id}${memberID}`),
          achievementID: achievement.id,
          memberID,
          clubID: achievement.clubID,
          checkinID,
          dateAchieved: now,
          hasSwag: Boolean(achievement.swag),
          swagReceived: false,
          swagReceivedDate: now,
          status: "new",
        },
      }),
    )) as GraphQLResult<AddAchievementToMemberResult>;

    if (result.data?.createMemberAchievement) {
      return result.data?.createMemberAchievement;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error adding achievement to member",
      file: "src/containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error adding achievement to member", e);
    return null;
  }
};

type CreateAchievementProps = {
  name: string;
  description: string;
  type: string;
  clubID: string;
  imageUrl?: string | null;
  value?: number | null;
  start?: string | null;
  end?: string | null;
  swag?: string | null;
  isManualAssign?: boolean | null;
};

type CreateAchievementResult = {
  createAchievement: Achievement;
};

const createAchievement = async ({
  name,
  description,
  type,
  value,
  imageUrl,
  start,
  end,
  swag,
  clubID,
  isManualAssign,
}: CreateAchievementProps): Promise<Achievement | null> => {
  try {
    const input = {
      name,
      description,
      type,
      value,
      imageUrl,
      start: start || null,
      end: end || null,
      swag,
      clubID,
      isManualAssign,
    };
    const result = (await API.graphql(
      graphqlOperation(mutations.createAchievement, {
        input,
      }),
    )) as GraphQLResult<CreateAchievementResult>;
    if (result.errors) {
      console.log(
        "[ERROR] error creating achievement",
        JSON.stringify(result.errors, null, 2),
      );
      return null;
    }

    if (result.data?.createAchievement) {
      return result.data.createAchievement;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "Error creating achievement",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error creating achievement", e);
    return null;
  }
};

type CreateClubResult = {
  createClub: Club;
};

const createClub = async (params: CreateClubInput): Promise<Club | null> => {
  try {
    const id = uuidv4();
    const paramsWithId = {
      ...params,
      id,
      managerGroup: `${id}-managers`,
    };
    const result = (await API.graphql(
      graphqlOperation(mutations.createClub, { input: paramsWithId }),
    )) as GraphQLResult<CreateClubResult>;

    if (result.data?.createClub) {
      return result.data.createClub;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error creating club",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error creating club", e);
    return null;
  }
};

type DeleteCheckinOptionResult = {
  deleteCheckinOption: CheckinOption;
};

const deleteCheckinOption = async (
  id: string,
): Promise<CheckinOption | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.deleteCheckinOption, { input: { id } }),
    )) as GraphQLResult<DeleteCheckinOptionResult>;

    if (result.data?.deleteCheckinOption) {
      return result.data.deleteCheckinOption;
    } else if (result.errors) {
      console.log(
        "[ERROR] error deleting check-in",
        JSON.stringify(result.errors),
      );
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error deleting check-in options",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error deleting check-in options", e);
    return null;
  }
};

type DeleteMemberAchievementResult = {
  deleteMemberAchievement: MemberAchievement;
};

const deleteMemberAchievement = async (
  id: string,
): Promise<MemberAchievement | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.deleteMemberAchievement, { input: { id } }),
    )) as GraphQLResult<DeleteMemberAchievementResult>;

    if (result.data?.deleteMemberAchievement) {
      return result.data.deleteMemberAchievement;
    }
    if (result.errors) {
      console.log(
        "[ERROR] error deleting member achievement",
        JSON.stringify(result.errors),
      );
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error deleting member achievement",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error deleting member achievement", e);
    return null;
  }
};

type DownloadMembersResult = {
  membersByClubID: { items: Array<Member>; nextToken?: string | null };
};

const downloadMembers = async (
  clubId: string,
): Promise<Array<Member> | null> => {
  try {
    let token;
    let allMembers: Array<Member> = [];
    do {
      const results = (await API.graphql(
        graphqlOperation(queries.membersByClubID, {
          clubID: clubId,
          nextToken: token,
          filter: {
            deletedAt: { attributeExists: false },
          },
        }),
      )) as GraphQLResult<DownloadMembersResult>;

      if (results.data?.membersByClubID && results.data.membersByClubID.items) {
        allMembers = allMembers.concat(results.data.membersByClubID.items);
      }
      if (results.data?.membersByClubID.nextToken) {
        token = results.data?.membersByClubID.nextToken;
      } else {
        return allMembers;
      }
    } while (token);

    return allMembers;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error downloading members",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting club", e);
    return null;
  }
};

type GetAchievementById = {
  getAchievement: Achievement;
};

export const getAchievementById = async (
  achievementId: string,
): Promise<Achievement | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.getAchievement, {
        id: achievementId,
      }),
    )) as GraphQLResult<GetAchievementById>;

    if (result.data?.getAchievement) {
      return result.data.getAchievement;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting achievement by id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting achievement", e);
    return null;
  }
};

type GetAchievementsByClubResult = {
  achievmentsByClub: { items: Array<Achievement> };
};

const getAchievementsByClub = async (
  clubId: string,
): Promise<Array<Achievement> | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.achievmentsByClub, {
        clubID: clubId,
      }),
    )) as GraphQLResult<GetAchievementsByClubResult>;

    if (result.data?.achievmentsByClub.items) {
      return result.data?.achievmentsByClub.items;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting achievements by club",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting achievements by club id", e);
    return null;
  }
};

type GetCheckinsByClubAndRangeResult = {
  checkinsByClubIDAndDate: { items: Array<Checkin>; nextToken?: string | null };
};

const getCheckinsByClubIDinRange = async (
  id: string,
  start: string,
  end: string,
): Promise<Array<Checkin> | null> => {
  try {
    let token;

    let results: Array<Checkin> = [];
    do {
      const result = (await API.graphql(
        graphqlOperation(customQueries.checkinsByClubIdAndDate, {
          clubID: id,
          date: { between: [start, end] },
          nextToken: token,
        }),
      )) as GraphQLResult<GetCheckinsByClubAndRangeResult>;

      if (result.data?.checkinsByClubIDAndDate.items) {
        results = results.concat(result.data?.checkinsByClubIDAndDate.items);
      }
      if (result.data?.checkinsByClubIDAndDate.nextToken) {
        token = result.data?.checkinsByClubIDAndDate.nextToken;
      } else {
        return results;
      }
    } while (token);
    return results;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting checkins for club in range",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting checkins for club in range", e);
    return null;
  }
};

type GetCheckinsByMemberIdResult = {
  checkinsByMemberID: { items: Array<Checkin> };
};

const getCheckinsByMemberId = async (
  memberID: string,
): Promise<Array<Checkin> | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.checkinsByMemberID, {
        memberID,
      }),
    )) as GraphQLResult<GetCheckinsByMemberIdResult>;

    if (result.data?.checkinsByMemberID.items) {
      return result.data?.checkinsByMemberID.items;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting checkins by member id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting checkins by member id", e);
    return null;
  }
};

type GetClubResult = {
  getClub: Club;
};

const getClub = async (clubId: string): Promise<Club | null> => {
  try {
    const results = (await API.graphql(
      graphqlOperation(queries.getClub, { id: clubId }),
    )) as GraphQLResult<GetClubResult>;

    if (results.data?.getClub) {
      return results.data.getClub;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting club by id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting club", e);
    return null;
  }
};

type GetMemberResult = {
  getMember: Member;
};

const getMember = async (memberId: string): Promise<Member | null> => {
  try {
    const results = (await API.graphql(
      graphqlOperation(queries.getMember, { id: memberId }),
    )) as GraphQLResult<GetMemberResult>;

    if (results.data?.getMember) {
      return results.data.getMember;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting member by id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting member", e);
    return null;
  }
};

type GetMemberAchievementsByClubAndStatusResult = {
  memberAchievementsByClubByStatus: {
    items: Array<MemberAchievement>;
    nextToken: string | null;
  };
};

type GetMemberAchievementsByClubProps = {
  clubID: string;
  nextToken?: string | null;
  status?: string;
};

type GetMemberAchievementsByClubResponse = {
  memberAchievements: Array<MemberAchievement>;
  nextToken: string | null;
} | null;

const getMemberAchievementsByClubAndStatus = async ({
  clubID,
  nextToken,
  status,
}: GetMemberAchievementsByClubProps): Promise<GetMemberAchievementsByClubResponse> => {
  try {
    const limit = 20;
    let resp: Array<MemberAchievement> = [];
    let token = nextToken;
    do {
      const result = (await API.graphql(
        graphqlOperation(queries.memberAchievementsByClubByStatus, {
          clubID,
          status: { eq: status },
          nextToken: token,
          filter: {
            hasSwag: { eq: true },
          },
          limit,
        }),
      )) as GraphQLResult<GetMemberAchievementsByClubAndStatusResult>;

      if (result.data?.memberAchievementsByClubByStatus.items) {
        resp = resp.concat(result.data?.memberAchievementsByClubByStatus.items);
        token = result.data.memberAchievementsByClubByStatus.nextToken;
      }
    } while (resp.length < limit && token);

    return {
      memberAchievements: resp,
      nextToken: token || null,
    };
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting member achievements by club and status",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting member achievements by club", e);
    return null;
  }
};

type GetMembersByClub = {
  membersByClubID: { items: Array<Member>; nextToken: string };
};

const getMembersByClub = async (
  clubID: string,
  nextToken?: string | null,
): Promise<[Array<Member>, string | null] | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.membersByClubID, {
        clubID,
        limit: 100,
        nextToken,
      }),
    )) as GraphQLResult<GetMembersByClub>;

    if (result.data?.membersByClubID.items) {
      const { items, nextToken } = result.data.membersByClubID;
      return [items, nextToken];
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting members by club id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting members by club", e);
    return null;
  }
};

type GetOptionsByClubIdResult = {
  checkinOptionsByClubID: { items: Array<CheckinOption> };
};

const getOptionsByClubId = async (
  clubId: string,
): Promise<Array<CheckinOption> | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.checkinOptionsByClubID, { clubID: clubId }),
    )) as GraphQLResult<GetOptionsByClubIdResult>;

    if (result.data?.checkinOptionsByClubID.items) {
      return result.data.checkinOptionsByClubID.items;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting options by club id",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting options by club id", e);
    return null;
  }
};

type ListClubByOwnerResult = {
  clubByOwner: { items: Array<Club> };
};

export const listClubs = async (
  ownerId: string,
): Promise<Array<Club> | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.clubByOwner, {
        owner: ownerId,
      }),
    )) as GraphQLResult<ListClubByOwnerResult>;

    if (result.data?.clubByOwner.items) {
      const filteredClubs = result.data?.clubByOwner.items.filter(
        (club) => club.deletedAt === null,
      );
      return filteredClubs;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error listing clubs",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error listing clubs", e);
    return null;
  }
};

type SaveCheckinOptionProps = {
  clubID: string;
  id?: string;
  label: string;
  score: number;
  order: number;
  status?: CheckinOptionStatus;
};

type NewCheckinOptionResult = {
  createCheckinOption: CheckinOption;
};
type UpdateCheckinOptionResult = {
  updateCheckinOption: CheckinOption;
};

const saveCheckinOption = async (
  newOption: SaveCheckinOptionProps,
): Promise<CheckinOption | null> => {
  try {
    if (newOption.id) {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateCheckinOption, {
          input: {
            id: newOption.id,
            label: newOption.label,
            score: newOption.score,
            status: newOption.status,
          },
        }),
      )) as GraphQLResult<UpdateCheckinOptionResult>;
      if (result.data?.updateCheckinOption) {
        return result.data.updateCheckinOption;
      }
    } else {
      const result = (await API.graphql(
        graphqlOperation(mutations.createCheckinOption, {
          input: {
            clubID: newOption.clubID,
            label: newOption.label,
            score: newOption.score,
            order: newOption.order,
            status: newOption.status,
          },
        }),
      )) as GraphQLResult<NewCheckinOptionResult>;

      if (result.data?.createCheckinOption) {
        return result.data.createCheckinOption;
      }
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error saving check-in option",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error saving check-in option", e);
    return null;
  }
};

type SaveMemberProps = {
  memberId: string;
  firstName: string;
  lastName: string;
  totalScore: number;
  email: string;
  birthday?: string;
  genderIdentity?: string;
};

type SaveMemberResults = {
  updateMember: Member;
};

const saveMember = async ({
  memberId,
  firstName,
  lastName,
  totalScore,
  birthday,
  email,
  genderIdentity,
}: SaveMemberProps): Promise<Member | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateMember, {
        input: {
          id: memberId,
          firstName,
          lastName,
          totalScore,
          birthday,
          email,
          genderIdentity,
        },
      }),
    )) as GraphQLResult<SaveMemberResults>;
    if (result.data?.updateMember) {
      return result.data.updateMember;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error saving memeber",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error saving member", e);
    return null;
  }
};

type SearchMembersByClubResult = {
  searchMembers: { items: Array<Member>; nextToken: string | null };
};

type SearchMembersResp = {
  members: Array<Member>;
  nextToken: string | null;
};

export const searchMembers = async ({
  clubId,
  query,
  showAll,
}: {
  clubId: string;
  query: string;
  showAll?: boolean;
}): Promise<SearchMembersResp | null> => {
  try {
    const lowerQuery = query.toLowerCase();

    const params = {
      filter: {
        and: {
          clubID: { eq: clubId },
          or: [
            { firstName: { regexp: `.*${lowerQuery}.*` } },
            { lastName: { regexp: `.*${lowerQuery}.*` } },
            { email: { regexp: `.*${lowerQuery}.*` } },
          ],
        },
      },
    };

    const result = (await API.graphql(
      graphqlOperation(queries.searchMembers, params),
    )) as GraphQLResult<SearchMembersByClubResult>;
    console.log("result", result);
    if (result.data?.searchMembers.items) {
      const filtered = result.data?.searchMembers.items.filter(
        (m) => showAll || !m.deletedAt,
      );
      return {
        members: filtered,
        nextToken: result.data.searchMembers.nextToken,
      };
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error searching for members",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error searching for members", e);
    return null;
  }
};

export const getMembersWithAchievement = async (
  achievementId: string,
): Promise<MemberAchievement[]> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.memberAchievementsByAchievement, {
        achievementID: achievementId,
        limit: 200,
      }),
    )) as GraphQLResult<{
      memberAchievementsByAchievement: { items: MemberAchievement[] };
    }>;
    if (result.data?.memberAchievementsByAchievement.items) {
      return result.data.memberAchievementsByAchievement.items;
    }
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error getting members with achievement",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error getting members with achievement", e);
  }
  return [];
};

type MembersByEmail = {
  membersByClubAndEmail: { items: Array<Member> };
};

export const searchMembersByEmail = async (
  clubId: string,
  email: string,
): Promise<Member[]> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.membersByClubAndEmail, {
        clubID: clubId,
        email: { beginsWith: email },
        filter: {
          deletedAt: { attributeExists: false },
        },
      }),
    )) as GraphQLResult<MembersByEmail>;

    if (result.data?.membersByClubAndEmail.items) {
      return result.data.membersByClubAndEmail.items;
    }
    return [];
  } catch (e) {
    console.log("[ERROR] error searching for members by email", e);
    return [];
  }
};

type SetClubScheduleResult = {
  updateClub: Club;
};

type SetClubScheduleProps = {
  clubId: string;
  day: string;
  time: number | null;
  address: string | null;
};

const setClubSchedule = async ({
  clubId,
  day,
  time,
  address,
}: SetClubScheduleProps): Promise<Club | null> => {
  try {
    const clubResult = (await API.graphql(
      graphqlOperation(queries.getClub, { id: clubId }),
    )) as GraphQLResult<GetClubResult>;

    if (clubResult.data?.getClub) {
      const schedule = clubResult.data.getClub.schedule;

      const newSchedule = {
        ...schedule,
        [day]: time,
        [`${day}Address`]: address,
      };

      const result = (await API.graphql(
        graphqlOperation(mutations.updateClub, {
          input: {
            id: clubId,
            schedule: newSchedule,
          },
        }),
      )) as GraphQLResult<SetClubScheduleResult>;

      if (result.data?.updateClub) {
        return result.data.updateClub;
      }
      if (result.errors) {
        console.log(
          "[ERROR] error setting the schedule",
          JSON.stringify(result.errors),
        );
      }
      return null;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error setting the club schedule",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error setting the club schedule", e);
    return null;
  }
};

type SetMemberAchievementIsNewResult = {
  updateMemberAchievement: MemberAchievement;
};

type GetMemberAchievementResult = {
  getMemberAchievement: MemberAchievement;
};

const setMemberAchievementIsNew = async (
  memberAchievementID: string,
  isNew: boolean,
): Promise<MemberAchievement | null> => {
  try {
    const achievementResult = (await API.graphql(
      graphqlOperation(queries.getMemberAchievement, {
        id: memberAchievementID,
      }),
    )) as GraphQLResult<GetMemberAchievementResult>;

    if (achievementResult.data?.getMemberAchievement) {
      const memberAchievement = achievementResult.data.getMemberAchievement;
      const result = (await API.graphql(
        graphqlOperation(mutations.updateMemberAchievement, {
          input: {
            id: memberAchievementID,
            isNew,
            status: isNew ? "new" : "completed",
            dateAchieved: memberAchievement.dateAchieved,
          },
        }),
      )) as GraphQLResult<SetMemberAchievementIsNewResult>;
      if (result.data?.updateMemberAchievement) {
        return result.data?.updateMemberAchievement;
      }
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error setting setMemberAchievementIsNew",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error setting setMemberAchievementSeen", e);
    return null;
  }
};

type SetMemberAchievementHasReceivedSwagResult = {
  updateMemberAchievement: MemberAchievement;
};

const setMemberAchievementHasReceivedSwag = async (
  memberAchievementID: string,
  swagReceived: boolean,
): Promise<MemberAchievement | null> => {
  try {
    const now = getTimeStamp();
    const achievementResult = (await API.graphql(
      graphqlOperation(queries.getMemberAchievement, {
        id: memberAchievementID,
      }),
    )) as GraphQLResult<GetMemberAchievementResult>;

    if (achievementResult.data?.getMemberAchievement) {
      const memberAchievement = achievementResult.data.getMemberAchievement;
      const result = (await API.graphql(
        graphqlOperation(mutations.updateMemberAchievement, {
          input: {
            id: memberAchievementID,
            swagReceived,
            swagReceivedDate: now,
            isNew: false,
            status: "completed",
            dateAchieved: memberAchievement.dateAchieved,
          },
        }),
      )) as GraphQLResult<SetMemberAchievementHasReceivedSwagResult>;
      if (result.data?.updateMemberAchievement) {
        return result.data?.updateMemberAchievement;
      }
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error setting setMemberAchievementHasReceivedSwag",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error setting setMemberAchievementSeen", e);
    return null;
  }
};

type UpdateAchievementProps = {
  id: string;
  name: string;
  description: string;
  type: string;
  clubID: string;
  imageUrl?: string | null;
  value?: number | null;
  start?: string | null;
  end?: string | null;
  swag?: string | null;
  isManualAssign?: boolean | null;
};
type UpdateAchievementResult = {
  updateAchievement: Achievement;
};

const updateAchievement = async ({
  id,
  name,
  description,
  imageUrl,
  swag,
  type,
  value,
  start,
  end,
  isManualAssign,
}: UpdateAchievementProps): Promise<Achievement | null> => {
  try {
    const input = {
      id,
      name,
      description,
      swag,
      type,
      imageUrl,
      value,
      start,
      end,
      isManualAssign,
    };
    const result = (await API.graphql(
      graphqlOperation(mutations.updateAchievement, { input }),
    )) as GraphQLResult<UpdateAchievementResult>;
    if (result.data?.updateAchievement) {
      return result.data?.updateAchievement;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error updating achievement",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error updating achievement", e);
    return null;
  }
};

type UpdateClubResult = {
  updateClub: Club;
};

const updateClub = async (params: UpdateClubInput): Promise<Club | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateClub, { input: params }),
    )) as GraphQLResult<UpdateClubResult>;

    if (result.data?.updateClub) {
      return result.data.updateClub;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error updating club",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error updating club", e);
    return null;
  }
};

type ClubNotifications = {
  wantsWeeklyStats: boolean;
};

const saveClubNotifications = async (
  clubID: string,
  params: ClubNotifications,
): Promise<Club | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateClub, {
        input: {
          id: clubID,
          ...params,
        },
      }),
    )) as GraphQLResult<UpdateClubResult>;

    if (result.errors) {
      console.log(
        "[ERROR] error saving club notifications",
        JSON.stringify(result.errors, null, 2),
      );
    }
    if (result.data?.updateClub) {
      return result.data.updateClub;
    }
    return null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error saving club notifications",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error saving club notifications", e);
    return null;
  }
};

const updateCheckinOptions = async (
  options: Array<CheckinOption>,
): Promise<Array<CheckinOption> | null> => {
  try {
    const result = await Promise.all(
      options.map(async (option, idx) => {
        return await moveCheckinOption({ option, order: idx });
      }),
    );

    return result.filter((r) => r !== null) as Array<CheckinOption>;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error updating check-in options",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error updating check-in options", e);
    return null;
  }
};

type MoveCheckinOptionParams = {
  option: CheckinOption;
  order: number;
};

type MoveCheckinOptionResult = {
  updateCheckinOption: CheckinOption;
};

const moveCheckinOption = async ({
  option,
  order,
}: MoveCheckinOptionParams): Promise<CheckinOption | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateCheckinOption, {
        input: {
          id: option.id,
          order,
        },
      }),
    )) as GraphQLResult<MoveCheckinOptionResult>;

    return result.data?.updateCheckinOption || null;
  } catch (_e) {
    const e = _e as Error;
    logError({
      error: e,
      message: "error moving check-in option",
      file: "containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error moving check-in option", e);
    return null;
  }
};

export const deleteMember = async (memberId: string): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateMember, {
      input: { id: memberId, deletedAt: formatISO(new Date()) },
    }),
  )) as GraphQLResult<{ updateMember: Member }>;

  if (result.errors) {
    console.log(
      "[ERROR] error deleting member",
      JSON.stringify(result.errors, null, 2),
    );
    return false;
  }
  if (result.data?.updateMember) {
    return true;
  }
  return false;
};

export const restoreMember = async (memberId: string): Promise<boolean> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateMember, {
      input: { id: memberId, deletedAt: null },
    }),
  )) as GraphQLResult<{ updateMember: Member }>;

  if (result.errors) {
    console.log(
      "[ERROR] error restoring member",
      JSON.stringify(result.errors, null, 2),
    );
    return false;
  }
  if (result.data?.updateMember) {
    return true;
  }
  return false;
};

const getAllMembersForClub = async (clubId: string): Promise<Array<Member>> => {
  let token: string | undefined = undefined;
  const allMembers = [];
  do {
    const result = (await API.graphql(
      graphqlOperation(queries.membersByClubID, {
        clubID: clubId,
        nextToken: token,
      }),
    )) as GraphQLResult<{
      membersByClubID: { items: Array<Member>; nextToken?: string };
    }>;
    if (result.errors) {
      console.log(
        "[ERROR] error getting all members for club",
        JSON.stringify(result.errors, null, 2),
      );
    }
    token = result.data?.membersByClubID.nextToken;
    if (result.data?.membersByClubID.items) {
      allMembers.push(...result.data.membersByClubID.items);
    }
  } while (token);

  return allMembers;
};

type UpdateMemberResult = {
  updateMember: Member;
};

const updateMember = async (
  params: UpdateMemberInput,
): Promise<Member | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateMember, { input: params }),
    )) as GraphQLResult<UpdateMemberResult>;

    if (result.errors) {
      console.log(
        "[ERROR] error updating member",
        JSON.stringify(result.errors, null, 2),
      );
    }
    if (result.data?.updateMember) {
      return result.data.updateMember;
    }
  } catch (e) {
    console.log("[ERROR] error updating member", e);
  }
  return null;
};

const createMember = async (
  props: CreateMemberInput,
): Promise<Member | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.createMember, { input: props }),
    )) as GraphQLResult<{ createMember: Member }>;

    if (result.errors) {
      console.log(
        "[ERROR] error creating member",
        JSON.stringify(result.errors, null, 2),
      );
    }
    if (result.data?.createMember) {
      return result.data.createMember;
    }
  } catch (e) {
    const error = e as Error;
    logError({
      error,
      message: "Error creating member",
      file: "/containers/Clubs/backend.ts",
    });
    console.log("[ERROR] error creating member", e);
  }
  return null;
};

export const uploadMembers = async ({
  clubId,
  file,
  updateExisting = false,
}: {
  clubId: string;
  file: File;
  updateExisting: boolean;
}): Promise<{
  success: boolean;
  updateCount: number;
  createCount: number;
}> => {
  const allMembers = await getAllMembersForClub(clubId);
  const allMembersByLowerEmail = allMembers.reduce((acc, member) => {
    if (!acc[member.email.toLowerCase()]) {
      acc[member.email.toLowerCase()] = [];
    }
    acc[member.email.toLowerCase()].push(member);
    return acc;
  }, {} as { [email: string]: Member[] });
  const fileReader = new FileReader();

  return await new Promise((resolve) => {
    let success = true;
    let updateCount = 0;
    let createCount = 0;
    fileReader.onload = async (e) => {
      try {
        const csvData = e.target?.result as string;
        if (csvData) {
          const rows = csvData.split("\n");
          for (let i = 0; i < rows.length; i++) {
            const rowI = rows[i];
            if (
              (rowI.includes("First Name") &&
                rowI.includes("Last Name") &&
                rowI.includes("Email")) ||
              rowI.length < 1
            ) {
              continue;
            }
            const columns = rowI.split(",");
            const firstName = columns[0];
            const lastName = columns[1];
            const email = columns[2];
            const totalScore = columns[3];

            if (allMembersByLowerEmail[email.toLowerCase()]) {
              if (!updateExisting) {
                continue;
              }
              const members = allMembersByLowerEmail[email.toLowerCase()];
              updateCount++;
              for (let j = 0; j < members.length; j++) {
                const member = members[j];
                if (member.id) {
                  const parsedTotalScore = parseFloat(totalScore);
                  await updateMember({
                    id: member.id,
                    firstName:
                      firstName && firstName.length > 0
                        ? firstName
                        : member.firstName,
                    lastName:
                      lastName && lastName.length > 0
                        ? lastName
                        : member.lastName,
                    totalScore:
                      parsedTotalScore && parsedTotalScore >= 0
                        ? parsedTotalScore
                        : member.totalScore,
                  });
                }
              }
            } else {
              createCount++;
              await createMember({
                clubID: clubId,
                firstName,
                lastName,
                email,
                totalScore: parseInt(totalScore, 10),
                userId: "UNCLAIMED",
              });
            }
          }
        }
      } catch (_e) {
        const e = _e as Error;
        logError({
          error: e,
          message: "Error uploading members",
          file: "/containers/Clubs/backend.ts",
        });
        success = false;
      }
      resolve({
        success,
        updateCount,
        createCount,
      });
    };
    fileReader.readAsText(file);
  });
};

export {
  addAchievementToMember,
  createAchievement,
  createClub,
  deleteCheckinOption,
  deleteMemberAchievement,
  downloadMembers,
  getAchievementsByClub,
  getCheckinsByClubIDinRange,
  getCheckinsByMemberId,
  getClub,
  getMember,
  getMemberAchievementsByClubAndStatus,
  getMembersByClub,
  getOptionsByClubId,
  saveCheckinOption,
  saveClubNotifications,
  saveMember,
  setClubSchedule,
  setMemberAchievementHasReceivedSwag,
  setMemberAchievementIsNew,
  updateAchievement,
  updateCheckinOptions,
  updateClub,
};
