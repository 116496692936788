import React, { ReactElement } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { CLUB_TYPES, CLUB_TYPES_TYPES, useDispatch, useStore } from "./context";
import { ONBOARDING_STEP_COUNT, showJson } from ".";
import { useHistory } from "react-router-dom";

const OnboardingType = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const handleNext = () => {
    dispatch({ type: "SET_STEP", payload: store.currentStep + 1 });
    history.push(`/clubs/new/address`);
  };

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}>
          <Typography variant="h1">New Club</Typography>
          <Typography variant="h2">2/{ONBOARDING_STEP_COUNT}</Typography>
        </Box>

        <Typography variant="h2" sx={{ mt: 3 }}>
          What type of club is this?
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel htmlFor="club-type">Club Type</InputLabel>
          <Select
            id="club-type"
            fullWidth
            label="Club Type"
            value={store.type || ""}
            onChange={(e) => {
              dispatch({
                type: "SET_TYPE",
                payload: e.target.value as CLUB_TYPES_TYPES,
              });
            }}>
            {CLUB_TYPES.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      <Button
        fullWidth
        size="large"
        onClick={handleNext}
        variant="contained"
        disabled={!store.type || store.type.length < 2}>
        Next
      </Button>
      {showJson(store)}
    </Container>
  );
};

export default OnboardingType;
