export const getClubWithMembers = /* GraphQL */ `
  query GetClubWithMembers($id: ID!) {
    getClub(id: $id) {
      id
      owner
      name
      address
      latitude
      longitude
      city
      state
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      customMemberFields {
        label
        type
        selectOptions
        __typename
      }
      logoUrl
      routesPathsUrl
      members(limit: 2000) {
        items {
          id
          owner
          firstName
          lastName
          email
          totalScore
          clubID
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          owner
          label
          score
          order
          clubID
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const checkinsByClubIdAndDate = /* GraphQL */ `
  query CheckinsByClubIdAndDate(
    $clubID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinsByClubIDAndDate(
      clubID: $clubID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      managerGroup
      isManualAssign
      createdAt
      updatedAt
    }
  }
`;

export const membersByClubID = /* GraphQL */ `
  query MembersByClubID(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const achievmentsByClub = /* GraphQL */ `
  query AchievmentsByClub(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    achievmentsByClub(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        isManualAssign
        deletedAt
        managerGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const checkinsByMemberID = /* GraphQL */ `
  query CheckinsByMemberID(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinsByMemberID(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        managerGroup
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
      }
      nextToken
    }
  }
`;

export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      managerGroup
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getClub = /* GraphQL */ `
  query GetClub($id: ID!) {
    getClub(id: $id) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      isPublic
      approvalStatus
      customMemberFields {
        label
        type
        slug
        selectOptions
        __typename
      }
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          managerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          managerGroup
          owner
          label
          score
          order
          clubID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;

export const memberAchievementsByClubByStatus = /* GraphQL */ `
  query MemberAchievementsByClubByStatus(
    $clubID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByClubByStatus(
      clubID: $clubID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        managerGroup
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          managerGroup
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          managerGroup
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const checkinOptionsByClubID = /* GraphQL */ `
  query CheckinOptionsByClubID(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinOptionsByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        managerGroup
        owner
        label
        score
        order
        clubID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const clubByOwner = /* GraphQL */ `
  query ClubByOwner(
    $owner: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubByOwner(
      owner: $owner
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        approvalStatus
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const searchMembers = /* GraphQL */ `
  query SearchMembers(
    $filter: SearchableMemberFilterInput
    $sort: SearchableMemberSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;

export const membersByClubAndEmail = /* GraphQL */ `
  query MembersByClubAndEmail(
    $clubID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubAndEmail(
      clubID: $clubID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getMemberAchievement = /* GraphQL */ `
  query GetMemberAchievement($id: ID!) {
    getMemberAchievement(id: $id) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      managerGroup
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        managerGroup
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        managerGroup
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const searchMembersNew = /* GraphQL */ `
  query SearchMembersNew(
    $query: String
    $filters: String
    $searchableAttrs: [String]
    $typoTolerance: Boolean
  ) {
    searchMembersNew(
      query: $query
      filters: $filters
      searchableAttrs: $searchableAttrs
      typoTolerance: $typoTolerance
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        deletedAt
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        memberAchievements {
          items {
            id
            memberID
            achievementID
            clubID
            checkinID
            dateAchieved
            isNew
            status
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;

export const memberAchievementsByAchievement = /* GraphQL */ `
  query MemberAchievementsByAchievement(
    $achievementID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByAchievement(
      achievementID: $achievementID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        managerGroup
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          isManualAssign
          deletedAt
          managerGroup
          createdAt
          updatedAt
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          managerGroup
          createdAt
          updatedAt
          deletedAt
          memberAchievements {
            items {
              id
              memberID
              achievementID
              clubID
              checkinID
              dateAchieved
              isNew
              status
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;

export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        managerGroup
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
