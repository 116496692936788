import React, { ReactElement } from "react";

import {
  Box,
  Button,
  Container,
  Input,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useStore } from "./context";
import { ONBOARDING_STEP_COUNT, showJson } from ".";
import { useHistory } from "react-router-dom";

const OnboardingName = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "SET_NAME", payload: e.target.value });
  };

  const handleNext = () => {
    dispatch({ type: "SET_STEP", payload: store.currentStep + 1 });
    history.push(`/clubs/new/type`);
  };

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}>
          <Typography variant="h1">New Club</Typography>
          <Typography variant="h2">1/{ONBOARDING_STEP_COUNT}</Typography>
        </Box>
        <Typography variant="body1">
          {"Let's"} get started creating your club! First, we need to know a few
          things...
        </Typography>

        <Typography variant="h2" sx={{ mt: 3 }}>
          What is the club name?
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 2 }}>
        <Input
          placeholder="Club name"
          fullWidth
          sx={{ textAlign: "center" }}
          onChange={handleChangeName}
          defaultValue={store.name}
          inputProps={{
            sx: { textAlign: "center", fontSize: "1.2rem" },
          }}
        />
      </Paper>
      <Button
        fullWidth
        size="large"
        onClick={handleNext}
        variant="contained"
        disabled={!store.name || store.name.length < 2}>
        Next
      </Button>
      {showJson(store)}
    </Container>
  );
};

export default OnboardingName;
