import React, { ReactElement } from "react";

type ErrorBoxProps = {
  children?: ReactElement | string;
};

const ErrorBox = (props: ErrorBoxProps): ReactElement => {
  return <div>{props.children}</div>;
};

export default ErrorBox;
