import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2660A4", //"rgba(129, 192, 77, 1)",
    },
    secondary: {
      main: "#B3294E", //"rgba(179, 41, 78, 1)",
    },
  },
  typography: {
    h1: {
      fontSize: "2.2rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.8rem",
    },
    h4: {
      fontSize: "1.6rem",
    },
    h5: {
      fontSize: "1.4rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: "50px" },
        contained: {
          color: "white",
        },
      },
    },
  },
});

export default theme;
