import { format, formatISO } from "date-fns";

export const getTimeStamp = (): string => {
  return formatISO(new Date());
};

export const formatTime = (time: string): string => {
  return formatISO(new Date(time));
};

export const formatDay = (time: string): string => {
  return format(new Date(time), "yyyy-MM-dd");
};
