import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  addAchievementToMember,
  deleteMemberAchievement,
  getAchievementsByClub,
  getMembersWithAchievement,
  searchMembers,
} from "./backend";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Achievement,
  AchievementType,
  Member,
  MemberAchievement,
} from "src/API";
import {
  AccessTime,
  Add,
  CheckCircle,
  Close,
  DataThresholding,
  DateRange,
  Edit,
  Search,
  WarningRounded,
} from "@mui/icons-material";
import { formatDay } from "src/utils/time";
import { isAfter, isBefore } from "date-fns";
import { ReactElement, useCallback, useEffect, useState } from "react";
import React from "react";

type AchievementListProps = {
  clubId: string;
};

const useAchievements = (clubId: string) => {
  return useQuery(
    ["club", clubId, "achievements"],
    async () => {
      try {
        const achievements = await getAchievementsByClub(clubId);
        if (achievements) {
          const sortedAchievements = achievements.sort((a, b) =>
            (a?.value || 0) < (b?.value || 0) ? -1 : 1,
          );
          return sortedAchievements;
        }
        return achievements;
      } catch (e) {
        console.log("[ERROR] error using achievements", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const sortOptions = [
  { value: "createdAtAsc", label: "Created Date: Newest" },
  { value: "createdAtDesc", label: "Created Date: Oldest" },
  { value: "scoreAsc", label: "Score: Low-High" },
  { value: "scoreDesc", label: "Score High-Low" },
];

const AchievementList = (): ReactElement => {
  const theme = useTheme();
  const { clubId } = useParams<AchievementListProps>();

  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { status: achievementsStatus, data: achievements } =
    useAchievements(clubId);

  const [sortedAchievements, setSortedAchievements] = useState<
    Achievement[] | null
  >(null);

  const [achievementViewFilter, setAchievementViewFilter] = useState<
    "all" | "active"
  >("all");

  const [sortBy, setSortBy] = useState<string>("createdAtAsc");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [memberSearchQuery, setMemberSearchQuery] = useState<string>("");
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedAchievement, setSelectedAchievement] =
    useState<Achievement | null>(null);
  const [memberResult, setMemberResult] = useState<Array<Member> | null>(null);

  const [isShowingAllWith, setIsShowingAllWith] = useState<boolean>(false);

  const [membersSortedBy, setMembersSortedBy] = useState<
    "firstName" | "lastName" | "dateAchieved"
  >("firstName");

  const handleApplyToMembers = (achievement: Achievement) => {
    setIsOpen(true);
    setSelectedAchievement(achievement);
  };

  const handleSearchMembers = async () => {
    try {
      setIsShowingAllWith(false);
      if (memberSearchQuery.length < 2) {
        return;
      }
      setIsSearching(true);
      const result = await searchMembers({
        clubId,
        query: memberSearchQuery,
        showAll: false,
      });

      if (result) {
        setMemberResult(result.members);
      } else {
        setMemberResult(null);
      }
    } catch (e) {
      console.log("[ERROR] error searching members", e);
      alert("There was an error searching for members. Please try again.");
    } finally {
      setIsSearching(false);
    }
  };
  const handleAwardAchievement = async (member: Member) => {
    try {
      if (!selectedAchievement) {
        return;
      }
      setIsApplying(true);
      const result = await addAchievementToMember({
        achievement: selectedAchievement,
        memberID: member.id,
        checkinID: "AUTO_ADD",
      });
      if (result) {
        if (isShowingAllWith) {
          await handleClickAllMembersWithAchievement();
        } else {
          await handleSearchMembers();
        }
      }
    } catch (e) {
      console.log("[ERROR] error awarding achievement", e);
      alert("There was an error awarding this achievement. Please try again.");
    } finally {
      setIsApplying(false);
    }
  };

  const handleRemoveAchievement = async (
    achievements?: Array<MemberAchievement> | null,
  ) => {
    try {
      if (achievements && achievements.length > 0) {
        setIsApplying(true);
        // If there is an 'AUTO_ADD' member achievement, remove it first.
        const autoAdded = achievements.filter(
          (ma) => ma?.checkinID === "AUTO_ADD",
        );
        const selected = autoAdded.length > 0 ? autoAdded[0] : achievements[0];

        if (!selected) {
          return;
        }
        const result = await deleteMemberAchievement(selected.id);

        if (result) {
          if (isShowingAllWith) {
            await handleClickAllMembersWithAchievement();
          } else {
            await handleSearchMembers();
          }
        }
      }
    } catch (e) {
      console.log("[ERROR] error removing achievement", e);
      alert("There was an error removing achievement. Please try again.");
    } finally {
      setIsApplying(false);
    }
  };

  const handleClickAllMembersWithAchievement = async () => {
    if (!selectedAchievement) {
      return;
    }
    setIsShowingAllWith(true);
    const result = await getMembersWithAchievement(selectedAchievement?.id);
    if (result) {
      const members = result.map((ma) => ma.member) as Array<Member>;
      setMemberResult(members);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedAchievement(null);
    setMemberResult(null);
    setMemberSearchQuery("");
  };
  const handleNavigateToEdit = (id: string) => {
    history.push(`/clubs/${clubId}/achievements/${id}`);
  };

  const getSortedAndFiltered = useCallback(
    (achievements: Achievement[]) => {
      let shown = achievements;
      if (achievementViewFilter === "active") {
        shown = shown.filter((a) => {
          if (a.type === AchievementType.RANGE && a.start && a.end) {
            const outOfRange =
              isBefore(new Date(), new Date(a.start)) ||
              isAfter(new Date(), new Date(a.end));
            return !outOfRange;
          }
          return true;
        });
      }

      return shown;
    },
    [achievementViewFilter],
  );

  const handleSortByChange = (sortKey: string) => {
    setSortBy(sortKey);
  };

  useEffect(() => {
    if (achievements) {
      const sorted = achievements.sort((a, b) => {
        if (sortBy === "createdAtDesc") {
          return a.createdAt < b.createdAt ? -1 : 1;
        } else if (sortBy === "createdAtAsc") {
          return a.createdAt < b.createdAt ? 1 : -1;
        } else if (sortBy === "scoreDesc") {
          return (a?.value || 0) < (b?.value || 0) ? -1 : 1;
        } else if (sortBy === "scoreAsc") {
          return (a?.value || 0) < (b?.value || 0) ? 1 : -1;
        } else {
          return 0;
        }
      });
      const filtered = sortBy.startsWith("score")
        ? sorted.filter((a) => !a?.start && !a?.end)
        : sorted;

      const shown = getSortedAndFiltered(filtered);

      setSortedAchievements(shown);
    }
  }, [achievementViewFilter, sortBy, achievements, getSortedAndFiltered]);

  useEffect(() => {
    if (achievements) {
      handleSortByChange(sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achievements]);

  const renderNoAchievements = () => {
    if (achievements && achievements.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography variant="h4" fontWeight="bold" my={2} textAlign="center">
            No Achievements
          </Typography>
          <Alert>
            <AlertTitle>
              Achievements are used to reward members for their attendance.
            </AlertTitle>
          </Alert>
          <Button
            color="primary"
            variant="outlined"
            sx={{ mt: 2 }}
            startIcon={<Add />}
            onClick={() => history.push(`/clubs/${clubId}/achievements/new`)}>
            Add Achievement
          </Button>
        </Box>
      );
    }
  };

  const renderAchievementStatus = (achievement: Achievement) => {
    if (
      achievement.type === AchievementType.RANGE &&
      achievement.start &&
      achievement.end
    ) {
      const outOfRange =
        isBefore(new Date(), new Date(achievement.start)) ||
        isAfter(new Date(), new Date(achievement.end));
      return outOfRange ? (
        <Chip
          label="Inactive: Out of range"
          color="warning"
          size="small"
          icon={<WarningRounded />}
        />
      ) : (
        <Chip
          label="Active"
          size="small"
          color="success"
          icon={<AccessTime />}
        />
      );
    }
    if (achievement.type === AchievementType.SCORE) {
      return <Chip label="Active" icon={<CheckCircle />} color="success" />;
    }
  };

  const renderAchievementChip = (achievement: Achievement) => {
    if (achievement.type === AchievementType.RANGE) {
      return <Chip label="Date Range" color="primary" icon={<DateRange />} />;
    }
    if (achievement.type === AchievementType.SCORE) {
      return <Chip label="Score" color="primary" icon={<DataThresholding />} />;
    }
  };

  const renderAchievements = () => {
    if (sortedAchievements) {
      return (
        <div>
          {sortedAchievements.map((achievement) => {
            return (
              <Card key={achievement.id} sx={{ mb: theme.spacing(2) }}>
                <CardContent>
                  <Box>{renderAchievementChip(achievement)}</Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}>
                    {achievement.imageUrl && (
                      <img
                        style={{ maxWidth: 150, objectFit: "contain" }}
                        src={achievement.imageUrl}
                      />
                    )}
                    <Box sx={{ width: "100%", ml: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: { xs: "column-reverse", md: "row" },
                          alignItems: { xs: "flex-end", md: "center" },
                        }}>
                        <Typography variant="h5" component="h2" mr={2}>
                          {achievement.name}
                        </Typography>
                        <Button
                          startIcon={<Edit />}
                          variant="outlined"
                          onClick={() => handleNavigateToEdit(achievement.id)}>
                          Edit
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: { xs: "column", md: "row" },
                        }}>
                        <Typography
                          variant="body2"
                          sx={{ mr: theme.spacing(1) }}>
                          Type: {achievement.type}
                          {achievement.type === "RANGE" &&
                            achievement.start &&
                            achievement.end &&
                            ` (${formatDay(achievement.start)} to ${formatDay(
                              achievement.end,
                            )})`}
                        </Typography>
                        {renderAchievementStatus(achievement)}
                      </Box>
                      <Typography variant="body2" fontWeight="bold">
                        {achievement.description}
                      </Typography>
                      <Typography variant="body2">
                        Score: {achievement.value}
                      </Typography>
                      <Typography variant="body2">
                        Swag: {achievement.swag || "No swag"}
                      </Typography>
                      <Typography variant="body2">
                        Created: {formatDay(achievement.createdAt)}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: achievement.isManualAssign
                            ? "bold"
                            : undefined,
                        }}>
                        {achievement.isManualAssign
                          ? "Manually Assigned"
                          : "Automatically Assigned"}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => handleApplyToMembers(achievement)}>
                    Award / Remove from member
                  </Button>
                </CardActions>
              </Card>
            );
          })}
          {renderNoAchievements()}
        </div>
      );
    }
  };
  const renderMemberResult = () => {
    if (isSearching) {
      return (
        <div>
          <CircularProgress />
          <Typography>Searching</Typography>
        </div>
      );
    }
    if (!memberResult || !selectedAchievement) {
      return null;
    }
    const sortedMembers = memberResult.sort((a, b) => {
      if (membersSortedBy === "firstName") {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      }
      if (membersSortedBy === "lastName") {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      }
      if (membersSortedBy === "dateAchieved") {
        const aDate = a.memberAchievements?.items.find(
          (item) => item && item.achievementID === selectedAchievement.id,
        )?.dateAchieved as string | undefined;
        const bDate = b.memberAchievements?.items.find(
          (item) => item && item.achievementID === selectedAchievement.id,
        )?.dateAchieved;
        if (aDate && bDate) {
          return aDate < bDate ? -1 : 1;
        }
        if (aDate) {
          return -1;
        }
        if (bDate) {
          return 1;
        }
        return 0;
      }
      return 0;
    });
    return (
      <>
        {memberResult.length > 0 && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel
              id="show-all-with-label"
              sx={{ backgroundColor: "white", px: 0.5 }}>
              Sort By
            </InputLabel>
            <Select
              labelId="show-all-with-label"
              id="show-all-with"
              value={membersSortedBy}
              onChange={(e) => {
                setMembersSortedBy(
                  e.target.value as "firstName" | "lastName" | "dateAchieved",
                );
              }}>
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="dateAchieved">Date Achieved</MenuItem>
            </Select>
          </FormControl>
        )}
        <List>
          {sortedMembers.map((member) => {
            const items = member.memberAchievements?.items;
            let isAutoAdd = false;
            let hasAchievement = false;
            const filtered = items
              ? items.filter(
                  (ma) => ma?.achievementID === selectedAchievement.id,
                )
              : [];
            hasAchievement = filtered ? filtered.length > 0 : false;
            isAutoAdd =
              filtered && filtered.length > 0
                ? filtered[0]?.checkinID === "AUTO_ADD"
                : false;
            return (
              <ListItem key={`member-${member.id}`}>
                <Box>
                  <Typography>
                    {member.firstName} {member.lastName} ({member.email})
                  </Typography>
                  {isAutoAdd && (
                    <Chip
                      color="info"
                      label="MANUALLY ADDED"
                      size="small"
                      sx={{ my: 0.5 }}
                    />
                  )}
                  {hasAchievement && (
                    <Typography variant="body2">
                      {filtered &&
                        filtered.length > 0 &&
                        filtered[0]?.dateAchieved &&
                        `Achieved: ${formatDay(filtered[0]?.dateAchieved)}`}
                    </Typography>
                  )}
                </Box>
                {!hasAchievement && (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={isApplying}
                    onClick={() => handleAwardAchievement(member)}>
                    Award Achievement
                  </Button>
                )}
                {hasAchievement && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      if (!filtered) {
                        return;
                      }
                      const _filtered = filtered.filter(
                        Boolean,
                      ) as MemberAchievement[];
                      handleRemoveAchievement(_filtered);
                    }}>
                    Remove Achievement
                  </Button>
                )}
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  if (achievementsStatus === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}>
        <FormControl sx={{ mt: { xs: 2, md: 0 } }}>
          <InputLabel id="sort-by-label">Sort By</InputLabel>
          <Select
            labelId="sort-by-label"
            size="small"
            label="Sort By"
            value={sortBy}
            onChange={(e) => handleSortByChange(e.target.value as string)}>
            {sortOptions.map((option) => {
              return (
                <MenuItem
                  key={option.value}
                  selected={sortBy === option.value}
                  value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
          <Typography variant="caption">
            Score hides Range achievements
          </Typography>
        </FormControl>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={achievementViewFilter === "active"}
                onChange={() =>
                  setAchievementViewFilter((s) =>
                    s === "all" ? "active" : "all",
                  )
                }
              />
            }
            label="Show only active"
          />
        </Box>
        <Button
          fullWidth={isMobile}
          color="primary"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => history.push(`/clubs/${clubId}/achievements/new`)}>
          Add Achievement
        </Button>
      </Box>
      {renderAchievements()}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="apply-achievement-modal"
        aria-describedby="apply-achievement-modal-description">
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}>
            <Typography variant="h5">
              Search for members to award this achievement
            </Typography>
            <Button variant="outlined" onClick={handleClose}>
              <Close />
              Close
            </Button>
          </Box>
          <Alert severity="info">
            <Typography>
              You are applying achievement: {selectedAchievement?.name}. If the
              member can still earn this achievement there is a chance the user
              might earn this achievement twice.
            </Typography>
          </Alert>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearchMembers();
            }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="member-search">
                Search by name or email
              </InputLabel>
              <Input
                id="member-search"
                type="search"
                fullWidth
                onChange={(e) => setMemberSearchQuery(e.target.value)}
                value={memberSearchQuery || ""}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      onClick={() => handleSearchMembers()}
                      variant="contained"
                      size="small"
                      startIcon={<Search />}
                      sx={{ borderRadius: 1 }}
                      color="primary">
                      Search
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="outlined"
              onClick={handleClickAllMembersWithAchievement}>
              All members with this achievement
            </Button>
          </form>
          {renderMemberResult()}
        </DialogContent>
      </Dialog>
      <Modal
        open={isApplying}
        aria-labelledby="apply-achievement-modal"
        aria-describedby="apply-achievement-modal-description">
        <div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <h1 style={{ marginRight: 10 }}>Processing...</h1>
              <CircularProgress />
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export default AchievementList;
