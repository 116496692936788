import React, { useEffect, useState, ReactElement } from "react";
import { Amplify, Hub, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import MainApp from "./containers/MainApp";
import CurrentUser from "./types/CurrentUser";
import CurrentUserContext from "./contexts/CurrentUserContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsconfig);

const App = (): ReactElement => {
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [user, setUser] = useState<CurrentUser | null>(null);

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setUser(auth);
        setIsAuthed(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsAuthed(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Hub.listen("auth", (authEvent) => {
      const { payload } = authEvent;
      const { event } = payload;
      if (event === "signIn") {
        loadUser();
      } else if (event === "cognitoHostedUI") {
        setIsAuthed(true);
      } else if (event === "signOut") {
        setIsAuthed(false);
      }
    });
  }, []);

  useEffect(() => {
    loadUser();
  }, []);
  if (isLoading) {
    return <h1>loading...</h1>;
  }

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <CurrentUserContext.Provider value={user}>
          {isAuthed && <MainApp isAuthed={isAuthed} />}
        </CurrentUserContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default withAuthenticator(App, {});
