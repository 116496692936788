import { Container, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";

const WhatAreOptions = (): ReactElement => {
  return (
    <Container>
      <div>
        <Typography variant="subtitle1">Onboarding Navigation</Typography>
        <ol>
          <li>
            <RouterLink to="/onboarding/what-you-need">
              <Typography>What will you need</Typography>
            </RouterLink>
          </li>
          <li>
            <Typography>What are check-in options?</Typography>
          </li>
          <li>
            <RouterLink to="/onboarding/what-are-achievements">
              <Typography>What are achievements?</Typography>
            </RouterLink>
          </li>
        </ol>
        <div>
          <Typography variant="h3">2. What are Check-in Options</Typography>
          <hr />
          <Typography variant="h5">The Label</Typography>
          <Typography variant="body1">
            The label is the value that the member will see on the button.
            Examples would be:
            <ul>
              <li>
                Distance: if the route is a 5k consider{" "}
                {"'5k' or '5 kilometers'"}
              </li>
              <li>
                Check-in: if you want a single check-in button consider{" "}
                {"'check-in' or 'sign-in'"}
              </li>
              <li>
                Other:{" "}
                {
                  "use the number of the options such as '2 laps' or '5 friends'"
                }
              </li>
            </ul>
          </Typography>
          <hr />
          <Typography variant="h5">The Score</Typography>
          <Typography variant="body1">
            {
              "This is what will be added to the member's total when they select the button."
            }
            The score units specified for the club measure what this total
            means.
          </Typography>
          <hr />
        </div>
        <div>
          <RouterLink to="/onboarding/what-you-need">
            <Typography>Back</Typography>
          </RouterLink>
          <RouterLink to="/onboarding/what-are-achievements">
            <Typography>Next</Typography>
          </RouterLink>
        </div>
      </div>
    </Container>
  );
};

export default WhatAreOptions;
