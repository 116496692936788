import React, { ReactElement, useEffect, useState } from "react";

import "./MainApp.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ClubRoot from "./Clubs";
import SettingsRoot from "./Settings";
import { QueryClient, QueryClientProvider } from "react-query";
import OnboardingRoot from "./Onboarding";
import Navigation from "./Navigation";
import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { add, isAfter } from "date-fns";

const queryClient = new QueryClient();

type MainAppProps = {
  isAuthed: boolean;
};

const NOTIFICATION_KEY = "notificationSeen";

// NOTE: when doing an update. Update this value to the current update number
const CURRENT_UPDATE = 1;

const MainApp = (props: MainAppProps): ReactElement | null => {
  const { isAuthed } = props;

  const theme = useTheme();

  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationsExpanded, setNotificationsExpanded] = useState(false);

  const [showingCopied, setShowingCopied] = useState(false);

  useEffect(() => {
    const refreshSession = async () => {
      try {
        const lastRefresh = localStorage.getItem("session:lastRefresh");
        if (
          !lastRefresh ||
          isAfter(new Date(), add(new Date(lastRefresh), { minutes: 10 }))
        ) {
          localStorage.setItem("session:lastRefresh", new Date().toISOString());
          const currentUser = await Auth.currentAuthenticatedUser();
          if (currentUser) {
            const signInSession = currentUser.getSignInUserSession();
            const refreshToken = signInSession.getRefreshToken();
            currentUser.refreshSession(refreshToken, (err: unknown) => {
              if (err) {
                console.log("[ERROR] error refreshing session", err);
              }
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    refreshSession();
  }, []);
  useEffect(() => {
    const lastSeen = localStorage.getItem(NOTIFICATION_KEY);
    if (
      !lastSeen ||
      isNaN(parseInt(lastSeen)) ||
      parseInt(lastSeen) < CURRENT_UPDATE
    ) {
      setShowNotifications(true);
    }
  }, []);

  const renderNotifications = () => {
    if (showNotifications) {
      return (
        <Container maxWidth="sm">
          <Alert
            sx={{
              mt: 2,
            }}
            style={{
              animation: "horizontal-shaking ease-in-out 3s infinite",
            }}
            severity="info"
            onClose={() => {
              setShowNotifications(false);
              localStorage.setItem(NOTIFICATION_KEY, CURRENT_UPDATE + "");
            }}>
            <Typography fontWeight="bold" component="span">
              UPDATE:
            </Typography>{" "}
            Added ability to add custom member fields.{" "}
            <Button onClick={() => setNotificationsExpanded(true)}>
              See More
            </Button>
          </Alert>
          <Dialog
            open={notificationsExpanded}
            onClose={() => setNotificationsExpanded(false)}>
            <DialogTitle>New Feature!</DialogTitle>
            <DialogContent>
              <Typography variant="body2" component="div">
                Custom member fields are things like{" "}
                {`"Shirt Size", "Shirt Style", or "Birthday"`}. You can now add
                them to your club and they will show up on the new member form
                and the report CSV from downloaded members.
                <Typography variant="body2" fontWeight="bold" sx={{ my: 1 }}>
                  Navigate to: Club / Edit Club / Member Fields
                </Typography>
              </Typography>
              <Alert variant="filled" severity="info">
                *Note*: Existing members will not have information for these
                fields. You will need to fill in the fields manually. Uploading
                member information will soon support this.
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setNotificationsExpanded(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      );
    }
  };

  if (!isAuthed) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className="main-app root">
        <Router>
          <Navigation />
          {renderNotifications()}
          <Container maxWidth="lg" sx={{ mt: 5, minHeight: "60vh" }}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/clubs" />
              </Route>
              <Route path="/clubs">
                <ClubRoot />
              </Route>
              <Route path="/settings">
                <SettingsRoot />
              </Route>
              <Route path="/onboarding">
                <OnboardingRoot />
              </Route>
            </Switch>
          </Container>
          <Box
            sx={{
              marginTop: 5,
              minHeight: 100,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography variant="body2" align="center">
              If you run into issues, please email
              <ClickAwayListener onClickAway={() => setShowingCopied(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  color="success"
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="right-end"
                  title="Copied!"
                  open={showingCopied}>
                  <Typography
                    component="span"
                    sx={{
                      display: "block",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                      "&:active": {
                        color: "#dbdbdb",
                      },
                    }}
                    fontWeight="bold"
                    onClick={() => {
                      setShowingCopied(true);
                      setTimeout(() => {
                        setShowingCopied(false);
                      }, 2000);
                      navigator.clipboard.writeText("support@runclub.beer");
                    }}>
                    support@runclub.beer
                  </Typography>
                </Tooltip>
              </ClickAwayListener>
              (Click to copy)
            </Typography>
          </Box>
        </Router>
      </div>
    </QueryClientProvider>
  );
};

export default MainApp;
