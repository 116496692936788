import React, { ReactElement } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useStore } from "./context";
import { ONBOARDING_STEP_COUNT, showJson } from ".";
import { useHistory } from "react-router-dom";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";

const OnboardingLogo = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const handlePhotoCropped = (url: string) => {
    dispatch({ type: "SET_LOGO_URL", payload: getImageUrl(url) });
  };

  const handleNext = () => {
    dispatch({ type: "SET_STEP", payload: store.currentStep + 1 });
    history.push(`/clubs/new/units`);
  };

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}>
          <Typography variant="h1">New Club</Typography>
          <Typography variant="h2">4/{ONBOARDING_STEP_COUNT}</Typography>
        </Box>

        <Typography variant="h2" sx={{ mt: 3 }}>
          What is the club logo?
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 2 }}>
        <FormControl fullWidth>
          <Typography variant="body2">Club Logo</Typography>
          {store.logoUrl && (
            <img
              src={store.logoUrl}
              alt="club logo"
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
              }}
            />
          )}
          <PhotoCropper
            onCancel={() => null}
            onComplete={handlePhotoCropped}
            showPreviewSquare
            keyFolder={`clubs/${store.clubID}`}
          />
        </FormControl>
      </Paper>
      <Button
        fullWidth
        size="large"
        onClick={handleNext}
        variant="contained"
        disabled={!store.name || store.name.length < 2}>
        Next
      </Button>
      {showJson(store)}
    </Container>
  );
};

export default OnboardingLogo;
