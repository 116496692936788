import React, { useEffect, useState, ReactElement } from "react";
import { format, isBefore } from "date-fns";
import { Link as RouterLink, useParams } from "react-router-dom";

import { useQuery } from "react-query";
import { getMember, getClub, getCheckinsByMemberId } from "./backend";

// Table
import { round } from "lodash";
import {
  Breadcrumbs,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Checkin, Club, Member } from "src/API";
import { WeatherResponseUnit } from "src/types";

const useCheckins = (memberId: string) => {
  return useQuery(["member", memberId, "checkins"], async () => {
    try {
      const checkins = await getCheckinsByMemberId(memberId);
      return checkins;
    } catch (e) {
      console.log("[ERROR] error getting check-ins", e);
      return null;
    }
  });
};

type MemberEditParams = {
  clubId: string;
  memberId: string;
};

const MemberShow = (): ReactElement => {
  const { clubId, memberId } = useParams<MemberEditParams>();

  const [club, setClub] = useState<Club | null>(null);
  const [member, setMember] = useState<Member | null>(null);

  const { status: checkinStatus, data: checkins } = useCheckins(memberId);

  const loadClub = async (id: string) => {
    try {
      const club = await getClub(id);

      setClub(club);
    } catch (e) {
      console.log("[ERROR] error loading club", e);
    }
  };
  const loadMember = async (id: string) => {
    try {
      const member = await getMember(id);

      setMember(member);
    } catch (e) {
      console.log("[ERROR] error loading member", e);
    }
  };

  useEffect(() => {
    loadMember(memberId);
    loadClub(clubId);
  }, [clubId, memberId]);

  const renderPrecip = (weather?: WeatherResponseUnit) => {
    if (!weather) {
      return "-";
    }
    if (weather.precipType) {
      const perc = weather.precipProbability
        ? weather.precipProbability * 100
        : 0;
      return `${weather.precipType} / ${perc}%`;
    }
    return "-";
  };

  const renderCheckins = () => {
    if (checkinStatus === "loading") {
      return <h3>Loading Check-ins...</h3>;
    }
    if (checkins) {
      const sortedItems = checkins.sort((a: Checkin, b: Checkin) =>
        isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1,
      );
      return (
        <div>
          <h4>{checkins.length} check-in(s)</h4>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Temperature</TableCell>
                  <TableCell>Precip. Type / Prob.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems.map((checkin: Checkin) => {
                  return (
                    <TableRow key={`checkin-${checkin.id}`}>
                      <TableCell component="th" scope="row">
                        {format(new Date(checkin.date), "E LLL do, yyyy h:mma")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {checkin.score} {club?.scoreUnits}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {checkin.weather && (
                          <>{Math.round(checkin.weather.temperature)} &deg;F</>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {checkin.weather &&
                          renderPrecip(checkin.weather as WeatherResponseUnit)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  };

  if (!club || !member) {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Container>
      <div style={{ paddingTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/clubs">
            All Clubs
          </RouterLink>
          <RouterLink color="inherit" to={`/clubs/${clubId}`}>
            {club.name}
          </RouterLink>
          <Typography>
            {member.firstName} {member.lastName}
          </Typography>
        </Breadcrumbs>
        <h1>
          {member.firstName} {member.lastName}
          <small>
            {" "}
            ({round(member.totalScore, 1)} {club.scoreUnits})
          </small>
        </h1>
        {renderCheckins()}
      </div>
    </Container>
  );
};

export default MemberShow;
