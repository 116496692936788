import React, { ReactElement, useState } from "react";

import { getClub, saveClubNotifications } from "./backend";

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormLabel,
  Switch,
  Typography,
} from "@mui/material";

type ClubFormParams = {
  clubId?: string;
};

const useClub = (clubId?: string) => {
  return useQuery(
    ["club", clubId],
    async () => {
      try {
        if (!clubId) {
          return null;
        }
        const club = await getClub(clubId);
        return club;
      } catch (e) {
        console.log("[ERROR] error getting club", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const ClubNotifications = (): ReactElement => {
  const { clubId } = useParams<ClubFormParams>();

  const { status: clubStatus, data: club } = useClub(clubId);

  const [wantsWeeklyStats, setWantsWeeklyStats] = useState<boolean>(false);

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangeWantsWeeklyStats = () => {
    setWantsWeeklyStats((v) => !v);
  };

  const handleSave = async () => {
    try {
      if (!clubId) {
        return;
      }

      setIsSaving(true);

      const club = await saveClubNotifications(clubId, {
        wantsWeeklyStats,
      });

      if (club) {
        setIsSaved(true);

        setTimeout(() => {
          setIsSaved(false);
        }, 5000);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error saving notification stats", e);
      setError(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  if (clubStatus === "loading") {
    return <CircularProgress />;
  }

  const renderSuccess = () => {
    if (isSaved) {
      return <Alert severity="success">Saved club notifications</Alert>;
    }
  };

  const renderError = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
  };

  const renderWantsWeeklyStats = () => {
    if (!club) {
      return <></>;
    }

    return (
      <div>
        <FormLabel component="legend">
          Get emailed stats every Monday at 9am EST. Stats will be of the
          previous 7 days.
        </FormLabel>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={Boolean(club.wantsWeeklyStats)}
              onChange={handleChangeWantsWeeklyStats}
              name="checkedB"
              disabled={isSaving}
              color="primary"
            />
          }
          label="Receive weekly stats"
        />
      </div>
    );
  };

  return (
    <Container>
      {renderSuccess()}
      {renderError()}
      {renderWantsWeeklyStats()}
      <Button
        fullWidth
        disabled={isSaving}
        onClick={handleSave}
        variant="contained"
        color="primary">
        <Typography>{isSaving ? "Saving" : "Save"}</Typography>
      </Button>
    </Container>
  );
};

export default ClubNotifications;
