import { initializeApp } from "firebase/app";
import * as Analytics from "firebase/analytics";
import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";

const firebaseConfig = {
  apiKey: "AIzaSyBEDg9DDZ5JdphEIMBSMYcTDgX0Y-0LGxw",
  authDomain: "runclub-77f67.firebaseapp.com",
  databaseURL: "https://runclub-77f67.firebaseio.com",
  projectId: "runclub-77f67",
  storageBucket: "runclub-77f67.appspot.com",
  messagingSenderId: "580073205678",
  appId: "1:580073205678:web:baff0e652859f0e552623f",
  measurementId: "G-E8RG7WSRYS",
};

const app = initializeApp(firebaseConfig);
const analytics = Analytics.getAnalytics(app);

/**
 * ERRORS
 */

export const logError = async ({
  error,
  message,
  file,
}: {
  error: Error;
  message?: string;
  file: string;
}): Promise<void> => {
  let userId;
  try {
    const user = await Auth.currentAuthenticatedUser();
    userId = user.username;
  } catch (e) {
    // ignore
  }
  console.log("[DEBUG] logError", error, userId, file);
  Sentry.captureException(error);
  Analytics.logEvent(analytics, "error", {
    error: JSON.stringify(error),
    message,
    file,
  });
};

/**
 * Log general event
 * @param eventName string: name of event
 * @param params object: params to log with event
 */

export const logEvent = async (
  eventName: string,
  params?: {
    [key: string]: unknown;
  },
): Promise<void> => {
  let userId;
  try {
    const user = await Auth.currentAuthenticatedUser();
    userId = user.username;
  } catch (e) {
    // ignore
  }
  console.log("[DEBUG] logEvent", eventName, userId, params);
  Analytics.logEvent(analytics, eventName, {
    userId,
    params,
  });
};
