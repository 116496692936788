import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
  deleteCheckinOption,
  getClub,
  getOptionsByClubId,
  saveCheckinOption,
  updateCheckinOptions,
} from "./backend";
import { CheckinOption, CheckinOptionStatus } from "src/API";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

const useClubOptions = (clubId: string) => {
  return useQuery(
    ["club", clubId, "options"],
    async () => {
      const result = await getOptionsByClubId(clubId);

      return result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useClub = (clubId: string) => {
  return useQuery(["club", clubId], async () => {
    const result = await getClub(clubId);
    return result;
  });
};

type ClubOptionsParams = {
  clubId: string;
};

const ClubOptions = (): ReactElement | null => {
  const { clubId } = useParams<ClubOptionsParams>();
  const { data: club, status: clubStatus } = useClub(clubId);
  const { data: options, status: optionStatus } = useClubOptions(clubId);
  const [isMovingOption, setIsMovingOption] = useState<boolean>(false);
  const [isAddingEditing, setIsAddingEditing] = useState<boolean>(false);
  const [editingOption, setEditingOption] = useState<CheckinOption | null>(
    null,
  );
  const client = useQueryClient();

  const [isNeedDeleteConfirm, setIsNeedDeleteConfirm] =
    useState<boolean>(false);

  const [addEditError, setAddEditError] = useState<string | null>(null);
  const [isScoreError, setIsScoreError] = useState<boolean>(false);
  const [isLabelError, setIsLabelError] = useState<boolean>(false);

  const [renderOptions, setRenderOptions] = useState<CheckinOption[]>([]);

  const [labelValue, setLabelValue] = useState<string | null>(null);
  const [scoreValue, setScoreValue] = useState<number | null>(null);
  const [optionStatusValue, setOptionStatusValue] =
    useState<CheckinOptionStatus>(CheckinOptionStatus.VISIBLE);

  const handlePressDelete = async () => {
    if (editingOption) {
      const result = await deleteCheckinOption(editingOption.id);

      setAddEditError(null);

      if (result) {
        setIsNeedDeleteConfirm(false);
        setIsAddingEditing(false);
        client.invalidateQueries(["club", clubId, "options"]);
      } else {
        setAddEditError("Error deleting check-in. Please try again.");
      }
    }
  };

  const handleCloseModal = () => {
    setIsScoreError(false);
    setIsLabelError(false);
    setIsAddingEditing(false);
    setIsNeedDeleteConfirm(false);
    setEditingOption(null);
  };

  const onSaveOption = async () => {
    if (!options) {
      return;
    }
    if (!scoreValue || scoreValue <= 0 || isNaN(scoreValue)) {
      setAddEditError("Score must be a positive number");
      setIsScoreError(true);
      return;
    }
    if (!labelValue || labelValue.length === 0) {
      setAddEditError("Label must have a value");
      setIsLabelError(true);

      return;
    }
    const result = await saveCheckinOption({
      label: labelValue,
      score: scoreValue,
      clubID: clubId,
      id: editingOption?.id,
      status: optionStatusValue || CheckinOptionStatus.VISIBLE,
      order: editingOption ? editingOption.order : options.length,
    });
    if (result) {
      onAddOption(result);
      client.invalidateQueries(["club", clubId, "options"]);
      handleCloseModal();
    } else {
      setAddEditError("Error saving. Please try again.");
    }
  };

  const onEditClicked = (option: CheckinOption | null) => {
    setIsScoreError(false);
    setIsLabelError(false);
    setIsAddingEditing(true);
    setAddEditError(null);
    setEditingOption(option);
    setLabelValue(option?.label || "");
    setScoreValue(option?.score || 0);
    setOptionStatusValue(option?.status || CheckinOptionStatus.VISIBLE);
  };

  const onAddOption = (option: CheckinOption) => {
    if (options) {
      setRenderedOptions([...options, option]);
    }
  };
  const setRenderedOptions = (options: CheckinOption[]) => {
    setRenderOptions(options.sort((a, b) => (a.order < b.order ? -1 : 1)));
  };

  useEffect(() => {
    if (options) {
      setRenderedOptions(options);
    }
  }, [options]);

  const setResults = (results: Array<CheckinOption>) => {
    setRenderOptions(results.sort((a, b) => (a.order < b.order ? -1 : 1)));
  };

  const moveOptionUp = async (option: CheckinOption) => {
    if (!options) {
      return;
    }
    setIsMovingOption(true);
    const sorted = options.sort((a, b) => (a.order < b.order ? -1 : 1));

    const index = sorted.indexOf(option);

    const temp = sorted[index - 1];
    sorted[index - 1] = option;

    sorted[index] = temp;

    const result = await updateCheckinOptions(sorted);

    if (result) {
      setRenderedOptions(result);
      client.invalidateQueries(["club", clubId, "options"]);
      setResults(result);
    }

    setIsMovingOption(false);
  };
  const moveOptionDown = async (option: CheckinOption) => {
    if (!options) {
      return;
    }
    setIsMovingOption(true);
    const sorted = options.sort((a, b) => (a.order < b.order ? -1 : 1));

    const index = sorted.indexOf(option);

    const temp = sorted[index + 1];
    sorted[index + 1] = option;

    sorted[index] = temp;

    const result = await updateCheckinOptions(sorted);

    if (result) {
      setRenderedOptions(result);
      client.invalidateQueries(["club", clubId, "options"]);
      setResults(result);
    }
    setIsMovingOption(false);
  };

  const renderCard = (option: CheckinOption, idx: number) => {
    if (!options) {
      return null;
    }
    return (
      <Card key={`option-${option.id}-${idx}`} sx={{ mb: 2 }}>
        <CardHeader
          title={option.label}
          subheader={`value: ${option.score}`}
          action={
            <Chip
              color={option.status === "HIDDEN" ? "warning" : "primary"}
              label={option.status || "VISIBLE"}
            />
          }
        />
        <CardContent>
          {options.length > 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mr: { xs: 0, sm: 2 } }}
                disabled={isMovingOption || idx === 0}
                onClick={() => moveOptionUp(option)}>
                <KeyboardArrowUp />
                <Typography>Move Up</Typography>
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={isMovingOption || idx === options.length - 1}
                onClick={() => moveOptionDown(option)}>
                <KeyboardArrowDown />
                <Typography>Move Down</Typography>
              </Button>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button
            onClick={() => onEditClicked(option)}
            variant="contained"
            startIcon={<Edit />}>
            Edit
          </Button>
        </CardActions>
      </Card>
    );
  };

  if (optionStatus === "loading" || clubStatus === "loading") {
    return (
      <div>
        <Typography>Loading Options</Typography>
        <CircularProgress />
      </div>
    );
  }
  if (!options) {
    return null;
  }
  return (
    <div>
      {renderOptions.length === 0 && (
        <Alert severity="info" sx={{ my: 2 }}>
          <AlertTitle>No Check-in Options</AlertTitle>
          <Typography>
            Create at least one option for your members to use when they
            check-in to your club.
          </Typography>
          {club && (
            <Typography variant="caption">
              (For example: {`"1 ${club?.scoreUnits}"`} or{" "}
              {`"5 ${club.scoreUnits}"`})
            </Typography>
          )}
        </Alert>
      )}
      <Container maxWidth="sm">
        {renderOptions.map((option, idx) => renderCard(option, idx))}
      </Container>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={() => onEditClicked(null)}>
        <Add />
        <Typography>Add Option</Typography>
      </Button>
      <Backdrop open={isMovingOption}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={isAddingEditing}
        onClose={handleCloseModal}
        aria-labelledby="adding-editing check-in option title"
        aria-describedby="adding-editing check-in option description">
        <DialogTitle>Add / edit check-in option</DialogTitle>
        <DialogContent>
          {addEditError && (
            <Typography color="secondary">{addEditError}</Typography>
          )}
          {!isNeedDeleteConfirm && (
            <Box mt={1}>
              <form onSubmit={onSaveOption}>
                <TextField
                  id="editing-label"
                  label="label"
                  error={isLabelError}
                  helperText="What the member sees on the check-in button."
                  onChange={(e) => setLabelValue(e.target.value)}
                  value={labelValue}
                />
                <TextField
                  id="editing-value"
                  label="score"
                  type="number"
                  error={isScoreError}
                  helperText="The value added to the member's total."
                  onChange={(e) =>
                    setScoreValue(parseFloat(e.target.value) || 0)
                  }
                  value={scoreValue || ""}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel
                    sx={{ backgroundColor: "#fff", px: 1 }}
                    id="status-select">
                    Status
                  </InputLabel>

                  <Select
                    value={optionStatusValue}
                    onChange={(e) =>
                      setOptionStatusValue(
                        e.target.value as CheckinOptionStatus,
                      )
                    }>
                    <MenuItem
                      value="VISIBLE"
                      selected={
                        optionStatusValue === "VISIBLE" ||
                        optionStatusValue === null
                      }>
                      Visible
                    </MenuItem>
                    <MenuItem
                      value="HIDDEN"
                      selected={optionStatusValue === "HIDDEN"}>
                      Hidden
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}>
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={onSaveOption}>
                    Save
                  </Button>
                  <Button variant="outlined" onClick={handleCloseModal}>
                    <Typography>Cancel</Typography>
                  </Button>
                </Box>
              </form>
            </Box>
          )}
          {editingOption && (
            <div>
              {!isNeedDeleteConfirm && (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => setIsNeedDeleteConfirm(true)}>
                  <Typography>Delete</Typography>
                </Button>
              )}
              {isNeedDeleteConfirm && (
                <div style={{ textAlign: "center" }}>
                  <Typography>Are you sure?</Typography>
                  <div>
                    <Button
                      style={{ marginTop: 20 }}
                      color="secondary"
                      variant="contained"
                      onClick={handlePressDelete}>
                      <Typography>Yes</Typography>
                    </Button>
                    <Button
                      style={{ marginTop: 20 }}
                      color="primary"
                      variant="outlined"
                      onClick={() => setIsNeedDeleteConfirm(false)}>
                      <Typography>No</Typography>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClubOptions;
