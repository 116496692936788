import { Modal, ModalProps } from "@mui/material";
import React, { ReactElement } from "react";

const CenterModal = ({
  children,
  open,
  onClose,
  ...props
}: ModalProps): ReactElement => {
  return (
    <Modal open={open} onClose={onClose} {...props}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 500,
          backgroundColor: "white",
          border: "2px solid #000",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
          padding: 10,
        }}>
        {children}
      </div>
    </Modal>
  );
};

export default CenterModal;
