import CircularProgress from "@mui/material/CircularProgress";
import React, { ReactElement } from "react";

const Loading = (): ReactElement => {
  return (
    <div>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loading;
