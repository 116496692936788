import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDispatch, useStore } from "./context";
import { useHistory } from "react-router-dom";
import React, { ReactElement, useState } from "react";

const OnboardingNav = (): ReactElement => {
  const store = useStore();

  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: store.currentStep > 0 ? "space-between" : "flex-end",
        mb: 2,
      }}>
      {store.currentStep > 0 && (
        <Button
          onClick={() => {
            dispatch({ type: "SET_STEP", payload: store.currentStep - 1 });
            history.goBack();
          }}>
          Go Back
        </Button>
      )}
      <Button color="error" onClick={() => setIsConfirmingCancel(true)}>
        Cancel
      </Button>
      <Dialog open={isConfirmingCancel}>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel? This will erase all form data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              localStorage.removeItem("club-onboarding");
              dispatch({ type: "RESET" });
              console.log("cleared");
              history.push("/clubs");
            }}>
            Yes, Cancel
          </Button>
          <Button onClick={() => setIsConfirmingCancel(false)}>
            No, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OnboardingNav;
