import { Container, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";

const WhatYouNeed = (): ReactElement => {
  return (
    <Container>
      <div>
        <Typography variant="subtitle1">Onboarding Navigation</Typography>
        <ol>
          <li>
            <Typography>What will you need</Typography>
          </li>
          <li>
            <RouterLink to="/onboarding/what-are-options">
              <Typography>What are check-in options?</Typography>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/onboarding/what-are-achievements">
              <Typography>What are achievements?</Typography>
            </RouterLink>
          </li>
        </ol>
        <div>
          <Typography variant="h3">1. What you will need</Typography>
          <hr />
          <Typography variant="h5">A Tablet</Typography>
          <Typography variant="body1">
            You will need an android-based device. We recommend a tablet and our
            preferred option is{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.amazon.com/dp/B07K1RZWMC">
              an Amazon Fire Tablet
            </a>
            . Any android device can be used but you will need to{" "}
            <a
              href="https://www.amazon.com/gp/mas/get/amazonapp"
              rel="noreferrer"
              target="_blank">
              download and install the Amazon Appstore
            </a>
            .
          </Typography>
          <hr />
          <Typography variant="h5">A Club</Typography>
          <Typography variant="body1">
            You can create a club by using the `New Club` button on the main
            page. Several important things to remember:
          </Typography>
          <ul>
            <li>
              <strong>The address:</strong> Use the address where the club will
              meet. This will be used for geolocation and weather analysis.
            </li>
            <li>
              <strong>The score units:</strong>{" "}
              {"The 'score units' are the basis for your club. Examples are:"}
              <ul>
                <li>{"distance: consider using 'miles' or 'kilometers'"}</li>
                <li>
                  {"check-ins: consider using 'check-ins' or 'times attended'"}
                </li>
                <li>
                  {
                    "actions: use the pluralized version of the object (ex: 'beers', 'cupcakes', etc)"
                  }
                </li>
              </ul>
            </li>
            <li>
              <strong>Show custom check-in:</strong> If you enable this option
              your members will get a button to enter between 0 and a maximum
              you set of the score units.
            </li>
          </ul>
          <hr />
        </div>
        <RouterLink to="/onboarding/what-are-options">
          <Typography>Next</Typography>
        </RouterLink>
      </div>
    </Container>
  );
};

export default WhatYouNeed;
