import React, { ReactElement } from "react";

import { Switch, Route } from "react-router-dom";

import SettingsDisplay from "./SettingsDisplay";

const SettingsRoot = (): ReactElement => {
  return (
    <Switch>
      <Route path="/settings/:option?">
        <SettingsDisplay />
      </Route>
    </Switch>
  );
};

export default SettingsRoot;
