import React, { ReactElement, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getClub, setClubSchedule } from "./backend";
import Loading from "src/components/Loading";
import {
  Alert,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

const DEFAULT_TIME = "18:30";

type ClubScheduleProps = {
  clubId: string;
};

type TimeMatch = {
  hours: string;
  minutes: string;
};

type Day =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

type DayAddress =
  | "mondayAddress"
  | "tuesdayAddress"
  | "wednesdayAddress"
  | "thursdayAddress"
  | "fridayAddress"
  | "saturdayAddress"
  | "sundayAddress";

const timeMatch = /(?<hours>[0-9]*):(?<minutes>[0-9]*)/;

const useClub = (clubId?: string) => {
  return useQuery(
    ["club", clubId],
    async () => {
      try {
        if (!clubId) {
          return null;
        }
        const club = await getClub(clubId);
        return club;
      } catch (e) {
        console.log("[ERROR] error getting club", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const ClubSchedule = (): ReactElement => {
  const { clubId } = useParams<ClubScheduleProps>();
  const [editingDay, setEditingDay] = useState<string | null>(null);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const client = useQueryClient();

  const [newTime, setNewTime] = useState<string>(DEFAULT_TIME);
  const [newAddress, setNewAddress] = useState<string>("");
  const [timeError, setTimeError] = useState<string | null>(null);
  const { data: club, isFetching, status: clubStatus } = useClub(clubId);

  const clickSetStartTime = (day: Day) => {
    setEditingDay(day);
    if (
      club?.schedule &&
      club.schedule[day] !== undefined &&
      club.schedule[day] !== null
    ) {
      const time = club.schedule[day] as number;
      const hour = Math.floor(time / 100);
      const minutes = Math.floor(time % 100);
      const vHours = hour < 10 ? `0${hour}` : hour;
      const vMin = minutes < 10 ? `0${minutes}` : minutes;
      setNewTime(`${vHours}:${vMin}`);
    }
    const dayAddress = `${day}Address` as DayAddress;
    if (
      club?.schedule &&
      club.schedule[dayAddress] !== undefined &&
      club.schedule[dayAddress] !== null
    ) {
      setNewAddress(club.schedule[dayAddress] as string);
    }
  };
  const handleClose = () => {
    setEditingDay(null);
    setNewAddress("");
    setNewTime(DEFAULT_TIME);
  };

  const handleSaveTime = async () => {
    if (newTime.length === 0) {
      setTimeError(
        "Cannot use that time. Please set a valid time or click 'Remove time'",
      );
      return;
    }
    if (!editingDay) {
      return null;
    }
    try {
      setIsWorking(true);
      const { hours, minutes } = timeMatch.exec(newTime)?.groups as TimeMatch;

      const h = parseInt(hours);
      const m = parseInt(minutes);

      const time = h * 100 + m;

      const result = await setClubSchedule({
        clubId: clubId,
        day: editingDay,
        time,
        address: newAddress.length > 2 ? newAddress : null,
      });
      if (result) {
        setEditingDay(null);
        setNewAddress("");
        setNewTime(DEFAULT_TIME);
        client.invalidateQueries(["club", clubId]);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error setting time", e);
      setTimeError(e.message);
    } finally {
      setIsWorking(false);
    }
  };
  const handleClearTime = async () => {
    try {
      if (!editingDay) {
        return;
      }
      setIsWorking(true);
      const result = await setClubSchedule({
        clubId: clubId,
        day: editingDay,
        time: null,
        address: null,
      });
      if (result) {
        setEditingDay(null);
        client.invalidateQueries(["club", clubId]);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error clearing time", e);
      setTimeError(e.message);
    } finally {
      setIsWorking(false);
    }
  };

  const renderTime = (day: Day) => {
    if (
      !club?.schedule ||
      club?.schedule[day] === undefined ||
      club?.schedule[day] === null
    ) {
      return <Typography variant="body2">No time</Typography>;
    }
    const time = club.schedule[day] as number;
    const hour = Math.floor(time / 100);
    const minutes = Math.floor(time % 100);
    const ampm = hour >= 12 ? "pm" : "am";

    const visualHour = hour === 0 ? "12" : hour > 12 ? hour - 12 : hour;

    const visualMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return (
      <Typography variant="h6">
        {visualHour}:{visualMinutes} {ampm}
      </Typography>
    );
  };

  const renderAddress = (day: Day) => {
    if (!club?.address) {
      return null;
    }
    if (
      !club?.schedule ||
      club?.schedule[day] === undefined ||
      club?.schedule[day] === null
    ) {
      return null;
    }
    const dayAddress = `${day}Address` as DayAddress;
    if (club?.schedule[dayAddress]) {
      return club?.schedule[dayAddress];
    }
    return `${club.address} ${club.city}, ${club.state}`;
  };

  const renderSchedule = () => {
    if (!club) {
      return null;
    }

    return (
      <div>
        <Paper>
          Monday: {renderTime("monday")}
          {renderAddress("monday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("monday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Tuesday:{renderTime("tuesday")}
          {renderAddress("tuesday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("tuesday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Wednesday:{renderTime("wednesday")}
          {renderAddress("wednesday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("wednesday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Thursday:{renderTime("thursday")}
          {renderAddress("thursday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("thursday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Friday:{renderTime("friday")}
          {renderAddress("friday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("friday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Saturday:{renderTime("saturday")}
          {renderAddress("saturday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("saturday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
        <Paper>
          Sunday:{renderTime("sunday")}
          {renderAddress("sunday")}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => clickSetStartTime("sunday")}>
            <Typography>Edit</Typography>
          </Button>
        </Paper>
      </div>
    );
  };

  return (
    <div>
      <Alert severity="info">
        {
          "Your club's schedule is used to help new members find your club. If you do not want new members to search for your club, do not set a schedule."
        }
      </Alert>
      {renderSchedule()}
      <Modal
        open={editingDay !== null}
        onClose={handleClose}
        aria-labelledby="apply-achievement-modal"
        aria-describedby="apply-achievement-modal-description">
        <div>
          <div>
            <Typography>Editing {editingDay} start time</Typography>
            <Alert severity="info">
              <Typography>
                This is when the club meets on {editingDay}s.
              </Typography>
              <Typography variant="body2">
                Use the clock icon on the right to pick time.
              </Typography>
            </Alert>
            <TextField
              id="time"
              label={`Start time for ${editingDay}`}
              type="time"
              defaultValue="18:00"
              onChange={(e) => setNewTime(e.target.value)}
              value={newTime}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            <TextField
              id="address"
              label={`Address for ${editingDay} (leave blank if same as club address)`}
              type="text"
              onChange={(e) => setNewAddress(e.target.value)}
              fullWidth
              defaultValue={newAddress}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
            {timeError && <Alert severity="error">{timeError}</Alert>}
            {isWorking && (
              <Alert severity="info">
                <Typography>Saving...</Typography>
              </Alert>
            )}
            {!isWorking && (
              <>
                <Button
                  onClick={() => handleSaveTime()}
                  fullWidth
                  variant="contained"
                  color="primary">
                  <Typography>Save</Typography>
                </Button>
                <Button
                  onClick={() => handleClearTime()}
                  fullWidth
                  variant="contained"
                  color="secondary">
                  <Typography>Remove {editingDay} time</Typography>
                </Button>
              </>
            )}
            <Button onClick={handleClose}>
              <Typography>Close</Typography>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={isFetching || clubStatus === "loading"}>
        <div>
          <Loading />
        </div>
      </Modal>
    </div>
  );
};

export default ClubSchedule;
