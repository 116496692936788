import React, { useEffect, useState, ReactElement } from "react";

import { useQuery, useQueryClient } from "react-query";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import {
  createAchievement,
  getAchievementById,
  getClub,
  updateAchievement,
} from "./backend";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InfoRounded } from "@mui/icons-material";
import { endOfDay, formatISO, startOfDay } from "date-fns";

type AchievementFormParams = {
  clubId: string;
  achievementId?: string;
};

const useAchievement = (achievementId?: string) => {
  return useQuery(["achievement", achievementId], async () => {
    try {
      if (!achievementId) {
        return null;
      }
      const achievement = await getAchievementById(achievementId);
      return achievement;
    } catch (e) {
      console.log("[ERROR] error getting achievement", e);
    }
  });
};

const useClub = (clubId?: string) => {
  return useQuery(["club", clubId], async () => {
    try {
      if (!clubId) {
        return null;
      }
      const club = await getClub(clubId);
      return club;
    } catch (e) {
      console.log("[ERROR] error getting club", e);
      return null;
    }
  });
};

const AchievementForm = (): ReactElement => {
  const { clubId, achievementId } = useParams<AchievementFormParams>();
  const history = useHistory();

  const queryClient = useQueryClient();

  const { status: achievementStatus, data: achievement } =
    useAchievement(achievementId);
  const { status: clubStatus, data: club } = useClub(clubId);

  const [achievementImageUrl, setAchievementImageUrl] = useState<
    string | undefined | null
  >(achievement?.name);
  const [achievementName, setAchievementName] = useState<string>(
    achievement?.name || "",
  );
  const [achievementSwag, setAchievementSwag] = useState<
    string | null | undefined
  >(achievement?.swag || null);
  const [achievementDescription, setAchievementDescription] = useState<string>(
    achievement?.description || "",
  );
  const [achievementType, setAchievementType] = useState<string>(
    achievement?.type || "SCORE",
  );
  const [achievementValue, setAchievementValue] = useState<
    number | null | undefined
  >(achievement?.value || 0);

  const [achievementStart, setAchievementStart] = useState<
    string | undefined | null
  >(achievement?.start);
  const [achievementEnd, setAchievementEnd] = useState<
    string | undefined | null
  >(achievement?.end);

  const [scoreError, setScoreError] = useState<string | null>(null);

  const [isManualAssign, setIsManualAssign] = useState<boolean>(false);
  const [isLearningAboutTypes, setIsLearningAboutTypes] =
    useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const handleClickCreateAchievement = async () => {
    if (achievementType === "SCORE" && achievementValue === 0) {
      setError("Score must be greater than 0");
      return;
    }
    if (scoreError) {
      setError("Fix the score error before saving.");
      return;
    }
    if (
      !achievementName ||
      !achievementDescription ||
      !achievementType ||
      !achievementValue
    ) {
      setError("Missing required achievement parameters");
      return;
    }

    if (isNaN(achievementValue) || achievementValue <= 0) {
      setError("Score must be a number greater than 0");
      return;
    }

    const startDate =
      achievementType === "RANGE"
        ? formatISO(
            startOfDay(
              achievementStart ? new Date(achievementStart) : new Date(),
            ),
          )
        : undefined;
    const endDate =
      achievementType === "RANGE"
        ? formatISO(
            endOfDay(achievementEnd ? new Date(achievementEnd) : new Date()),
          )
        : undefined;

    if (achievementId) {
      const result = await updateAchievement({
        id: achievementId,
        name: achievementName,
        description: achievementDescription,
        type: achievementType,
        value: achievementValue,
        clubID: clubId,
        imageUrl: achievementImageUrl,
        swag: achievementSwag,
        start: startDate || undefined,
        end: endDate || undefined,
        isManualAssign: isManualAssign,
      });
      if (result) {
        queryClient.invalidateQueries(["achievement", achievementId]);
        queryClient.invalidateQueries(["club", clubId, "achievements"]);
        history.push(`/clubs/${clubId}/edit/achievements`);
      } else {
        setError("Error saving achievement. Please try again.");
      }
    } else {
      const result = await createAchievement({
        name: achievementName,
        description: achievementDescription,
        type: achievementType,
        value: achievementValue,
        imageUrl: achievementImageUrl,
        clubID: clubId,
        swag: achievementSwag,
        start: startDate || undefined,
        end: endDate || undefined,
        isManualAssign: isManualAssign,
      });
      if (result) {
        queryClient.invalidateQueries(["achievement", achievementId]);
        queryClient.invalidateQueries(["club", clubId, "achievements"]);
        history.push(`/clubs/${clubId}/edit/achievements`);
      } else {
        setError("Error creating achievement. Please try again.");
      }
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<{ value: string }>) => {
    setScoreError(null);
    if (event.target.value === "") {
      setAchievementValue(0);
      return;
    }
    if (!isNaN(parseFloat(event.target.value))) {
      if (parseFloat(event.target.value) <= 0) {
        setScoreError("Score must be greater than 0");
      } else {
        setAchievementValue(parseFloat(event.target.value));
      }
    } else {
      setScoreError("Score must be a number");
    }
  };

  const handleChangeAchievementType = (val: string) => {
    setAchievementType(val);
  };

  const handlePhotoCropped = (croppedImage: string) => {
    setAchievementImageUrl(getImageUrl(croppedImage));
  };

  useEffect(() => {
    if (achievementStatus === "success" && achievement) {
      setAchievementName(achievement.name);
      setAchievementImageUrl(achievement.imageUrl);
      setAchievementDescription(achievement.description);
      setAchievementSwag(achievement.swag);
      setAchievementType(achievement.type);
      setAchievementValue(achievement.value);
      setAchievementStart(achievement.start);
      setAchievementEnd(achievement.end);
      setIsManualAssign(Boolean(achievement.isManualAssign));
    }
  }, [achievementStatus, achievement]);

  const renderAchievementTypeForm = () => {
    if (achievementId && !achievement) {
      return null;
    }
    if (
      achievementType === "SCORE" &&
      club &&
      achievementValue !== null &&
      achievementValue !== undefined
    ) {
      return (
        <>
          {scoreError && (
            <Alert sx={{ my: 1 }} variant="filled" severity="error">
              {scoreError}
            </Alert>
          )}
          <TextField
            id="achievement-value"
            label="Achievement Score"
            helperText={`The amount of ${club.scoreUnits} to earn this achievement.`}
            fullWidth
            sx={{ mb: 2 }}
            type="number"
            defaultValue={achievementValue}
            onChange={handleChangeValue}
          />
        </>
      );
    } else if (achievementType === "RANGE") {
      return (
        <Box sx={{ mb: 2 }}>
          <Alert severity="info" sx={{ mb: 1 }}>
            Range achievements reward members for checking-in X number of times
            over the date range.
            <br />
            Examples:
            <ul>
              <li>
                Once a week for Jan and Feb: Set start to Jan 1st, end to Feb
                28th/29th, and check-in count to the <strong>total</strong>{" "}
                number of opportunities your members have to check-in in that
                range.
              </li>
              <li>
                Check-in 3 times in May: Set start to May 1st, end to May 31st,
                and check-in count to 3.
              </li>
            </ul>
          </Alert>
          {scoreError && (
            <Alert sx={{ my: 1 }} variant="filled" severity="error">
              {scoreError}
            </Alert>
          )}
          <TextField
            id="achievement-value"
            label="Check-ins over range (total # of check-ins needed over range)"
            fullWidth
            sx={{ mt: 1, mb: 2 }}
            helperText="If doing weekly over multiple months, count the number of weeks in the range."
            type="number"
            error={!!scoreError}
            defaultValue={achievementValue}
            onChange={handleChangeValue}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              sx={{ display: "flex", mt: 2, mb: 1, justifyContent: "center" }}>
              <DatePicker
                value={achievementStart}
                label="Start time"
                onChange={(date?: Date | string | null) => {
                  if (
                    !date ||
                    date.toString() === "Invalid Date" ||
                    typeof date === "string"
                  ) {
                    return;
                  }
                  setAchievementStart(
                    formatISO(startOfDay(new Date(date))) || null,
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography style={{ margin: "auto 10px" }}>to</Typography>
              <DatePicker
                value={achievementEnd}
                label="End time"
                onChange={(date?: Date | string | null) => {
                  if (
                    !date ||
                    date.toString() === "Invalid Date" ||
                    typeof date === "string"
                  ) {
                    return;
                  }
                  setAchievementEnd(
                    formatISO(endOfDay(new Date(date))) || null,
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Includes start day and end day [start: 00:00 hour, end: 23:59 hour]">
                <Typography
                  variant="caption"
                  sx={{ display: "flex", alignItems: "center" }}>
                  <strong>NOTE:</strong> The start and end times are inclusive.
                  <InfoRounded sx={{ ml: 1 }} />
                </Typography>
              </Tooltip>
            </Box>
          </LocalizationProvider>
        </Box>
      );
    }
  };

  const renderAchievementIsManual = () => {
    return (
      <FormGroup sx={{ my: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={isManualAssign}
              onChange={() => setIsManualAssign((s) => !s)}
            />
          }
          label="Manual award achievement"
        />
        <Typography variant="caption">
          Set if you do not want to automatically award this achievement.{" "}
          {'(Use the "Award achievement" functionality)'}
        </Typography>
      </FormGroup>
    );
  };

  if (achievementStatus === "loading" || clubStatus === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm" sx={{ mb: 20, pb: 10 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <RouterLink color="inherit" to="/clubs">
          All Clubs
        </RouterLink>
        <RouterLink color="inherit" to={`/clubs/${clubId}`}>
          {club?.name}
        </RouterLink>
        <RouterLink color="inherit" to={`/clubs/${clubId}/achievements`}>
          Achievements
        </RouterLink>
        <Typography color="textPrimary">
          {achievementId ? "Editing" : "New"}
        </Typography>
      </Breadcrumbs>
      {error && (
        <Alert sx={{ my: 3 }} variant="filled" severity="error">
          {error}
        </Alert>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body2" sx={{ my: 2 }} color="error">
          * denotes required field
        </Typography>
      </Box>
      <TextField
        id="achievement-name"
        label="Achievement Name *"
        fullWidth
        sx={{ mb: 2 }}
        value={achievementName}
        onChange={(e) => setAchievementName(e.target.value)}
      />
      <TextField
        id="achievement-description"
        label="Achievement Description"
        fullWidth
        multiline
        minRows={3}
        sx={{ mb: 2 }}
        value={achievementDescription}
        onChange={(e) => setAchievementDescription(e.target.value)}
      />
      <Box sx={{ mb: 2 }}>
        <Typography>Achievement Image</Typography>
        {achievementImageUrl && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <img src={achievementImageUrl} />
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={() => setAchievementImageUrl(null)}>
              Remove Image
            </Button>
          </div>
        )}
        <PhotoCropper
          onCancel={() => null}
          onComplete={handlePhotoCropped}
          showPreviewSquare
          keyFolder="achievements"
        />
      </Box>
      <TextField
        id="achievement-swag"
        label="Achievement Swag (Optional)"
        helperText="Only use this field if you are offering swag when the member earns this achievement."
        fullWidth
        sx={{ mb: 2 }}
        value={achievementSwag || ""}
        onChange={(e) => setAchievementSwag(e.target.value)}
      />
      <Button
        variant="outlined"
        endIcon={<InfoRounded />}
        onClick={() => setIsLearningAboutTypes(true)}>
        Learn about Achievement Types
      </Button>
      <FormControl sx={{ my: 2 }} fullWidth>
        <InputLabel
          id="achievement-type-label"
          sx={{ backgroundColor: "white", px: 1 }}>
          Achievement Type *
        </InputLabel>
        <Select
          labelId="achievement-type-label"
          id="achievement-type"
          fullWidth
          value={achievementType || "SCORE"}
          onChange={(e) => handleChangeAchievementType(e.target.value)}>
          <MenuItem value={"SCORE"}>Total Score</MenuItem>
          <MenuItem value={"RANGE"}>Date Range</MenuItem>
        </Select>
      </FormControl>
      {renderAchievementTypeForm()}
      {renderAchievementIsManual()}
      {error && (
        <Alert sx={{ my: 3 }} variant="filled" severity="error">
          {error}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickCreateAchievement()}>
        {achievementId ? "Update Achievement" : "Create Achievement"}
      </Button>
      <Dialog
        open={isLearningAboutTypes}
        onClose={() => setIsLearningAboutTypes(false)}>
        <DialogTitle>Achievement Types Explained</DialogTitle>
        <DialogContent>
          <Typography>
            <Typography fontWeight="bold">Total Score:</Typography>
            Total Score achievements reward members for earning a certain score.
            This is the total over the lifetime of their membership.
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography>
            <Typography fontWeight="bold">Date Range:</Typography>
            Date Range achievements reward members for checking-in X number of
            times over the date range.
            <Typography
              component={Paper}
              fontStyle="italic"
              variant="caption"
              sx={{ display: "flex", p: 1, alignItems: "flex-start" }}>
              <strong style={{ marginRight: "5px" }}>Example:</strong> If you
              wanted members to check in 7 times between Jan. 1st{" "}
              {new Date().getFullYear() + 1} and Mar. 31st{" "}
              {new Date().getFullYear() + 1}, you would set the start date to
              Jan. 1st {new Date().getFullYear() + 1}, the end date to Mar. 31st{" "}
              {new Date().getFullYear() + 1}, and the check-in count to 7.
            </Typography>
          </Typography>
          <Typography>
            <Typography fontWeight="bold">Note:</Typography>
            <em>Why would I use date range over total score?</em> Date range is
            useful if you want to incentivize members to check-in a certain
            number of times over a period of time or risk missing out on the
            achievement.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsLearningAboutTypes(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AchievementForm;
