import React, { ReactElement, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";

const SettingsGeneral = (): ReactElement => {
  const [email, setEmail] = useState<string | null>(null);
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [verificationError, setVerificationError] = useState<string | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState<boolean>(false);
  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] =
    useState<boolean>(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseVerificationModal = () => {
    setIsVerificationModalOpen(false);
  };

  const handleSubmitNewEmail = async () => {
    setEmailError(null);
    try {
      if (!newEmail) {
        setEmailError("New Email cannot be blank");
        return;
      }

      setIsChangingEmail(true);
      const user = await Auth.currentAuthenticatedUser();

      const result = await Auth.updateUserAttributes(user, {
        email: newEmail,
      });

      if (result === "SUCCESS") {
        setEmail(newEmail);
        setEmailError(null);
        setIsSuccess(true);
        setIsModalOpen(false);
        setIsEmailVerified(false);
        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] Error changing email", e);
      setEmailError(e.message);
    } finally {
      setIsChangingEmail(false);
    }
  };

  const handleResendCodeClicked = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.resendSignUp(user.username);
      setIsCodeResent(true);
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] Error sending confirmation code", e);
      setVerificationError(e.message);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      if (!verificationCode) {
        setVerificationError("Please enter a code");
        return;
      }
      setIsVerifyingEmail(true);

      const result = await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        verificationCode,
      );

      if (result === "SUCCESS") {
        setIsVerificationModalOpen(false);
        setIsEmailVerified(true);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] Error verifying email", e);
      setVerificationError(e.message);
    } finally {
      setIsVerifyingEmail(false);
    }
  };
  const loadUser = async () => {
    try {
      setIsLoadingUser(true);
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      setEmail(attributes.email);
      setNewEmail(attributes.email);
      setIsEmailVerified(attributes.email_verified);
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error loading user", e);
      setError(e.message);
    } finally {
      setIsLoadingUser(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  const renderVerificationError = () => {
    if (verificationError) {
      return <Alert severity="error">{verificationError}</Alert>;
    }
  };

  const renderEmailError = () => {
    if (emailError) {
      return <Alert severity="error">{emailError}</Alert>;
    }
  };

  const renderSuccess = () => {
    if (isSuccess) {
      return (
        <Alert severity="success" onClose={() => setIsSuccess(false)}>
          Successfully updated your account.
        </Alert>
      );
    }
  };

  const renderVerification = () => {
    if (isEmailVerified) {
      return <Chip label="Email verified" color="success" />;
    }
    return (
      <>
        <Chip label="Not verified" color="warning" />
        <Button
          color="primary"
          size="small"
          onClick={() => setIsVerificationModalOpen(true)}>
          Verify Email
        </Button>
      </>
    );
  };

  const renderEmail = () => {
    if (email) {
      return (
        <div>
          <Typography variant="body2">Email:</Typography>
          <Typography variant="body2">{email}</Typography>
          <Button
            startIcon={<Refresh />}
            onClick={() => setIsModalOpen(true)}
            size="small">
            Change
          </Button>
        </div>
      );
    }
  };

  const renderEmailVerificationStatus = () => {
    if (email) {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
            Email status:
          </Typography>
          {renderVerification()}
        </Box>
      );
    }
  };

  if (isLoadingUser) {
    return (
      <div>
        <Typography variant="body2">Loading user...</Typography>
        <CircularProgress color="primary" />
      </div>
    );
  }
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  return (
    <Container maxWidth="sm">
      <Typography variant="h5">General</Typography>
      {renderSuccess()}
      {renderEmail()}
      <Divider sx={{ my: 1 }} />
      {renderEmailVerificationStatus()}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="change-email-modal"
        aria-describedby="change your email address modal">
        <DialogTitle>Change Email</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ my: 1 }}
            onChange={(e) => setNewEmail(e.target.value)}
            label="Email"
            type="email"
            autoComplete="off"
            defaultValue={email}
            value={newEmail}
            fullWidth
          />
          {renderEmailError()}
          <Button
            disabled={isChangingEmail}
            onClick={handleSubmitNewEmail}
            variant="outlined"
            color="primary"
            size="large">
            {isChangingEmail ? "Changing" : "Change"}
          </Button>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isVerificationModalOpen}
        onClose={handleCloseVerificationModal}
        aria-labelledby="verify-email-modal"
        aria-describedby="verify your email modal">
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ my: 1 }}>
            You were sent a verification email. Enter the code in the email
            below.
            {!isCodeResent && (
              <Button onClick={handleResendCodeClicked}>
                <Typography>Resend code</Typography>
              </Button>
            )}
          </Alert>
          <TextField
            sx={{ my: 1 }}
            onChange={(e) => setVerificationCode(e.target.value)}
            label="Verification Code"
            value={verificationCode}
            fullWidth
          />
          {renderVerificationError()}
          <Button
            sx={{ m: 1 }}
            disabled={isVerifyingEmail}
            onClick={handleVerifyEmail}
            variant="outlined"
            color="primary"
            size="large">
            {isVerifyingEmail ? "Verifying" : "Verify"}
          </Button>
          <Button onClick={() => setIsVerificationModalOpen(false)}>
            Cancel
          </Button>
          <Alert severity="warning">
            {
              'If you have verified your email but it still says "Unverified", please sign out and log back in'
            }
          </Alert>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default SettingsGeneral;
