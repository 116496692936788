import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getClub, updateClub } from "./backend";
import { ClubEditParams } from "./ClubEdit";
import { useParams } from "react-router-dom";
import { MemberField, MemberFieldType } from "src/API";
import { Add, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

const useClub = (clubId?: string) => {
  return useQuery(
    ["club", clubId],
    async () => {
      try {
        if (!clubId) {
          return null;
        }
        const club = await getClub(clubId);
        return club;
      } catch (e) {
        console.log("[ERROR] error getting club", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const ClubMemberFields = (): ReactElement => {
  const { clubId } = useParams<ClubEditParams>();

  const { data: club, status: clubStatus } = useClub(clubId);

  const [isAddingField, setIsAddingField] = useState<boolean>(false);

  const [memberFields, setMemberFields] = useState<MemberField[]>([]);

  const [saveError, setSaveError] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isMoving, setIsMoving] = useState<boolean>(false);

  const [editingFieldIndex, setEditingFieldIndex] = useState<number>(-1);
  const [deletingFieldIndex, setDeletingFieldIndex] = useState<number>(-1);

  const [newFieldLabel, setNewFieldLabel] = useState<string>("");
  const [newFieldType, setNewFieldType] = useState<MemberFieldType | "">(
    MemberFieldType.TEXT,
  );
  const [newFieldSlug, setNewFieldSlug] = useState<string>("");
  const [newFieldOptions, setNewFieldOptions] = useState<string>("");

  const [isConfirmingDelete, setIsConfirmingDelete] =
    React.useState<boolean>(false);

  const [showInfo, setShowInfo] = useState<boolean>(true);

  useEffect(() => {
    if (editingFieldIndex > -1) {
      setNewFieldLabel(memberFields[editingFieldIndex]?.label || "");
      setNewFieldType(
        (memberFields[editingFieldIndex]?.type as MemberFieldType) || "",
      );
      setNewFieldOptions(
        memberFields[editingFieldIndex]?.selectOptions?.join(", ") || "",
      );
      setNewFieldSlug(memberFields[editingFieldIndex]?.slug || uuidv4());
    }
  }, [editingFieldIndex, memberFields]);

  useEffect(() => {
    if (club && club.customMemberFields) {
      // @ts-ignore
      setMemberFields(club.customMemberFields.filter(Boolean) || []);
    }
  }, [club]);

  const handleDismissDialogs = () => {
    setIsConfirmingDelete(false);
    setIsAddingField(false);
    setEditingFieldIndex(-1);
  };

  const handlePromptDelete = () => {
    setDeletingFieldIndex(editingFieldIndex);
    setEditingFieldIndex(-1);
    setIsConfirmingDelete(true);
  };

  const handleDeleteField = async () => {
    if (deletingFieldIndex > -1) {
      const newFields = memberFields.filter(
        (_, idx) => idx !== deletingFieldIndex,
      );

      try {
        const updateFields = newFields.map((field) =>
          _.omit(field, "__typename"),
        );
        setSaveError(false);
        setIsProcessing(true);
        const result = await updateClub({
          id: clubId,
          customMemberFields: updateFields,
        });

        if (result) {
          setMemberFields(newFields);
          setEditingFieldIndex(-1);
          handleDismissDialogs();
        } else {
          setSaveError(true);
        }
      } catch (e) {
        console.log("[ERROR] error deleting field", e);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleAddField = () => {
    setIsAddingField(true);
    setEditingFieldIndex(-1);
    setNewFieldLabel("");
    setNewFieldType("");
    setNewFieldOptions("");
    setNewFieldSlug("");
  };

  const handleSaveFields = async () => {
    try {
      if (!newFieldLabel || !newFieldType) {
        return;
      }
      let updateFields = memberFields.map((f) => _.omit(f, "__typename"));

      const newUpdateField = {
        label: newFieldLabel,
        type: newFieldType,
        slug: newFieldSlug || uuidv4(),
        selectOptions: newFieldOptions
          .split(",")
          .map((option) => option.trim()),
      };
      if (editingFieldIndex > -1) {
        // @ts-ignore
        updateFields[editingFieldIndex] = newUpdateField;
      } else {
        // @ts-ignore
        updateFields.push(newUpdateField);
      }
      updateFields = updateFields.map((f) => {
        if (f && !f.slug) {
          return {
            ...f,
            slug: uuidv4(),
          };
        }
        return f;
      });
      setIsProcessing(true);
      const result = await updateClub({
        id: clubId,
        customMemberFields: updateFields,
      });

      if (result) {
        // @ts-ignore
        setMemberFields(updateFields);
        handleDismissDialogs();
      } else {
        setSaveError(true);
      }
    } catch (e) {
      console.log("[ERROR] error saving fields", e);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleMoveIdx = async (idx: number, direction: number) => {
    if (idx + direction < 0 || idx + direction > memberFields.length - 1) {
      return;
    }
    try {
      setIsMoving(true);
      const newFields = [...memberFields];
      const temp = newFields[idx];
      newFields[idx] = newFields[idx + direction];
      newFields[idx + direction] = temp;

      const updateFields = newFields.map((f) => _.omit(f, "__typename"));

      const result = await updateClub({
        id: clubId,
        customMemberFields: updateFields,
      });
      if (result) {
        setMemberFields(newFields);
      }
    } catch (e) {
      console.log("[ERROR] error moving field", e);
    } finally {
      setIsMoving(false);
    }
  };

  const renderField = ({
    field,
    idx,
  }: {
    field?: MemberField | null;
    idx: number;
  }) => {
    return (
      <Card key={`card-${idx}`} sx={{ my: 1 }}>
        <CardHeader
          title={field && field.label}
          action={
            <Button
              variant="contained"
              size="small"
              startIcon={<Edit />}
              onClick={() => setEditingFieldIndex(idx)}>
              Edit
            </Button>
          }
        />
        <CardContent>
          <Typography>type: {field && field.type}</Typography>
          <Divider sx={{ my: 1 }} />

          {field && field.type === MemberFieldType.SELECT && (
            <Typography>options: {field.selectOptions?.join(", ")}</Typography>
          )}
        </CardContent>
        <CardActions>
          <LoadingButton
            loading={isMoving}
            disabled={idx === 0}
            onClick={() => handleMoveIdx(idx, -1)}>
            Move Up
          </LoadingButton>
          <LoadingButton
            loading={isMoving}
            disabled={idx === memberFields.length - 1}
            onClick={() => handleMoveIdx(idx, 1)}>
            Move Down
          </LoadingButton>
        </CardActions>
      </Card>
    );
  };
  if (clubStatus === "loading") {
    return <CircularProgress />;
  }
  return (
    <Container maxWidth="sm">
      {showInfo && (
        <Alert severity="info" onClose={() => setShowInfo(false)}>
          <Typography>
            Member fields allow you to ask your members for custom information.
            Any field listed will be prompted of a new member when joining your
            club.
            <br />
            Examples of custom fields:{" "}
            {`"Birthday", "Shirt Size", "Shirt Style", etc`}
          </Typography>
        </Alert>
      )}
      {memberFields.map((field, idx) => renderField({ field, idx }))}
      {memberFields.length === 0 && (
        <Typography textAlign="center" variant="h6" sx={{ my: 2 }}>
          No member fields found
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{ mt: 2 }}
        startIcon={<Add />}
        onClick={() => handleAddField()}>
        Add Field
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={editingFieldIndex !== -1 || isAddingField}
        onClose={() => handleDismissDialogs()}>
        <DialogTitle>
          {editingFieldIndex === -1 ? "Add Field" : "Edit Field"}
        </DialogTitle>
        <DialogContent>
          {saveError && <Alert severity="error">Error saving field</Alert>}
          <TextField
            label="Label"
            onChange={(e) => setNewFieldLabel(e.target.value)}
            sx={{ my: 2 }}
            value={newFieldLabel}
            fullWidth
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel variant="standard" sx={{ ml: 1.75, mt: -0.75 }}>
              Field Type
            </InputLabel>
            <Select
              value={newFieldType}
              onChange={(e) =>
                setNewFieldType(e.target.value as MemberFieldType)
              }
              label="Field Type"
              inputProps={{
                id: "field-type",
              }}>
              <MenuItem value={MemberFieldType.TEXT}>
                {MemberFieldType.TEXT}
              </MenuItem>
              <MenuItem value={MemberFieldType.SELECT}>
                {MemberFieldType.SELECT}
              </MenuItem>
              <MenuItem value={MemberFieldType.DATE}>
                {MemberFieldType.DATE}
              </MenuItem>
              <MenuItem value={MemberFieldType.NUMBER}>
                {MemberFieldType.NUMBER}
              </MenuItem>
            </Select>
          </FormControl>
          {newFieldType === MemberFieldType.SELECT && (
            <TextField
              fullWidth
              onChange={(e) => setNewFieldOptions(e.target.value)}
              sx={{ my: 2 }}
              label="Select Options (comma separated)"
              helperText="Separate options with commas"
              value={newFieldOptions}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 3,
          }}>
          <Button color="error" onClick={() => handlePromptDelete()}>
            Delete Field
          </Button>
          <Box>
            <LoadingButton
              loading={isProcessing}
              variant="contained"
              onClick={() => handleSaveFields()}>
              Save
            </LoadingButton>
            <Button onClick={() => handleDismissDialogs()}>Cancel</Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmingDelete} onClose={() => handleDismissDialogs()}>
        <DialogTitle>Are you sure you want to delete this field?</DialogTitle>
        <DialogContent>
          {saveError && <Alert severity="error">Error deleting field</Alert>}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isProcessing}
            color="error"
            variant="contained"
            onClick={() => {
              handleDeleteField();
            }}>
            Yes, Delete
          </LoadingButton>
          <Button onClick={() => handleDismissDialogs()}>No, Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ClubMemberFields;
