/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "6702c9fc063f4c23a4b64d3088dc26fb",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6702c9fc063f4c23a4b64d3088dc26fb",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://yawhkao74jbyxgmwbz6wukrq5y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-kmuzkybvkvemtjclr2jc35zmfy",
    "aws_cognito_identity_pool_id": "us-east-1:d76c327b-35fa-4d2d-8231-de31ea34efbd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_cay8HrDda",
    "aws_user_pools_web_client_id": "6krl7hqugb9gh4u7f398eimltm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "unlubpp-20200214201907-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://unlubpp-20200214201907-hostingbucket-develop.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "runclub-analytics195109-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
