import React, { useEffect, useState, ReactElement } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { getClub, getCheckinsByClubIDinRange } from "./backend";

import { CSVLink } from "react-csv";
import { differenceInDays, endOfDay, formatISO, startOfDay } from "date-fns";

import ErrorBox from "src/components/ErrorBox";
import { round } from "lodash";
import { MAX_NUMBER_OF_DAYS_RANGE_SEARCH } from "src/global/constants";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { Checkin, Club, Member } from "src/API";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InfoRounded } from "@mui/icons-material";

type MemberDict = {
  [id: string]: Array<Checkin>;
};

type MemberRecord = {
  member: Member;
  checkinCount: number;
  score: number;
};

type MembersRangeParams = {
  clubId: string;
};

const MembersRange = (): ReactElement => {
  const theme = useTheme();
  const { clubId } = useParams<MembersRangeParams>();

  const [club, setClub] = useState<Club | null>(null);
  const [startTime, setStartTime] = useState<string | null>(
    format(new Date(), "yyyy-MM-dd"),
  );
  const [endTime, setEndTime] = useState<string | null>(
    format(new Date(), "yyyy-MM-dd"),
  );

  const [memberCheckins, setMemberCheckins] = useState<MemberDict>({});
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const loadClub = async (id: string) => {
    try {
      const club = await getClub(id);

      setClub(club);
    } catch (e) {
      console.log("[ERROR] error loading club", e);
    }
  };

  const onSubmit = async () => {
    try {
      setHasSearched(false);
      if (!startTime || !endTime) {
        setError("start / end time missing");
        return;
      }

      if (
        differenceInDays(new Date(endTime), new Date(startTime)) >
        MAX_NUMBER_OF_DAYS_RANGE_SEARCH
      ) {
        setError(
          `Please limit your search to a max of ${MAX_NUMBER_OF_DAYS_RANGE_SEARCH} days. If you deeper reports, please contact support@runclub.beer`,
        );
        return;
      }

      if (isSearching) {
        return;
      }

      setError(null);

      setIsSearching(true);

      const start = formatISO(startOfDay(new Date(startTime)));
      const end = formatISO(endOfDay(new Date(endTime)));
      const checkins = await getCheckinsByClubIDinRange(clubId, start, end);
      const memberCheckins: { [key: string]: Array<Checkin> } = {};
      if (!Array.isArray(checkins)) {
        return;
      }
      checkins.forEach((checkin: Checkin) => {
        const { member } = checkin;
        if (!member) {
          return;
        }
        if (memberCheckins[member.id]) {
          memberCheckins[member.id].push(checkin);
        } else {
          memberCheckins[member.id] = [checkin];
        }
      });
      setMemberCheckins(memberCheckins);
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error getting check-ins", e);
      setError(e.message);
    } finally {
      setIsSearching(false);
      setHasSearched(true);
    }
  };

  const parseCheckins = (memberCheckins: MemberDict): Array<MemberRecord> => {
    const keys = Object.keys(memberCheckins);
    const users = keys
      .map((key: string) => {
        const checkins: Array<Checkin> = memberCheckins[key];
        const score = checkins.reduce((a, b) => a + b.score, 0);
        const { member } = checkins[0];
        if (!member) {
          return null;
        }
        return {
          member,
          checkinCount: checkins.length,
          score,
        };
      })
      .filter((mr) => mr !== null) as Array<MemberRecord>;
    return users;
  };

  useEffect(() => {
    loadClub(clubId);
  }, [clubId]);

  const getCsvData = (users: Array<MemberRecord>) => {
    const data = users.map((record) => {
      const { member, checkinCount, score } = record;
      return [
        member.firstName,
        member.lastName,
        member.email,
        checkinCount,
        score,
      ];
    });
    return [
      ["first name", "last name", "email", "check-in count", "score"],
      ...data,
    ];
  };

  const renderCheckins = () => {
    if (isSearching) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const users = parseCheckins(memberCheckins);
    const sortedUsers = users.sort((a, b) =>
      a.checkinCount > b.checkinCount ? -1 : 1,
    );
    const csvOutput = getCsvData(sortedUsers);

    const date = format(new Date(), "yyyy-MM-dd");

    if (users.length > 0) {
      return (
        <div>
          <CSVLink
            style={{
              border: `1px solid ${theme.palette.primary.main}`,
              padding: 10,
              borderRadius: 100,
              textDecoration: "none",
              backgroundColor: theme.palette.primary.main,
              color: "white",
              width: "100%",
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            filename={`${club?.name}-members-checkins-${date}.csv`}
            data={csvOutput}>
            Download CSV
          </CSVLink>
          <Divider sx={{ my: 2 }} />
          <TableContainer component={Paper}>
            <Table aria-label="club table">
              <TableHead>
                <TableRow>
                  <TableCell>full name</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>check-ins</TableCell>
                  <TableCell>{club?.scoreUnits}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers.map((record: MemberRecord) => {
                  const { member, checkinCount, score } = record;
                  return (
                    <tr key={`member-${member.id}`}>
                      <td>
                        {member.firstName} {member.lastName}
                      </td>
                      <td>{member.email}</td>
                      <td>{checkinCount}</td>
                      <td>{round(score, 2)}</td>
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      return (
        <Container maxWidth="sm">
          {hasSearched && (
            <Typography sx={{ mb: 2 }}>No results found</Typography>
          )}
          <Alert severity="info">
            <Typography>
              Show number of check-ins &amp; total score for members over a date
              range.
            </Typography>
          </Alert>
        </Container>
      );
    }
  };

  if (!club) {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div>
      <Alert severity="warning">
        Fixed a bug that was causing your selection to go to the day before.
        <br />
        <Tooltip title="Includes start day and end day [start: 00:00 hour, end: 23:59 hour]">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography fontWeight="bold">
              {"Start and end times are inclusive"}
            </Typography>
            <InfoRounded />
          </Box>
        </Tooltip>
      </Alert>
      <Box sx={{ my: 3 }}>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <Box sx={{ my: 2, display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startTime}
                label="Start time"
                onChange={(date?: Date | string | null) => {
                  if (
                    !date ||
                    date.toString() === "Invalid Date" ||
                    typeof date === "string"
                  ) {
                    return;
                  }
                  setStartTime(formatISO(startOfDay(new Date(date))) || null);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography style={{ margin: "auto 10px" }}>to</Typography>
              <DatePicker
                value={endTime}
                label="End time"
                onChange={(date?: Date | string | null) => {
                  if (
                    !date ||
                    date.toString() === "Invalid Date" ||
                    typeof date === "string"
                  ) {
                    return;
                  }
                  setEndTime(formatISO(endOfDay(new Date(date))) || null);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{ width: "50%", margin: "0 auto" }}
            color="primary"
            disabled={isSearching}
            onClick={onSubmit}>
            {isSearching ? "Searching..." : "Search"}
          </Button>
        </Box>
      </Box>
      {error && <ErrorBox>{error}</ErrorBox>}
      {renderCheckins()}
    </div>
  );
};

export default MembersRange;
