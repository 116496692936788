import React, { ReactElement, useCallback } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Input,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useStore } from "./context";
import { ONBOARDING_STEP_COUNT, showJson } from ".";
import { useHistory } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { logError, logEvent } from "src/utils/analytics";
import { Search } from "@mui/icons-material";

type VenueSelection = {
  description: string;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Array<{
    offset: number;
    value: string;
  }>;
  types: Array<string>;
};

const OnboardingAddress = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const [newAddress, setNewAddress] = React.useState(store.address);

  const [isProcessing, setIsProcessing] = React.useState(false);

  // Places searcher
  const {
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleClubAddressChange = useCallback(
    (address: string) => {
      setValue(address);
    },
    [setValue],
  );

  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddress(e.target.value);
    handleClubAddressChange(e.target.value);
  };

  const handleVenueSelected = async (venue: VenueSelection) => {
    const { types, structured_formatting } = venue;

    if (
      !types.includes("establishment") &&
      !types.includes("street_address") &&
      !types.includes("premise")
    ) {
      logEvent("non_venue_selected", {
        returnedTypes: types,
        returnedStructuredFormatting: structured_formatting,
      });
      window.alert("You must choose either a business or a street address");
      return;
    }

    let address = "";
    let city = "";
    let state = "";
    const addressParts = structured_formatting.secondary_text.split(",");

    if (types.includes("street_address") || types.includes("premise")) {
      address = structured_formatting.main_text;
      city = addressParts[0];
      state = addressParts[1];
    } else {
      address = addressParts[0];
      city = addressParts[1];
      state = addressParts[2];
    }

    clearSuggestions();

    setNewAddress([address, city, state].join(", "));

    setIsProcessing(true);

    const results = await getGeocode({ address: venue.description });
    if (results.length > 0) {
      try {
        const { lat, lng } = await getLatLng(results[0]);
        dispatch({
          type: "SET_ADDRESS",
          payload: {
            address,
            city,
            state,
            latitude: lat,
            longitude: lng,
          },
        });
      } catch (_e) {
        const e = _e as Error;
        logError({
          error: e,
          message: "Error getting lat/lng for venue",
          file: "ClubForm.tsx",
        });
        window.alert(
          "There was an error setting the address. Please select a new venue.",
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleNext = () => {
    dispatch({ type: "SET_STEP", payload: store.currentStep + 1 });
    history.push(`/clubs/new/logo`);
  };

  const renderPlacesResult = () => {
    if (isProcessing || placesLoading) {
      return (
        <Box>
          <CircularProgress />
        </Box>
      );
    }
    if (placeSuggestions) {
      return (
        <Box>
          {placeSuggestions.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Button
                key={place_id}
                onClick={() => handleVenueSelected(suggestion)}
                variant="text">
                <strong style={{ marginRight: 5 }}>{main_text}</strong>{" "}
                <small>( {secondary_text} )</small>
              </Button>
            );
          })}
        </Box>
      );
    }
  };

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}>
          <Typography variant="h1">New Club</Typography>
          <Typography variant="h2">3/{ONBOARDING_STEP_COUNT}</Typography>
        </Box>

        <Typography variant="h2" sx={{ mt: 3 }}>
          Where does the club meet?
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 2 }}>
        <Input
          placeholder="Search by address"
          fullWidth
          startAdornment={<Search />}
          sx={{ textAlign: "center" }}
          value={newAddress || ""}
          onChange={handleChangeQuery}
          inputProps={{
            sx: { textAlign: "center", fontSize: "1.2rem" },
          }}
        />
        <Typography variant="caption">
          Search for the location the club will meet then select the result
          below
        </Typography>
        {renderPlacesResult()}
      </Paper>
      <Button
        fullWidth
        size="large"
        onClick={handleNext}
        variant="contained"
        disabled={isProcessing || !store.latitude || !store.longitude}>
        Next
      </Button>
      {showJson(store)}
    </Container>
  );
};

export default OnboardingAddress;
