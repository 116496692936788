import React, { ReactElement, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import { listClubs } from "./backend";

import { useQuery } from "react-query";
import Loading from "../../components/Loading";
import { MAX_NUMBER_OF_CLUBS_PER_USER } from "../../global/constants";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add, Check, ContentCopy, Info } from "@mui/icons-material";
import { Club, ClubApprovalStatus } from "src/API";
import { logEvent } from "src/utils/analytics";

const useClubs = () => {
  return useQuery(["clubs"], async () => {
    const currentAuthUser = await Auth.currentAuthenticatedUser();
    const owner = currentAuthUser.username;
    const results = await listClubs(owner);

    return results;
  });
};

const ClubList = (): ReactElement => {
  const theme = useTheme();

  const { data: clubs, status: clubStatus } = useClubs();

  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showingPendingApproval, setShowingApproval] =
    React.useState<Club | null>(null);

  const [isSubjectCopied, setSubjectCopied] = React.useState(false);
  const [isBodyCopied, setBodyCopied] = React.useState(false);

  const [isApprovalDialogOpen, setApprovalDialogOpen] = React.useState(false);

  const handleViewOnboarding = () => {
    history.push("/onboarding");
  };

  const handleOpenApprovalDialog = (club: Club) => {
    setShowingApproval(club);
    setApprovalDialogOpen(true);
  };
  const handleCloseApprovalDialog = () => {
    setApprovalDialogOpen(false);
  };

  useEffect(() => {
    if (isSubjectCopied) {
      const timeout = setTimeout(() => {
        setSubjectCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
    if (isBodyCopied) {
      const timeout = setTimeout(() => {
        setBodyCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [isSubjectCopied, isBodyCopied]);

  const renderApprovalStatus = (club: Club) => {
    if (club.approvalStatus === ClubApprovalStatus.APPROVED) {
      return (
        <Chip
          label="Approved"
          color="success"
          icon={
            <Tooltip title="Approved to show in https://runclub.beer search">
              <Info />
            </Tooltip>
          }
        />
      );
    }
    return (
      <Chip
        label="Pending approval"
        icon={
          <Tooltip title="Request approval to show up on https://runclub.beer search">
            <Info />
          </Tooltip>
        }
        color="warning"
      />
    );
  };

  const renderClubs = () => {
    if (!clubs) {
      return null;
    }
    if (clubs.length === 0) {
      return (
        <Alert severity="info">
          We recommend you{" "}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleViewOnboarding}>
            <Typography>view Getting started</Typography>
          </Button>{" "}
          before creating your first club.
        </Alert>
      );
    }
    return (
      <>
        <Grid container spacing={3}>
          {clubs &&
            clubs.map((club) => (
              <Grid item xs={12} md={6} key={`club-${club.id}`}>
                <Card>
                  <CardActionArea href={`/clubs/${club.id}`}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}>
                        <Box>
                          {club.logoUrl && (
                            <img
                              src={club.logoUrl}
                              alt="club logo"
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ ml: 1 }}>
                          <Typography variant="h6">{club.name}</Typography>
                          <Typography>
                            {[club.city, club.state, club.zipcode]
                              .filter(Boolean)
                              .join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </CardActionArea>

                  <CardActions>
                    {renderApprovalStatus(club)}
                    {club.approvalStatus !== ClubApprovalStatus.APPROVED && (
                      <Button
                        onClick={() => {
                          logEvent("request_approval_open", {
                            clubId: club.id,
                          });
                          handleOpenApprovalDialog(club);
                        }}>
                        Request approval
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Alert severity="info" sx={{ mt: 3 }}>
          You can review{" "}
          <RouterLink to="/onboarding">getting started</RouterLink> if you need
          a refresher.
        </Alert>
      </>
    );
  };
  const renderNewClubButton = () => {
    if (
      clubStatus === "success" &&
      clubs &&
      clubs.length < MAX_NUMBER_OF_CLUBS_PER_USER
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          startIcon={<Add />}
          onClick={() => {
            logEvent("new_club_open");
            history.push("/clubs/new");
          }}>
          New Club
        </Button>
      );
    }
    return null;
  };

  if (clubStatus === "loading") {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <h1>Clubs</h1>
        {renderNewClubButton()}
      </div>
      {renderClubs()}

      <Dialog
        fullScreen={isMobile}
        open={Boolean(isApprovalDialogOpen)}
        onClose={() => handleCloseApprovalDialog()}>
        <DialogTitle>Request Club Approval</DialogTitle>
        {showingPendingApproval && (
          <DialogContent>
            Send the following email to <strong>support@runclub.beer</strong>.
            <br />
            Please allow 1-2 business days for approval.
            <Divider sx={{ my: 1 }} />
            <Box>
              <Typography sx={{ mt: 1 }}>Subject:</Typography>
              <code style={{ fontWeight: "bold" }}>
                Club Approval {showingPendingApproval.id}
              </code>
              <br />
              <Button
                variant="contained"
                startIcon={isSubjectCopied ? <Check /> : <ContentCopy />}
                color={isSubjectCopied ? "success" : "primary"}
                onClick={() => {
                  setSubjectCopied(true);
                  navigator.clipboard.writeText(
                    `Club Approval ${showingPendingApproval.id}`,
                  );
                }}>
                {isSubjectCopied ? "Copied!" : "Copy"}
              </Button>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{ mt: 1 }}>Body:</Typography>
              <code style={{ fontWeight: "bold" }}>
                {`Requesting approval for club: ${showingPendingApproval.id}`}
              </code>
              <br />
              <Button
                startIcon={isBodyCopied ? <Check /> : <ContentCopy />}
                variant="contained"
                color={isBodyCopied ? "success" : "primary"}
                onClick={() => {
                  setBodyCopied(true);
                  navigator.clipboard.writeText(
                    `Requesting approval for club: ${showingPendingApproval.id}`,
                  );
                }}>
                {isBodyCopied ? "Copied!" : "Copy"}
              </Button>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleCloseApprovalDialog()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClubList;
