import React, { ReactElement, useEffect, useState, useContext } from "react";
import ClubSchedule from "./ClubSchedule";
import ClubNotifications from "./ClubNotifications";
import {
  Alert,
  AppBar,
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import { getClub } from "./backend";
import { useHistory, Link as RouterLink, useParams } from "react-router-dom";

import TabPanel from "src/components/TabPanel";
import ClubForm from "./ClubForm";
import ClubOptions from "./ClubOptions";

import CurrentUserContext from "src/contexts/CurrentUserContext";
import ClubMemberFields from "./ClubMemberFields";

const useClub = (clubId?: string) => {
  return useQuery(
    ["club", clubId],
    async () => {
      try {
        if (!clubId) {
          return null;
        }
        const club = await getClub(clubId);
        return club;
      } catch (e) {
        console.log("[ERROR] error getting club", e);
        return null;
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export type ClubEditParams = {
  option: string;
  clubId: string;
};

const ClubEdit = (): ReactElement => {
  const { option, clubId } = useParams<ClubEditParams>();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentUser = useContext(CurrentUserContext);
  const [tabValue, setTabValue] = useState<number>(0);

  const { data: club, status: clubStatus } = useClub(clubId);

  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickTab = (tab: string) => {
    history.replace(`/clubs/${clubId}/edit/${tab}`);
  };

  useEffect(() => {
    switch (option) {
      case "general":
        setTabValue(0);
        break;
      case "member-fields":
        setTabValue(1);
        break;
      case "schedule":
        setTabValue(2);
        break;
      case "options":
        setTabValue(3);
        break;
      case "notifications":
        setTabValue(4);
        break;
      default:
        setTabValue(0);
        break;
    }
  }, [option]);

  const renderHeader = () => {
    if (clubStatus !== "success") {
      return null;
    }
    if (club) {
      return (
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <RouterLink color="inherit" to="/clubs">
              All Clubs
            </RouterLink>
            <RouterLink color="inherit" to={`/clubs/${clubId}`}>
              {club.name}
            </RouterLink>
            <Typography color="textPrimary">
              {option?.replace && option.replace("-", " ")}
            </Typography>
          </Breadcrumbs>
        </div>
      );
    }
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/clubs">
            All Clubs
          </RouterLink>
          <Typography color="textPrimary">New Club</Typography>
        </Breadcrumbs>
      </div>
    );
  };

  if (club && currentUser && club.owner !== currentUser.username) {
    return (
      <Alert severity="error">
        <Typography variant="body2">
          You do not have permission to view this club.
        </Typography>
      </Alert>
    );
  }

  return (
    <div>
      {renderHeader()}
      <AppBar color="transparent" position="static">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons
          orientation={isMobile ? "vertical" : "horizontal"}
          aria-label="Club Edit Tabbar">
          <Tab label="General" onClick={() => handleClickTab("general")} />
          <Tab
            label="Member Fields"
            onClick={() => handleClickTab("member-fields")}
          />
          <Tab label="Schedule" onClick={() => handleClickTab("schedule")} />
          <Tab
            label="Check-in Options"
            onClick={() => handleClickTab("options")}
          />
          <Tab
            label="Notifications"
            onClick={() => handleClickTab("notifications")}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ClubForm />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ClubMemberFields />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ClubSchedule />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <ClubOptions />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <ClubNotifications />
      </TabPanel>
      <Backdrop open={clubStatus === "loading"}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ClubEdit;
