import { SplitSdk } from "@splitsoftware/splitio-react";
import { useQuery } from "react-query";
import {
  SPLIT_FEATURE_FLAG_API_KEY,
  SPLIT_FEATURE_FLAG_KEY,
} from "src/config/split";

export const config = {
  core: {
    authorizationKey: SPLIT_FEATURE_FLAG_API_KEY,
    key: SPLIT_FEATURE_FLAG_KEY,
  },
};

// Instantiate the SDK
const factory = SplitSdk(config);

// And get the client instance you'll use
export const splitClient = factory.client();

const useFeature = (featureName: string, key: string) => {
  return useQuery(
    ["features", featureName, key],
    async () => {
      const featureValue = await splitClient.getTreatment(featureName);
      return featureValue;
    },
    {
      // refetchInterval: 60 * 1000, // check at interval for dev and prod
      refetchOnWindowFocus: false,
    },
  );
};

export const useHasFeature = (
  featureName: string,
): { hasFeature: boolean; isLoading: boolean } => {
  const { data: featureValue, isLoading } = useFeature(featureName, "on");
  return { hasFeature: featureValue === "on", isLoading };
};

export const useGetFeatureValue = (
  featureName: string,
): { featureValue?: string; isLoading: boolean } => {
  const { data: featureValue, isLoading } = useFeature(featureName, "value");
  return { featureValue, isLoading };
};
