import { Box, SxProps, Theme } from "@mui/material";
import React, { ReactElement } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  boxStyles?: SxProps<Theme>;
}

const TabPanel = (props: TabPanelProps): ReactElement => {
  const { children, value, index, boxStyles, ...other } = props;

  const styles = boxStyles || { py: 3 };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={styles}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
