import { Container, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";

const WhatAreAchievements = (): ReactElement => {
  return (
    <Container>
      <div>
        <Typography variant="subtitle1">Onboarding Navigation</Typography>
        <ol>
          <li>
            <RouterLink to="/onboarding/what-you-need">
              <Typography>What will you need</Typography>
            </RouterLink>
          </li>
          <li>
            <RouterLink to="/onboarding/what-are-options">
              <Typography>What are check-in options?</Typography>
            </RouterLink>
          </li>
          <li>
            <Typography>What are achievements?</Typography>
          </li>
        </ol>
        <div>
          <Typography variant="h3">3. What are Achievements?</Typography>
          <hr />
          <Typography variant="h5">Top-level</Typography>
          <Typography variant="body2">
            An achievement is a goal for a member. You will choose between range
            and score types which will be explained below. When a user earns an
            achievement, it will be displayed on their activity screen and you
            will get a notification that they have achieved it.
            <br />
            <br />
            You can handle marking these achievements as seen in the club
            management section of this web app.
          </Typography>
          <hr />
          <Typography variant="h5">Range Type</Typography>
          <Typography variant="body2">
            A range-type achievement has a start date, an end date, and a number
            of times that the member must check-in to earn the achievement.
            <br />
            <br />
            An example would be if you wanted your members to check-in once a
            week for each week between January and the end of March. You would
            count the number of weeks between January 1st and March 31st and use
            that as the score required.
            <br />
            <br />
            Another use for this is rewarding your members for their first
            check-in. If you specify the start date as way in the past (ex Jan
            28th 1985) and the end date way in the future (ex Jan 28th 2121) and
            the score to be 1, they will earn the achievement on their first
            check-in.
          </Typography>
          <hr />
          <Typography variant="h5">Score Type</Typography>
          <Typography variant="body2">
            A score-type achievement is just like it sounds. You specify a score
            that your member must achieve and when they get that score, they get
            that achievement.
          </Typography>
          <hr />
          <div>
            <RouterLink to="/onboarding/what-are-options">
              <Typography>Back</Typography>
            </RouterLink>
            <RouterLink to="/clubs">
              <Typography>Back to home</Typography>
            </RouterLink>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhatAreAchievements;
